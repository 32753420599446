import { Carousel, Col, Row } from 'antd';
import React, { useRef, useState } from 'react';
import InfoCard from '../../components/Card/InfoCard';
import { SubSectionLayout } from '../../layouts/SubSectionLayout';
import { PrimaryNavButton } from '../../components/commonComponent/Button/PrimaryNavButton';

const ResourcesList = ({ resources, className, style }) => {
  const slider = useRef(null);

  const handlePrev = () => {
    slider.current.prev();
  };

  const handleNext = () => {
    slider.current.next();
  };
  return (
    <SubSectionLayout
      title={'Handpicked resources for you'}
      className={className}
      style={style}
      arrowDisplay={true}
      handlePrev={handlePrev}
      handleNext={handleNext}
    >
      <Carousel slidesToShow={3} ref={slider}>
        {resources?.data.map(({ attributes, id }, k) => {
          return (
            <Col key={k}>
              <InfoCard
                data={attributes}
                id={id}
                image={attributes?.image}
                title={attributes?.resourceName}
                desc={attributes?.resourceDescription}
              />
            </Col>
          );
        })}
      </Carousel>
    </SubSectionLayout>
  );
};

export default ResourcesList;
