import React from 'react';

export const Image = ({
  alt = '',
  key = '',
  id = '',
  style = {},
  src = '',
  onClick = () => { },
  ...rest
}) => {
  return (
    <img
      alt={alt}
      id={id}
      style={style}
      src={src}
      onClick={onClick}
      {...rest}
    />
  );
};
