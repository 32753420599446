import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { Col, Input, Row, Select, Flex } from 'antd';
import MediaCard from './Card';
import styles from './styles.module.scss';
import { debounce } from '../../utils';
import CardLoader from '../CardLoader.js';
import { Cpu } from 'react-feather';

const options = [
  {
    value: 'LastAdded',
    label: 'Last Added',
  },
];

const AssetsListingTab = ({ totalMedia, loading, handleSearch, handleChangeFilter, setDeleteLoader, loadMoreHandle }) => {
  const processChange = debounce((e) => handleSearch(e), 3000);
  return (
    <>
      <Row>
        <Col span={12}>
          <Input
            placeholder="Search by Keywords"
            className="mt-2 mb-2 search-input"
            allowClear
            size="large"
            prefix={<SearchOutlined />}
            onChange={(e) => processChange(e)}
            style={{ width: '70%', marginLeft: '1.5rem' }}
          />
        </Col>
        <Col span={12} className='d-flex justify-content-end align-items-center'>
          <label htmlFor="filter">Filter By</label>
          <Select
            defaultValue="LastAdded"
            style={{
              width: 120,
              marginLeft: '1rem'
            }}
            onChange={handleChangeFilter}
            options={options}
            id="filter"
            className="filter-by-input"
          />
        </Col>
      </Row>
      {loading ? (
                <Row className={styles.cardRoot} gutter={16}>
                  {
                    new Array(6).fill(null).map((item, id) => (
                      <Col className={styles.colCard} key={id}>
                        <CardLoader />
                      </Col>
                    ))
                  }
                </Row>
            ) : (
                <Flex vertical={true}>
                  <Row className={styles.cardRoot} gutter={16}>
                    {totalMedia?.map((media, id) => (
                      <MediaCard id={id} key={id} media={media?.attributes?.asset?.data[0]} setDeleteLoader={setDeleteLoader} />
                    ))}

                  </Row>
                  <Row className="mt-5">
                    <Col className="d-flex-center primary-color" span={24}>
                      <div>
                        <b onClick={loadMoreHandle} className="cursor-pointer">
                                    Load More
                        </b>
                      </div>
                    </Col>
                  </Row>
                </Flex>

            )}

    </>
  );
};

export default AssetsListingTab;
