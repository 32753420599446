import React from 'react';
import { PrimaryNavButton } from '../Button/PrimaryNavButton';

export const NavButton = (props) => {
  const { top, marginTop, direction = 'next', left, right, inverse = false } = props;
  const { className, onClick, style: parentStyle } = props.parentProps;// from carousel through CarsoufelNavWrapper

  return (<PrimaryNavButton
    className={`${className}`}
    style={{ ...parentStyle, marginTop, top, left, right }}
    onClick={onClick}
    direction={direction}
    inverse={inverse}
  >
  </PrimaryNavButton>
  );
};
