import React from 'react';

export const Pentagon = ({
  width = '24',
  height = '24',
  className = '',
  style = {}
}) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m11.467 3.183c.175-.122.38-.183.584-.183.206 0 .413.063.589.186 1.778 1.252 7.104 4.997 8.93 6.282.274.194.43.505.43.826 0 .11-.018.221-.056.329-.697 2.016-2.668 7.718-3.351 9.693-.142.41-.53.684-.965.684h-11.153c-.432 0-.818-.27-.962-.674-.7-1.964-2.732-7.667-3.454-9.694-.04-.111-.059-.225-.059-.338 0-.324.157-.636.435-.829 1.853-1.289 7.239-5.035 9.032-6.282zm-7.868 7.3 3.213 9.017h10.475l3.118-9.017-8.355-5.878z"
        fillRule="nonzero"
      />
    </svg>
  );
};
