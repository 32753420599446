import React, { useState, useEffect } from 'react';
import { Button, Dialog, Classes, Icon, H6, Tab, Tabs, Divider, InputGroup } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { elementadd, imageUpdated } from '../../redux/studio/actions';
import appConfig from '../../config';
import { CloudUploadOutlined, PlusOutlined } from '@ant-design/icons';
import { Select, Upload, message, Popconfirm, Spin } from 'antd';
import { getAssets, newMediaUpload, newAssets, deleteItem } from '../../helpers/apiCalls';
import { Popover2 } from '@blueprintjs/popover2';
import usePaginatedApi from '../../customHooks/usePaginationApi';
const imageProperty = { borderRadius: '20px', height: '106px' };

const Capitalize = (stringData) => stringData.charAt(0).toUpperCase() + stringData.slice(1);
const Replaceproperty = () => {
  const [showsModel, setShowsModel] = useState(false);
  const [assetImage, setAssetImage] = useState({});
  const [mediaModel, setMediamodel] = useState(false);
  const { selectedElement } = useSelector((state) => state.Studio.present);
  const [imageItem, setImageItem] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const { data: assetimage, loading, error, loadMore, searchParams } = usePaginatedApi('assets', 9);
  let elemId = selectedElement;
  let srcimage;

  useEffect(() => {
    searchParams('background', 'contenttype');
  }, []);

  if (elemId.type === 'img') {
    srcimage = elemId.content?.src;
  } else {
    srcimage = '';
  }
  const uploadProps = {
    name: 'file',
    multiple: false,
  };
  const uploadbutton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const onChange = (item) => {
    const { status } = item.file;
    if (status === 'done') {
      message.success(`${item.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${item.file.name} file upload failed.`);
    }
  };
  const onFinish = () => {
    let name = assetImage.name.split('.')[0] || '';
    let data = {
      data: {
        asset: assetImage.id,
        type: 'user',
        contenttype: 'background',
        name,
      },
    };

    newAssets(data)
      .then((res) => {
        message.success('Added Assets successfully!');
      })
      .catch((err) => {
        console.log(err);
        message.error('Plz try again!');
      });
    setMediamodel(!mediaModel);
  };
  const handleUpload = (item, name, index = '') => {
    const formData = new FormData();
    formData.append('files', item.file);
    newMediaUpload(formData, item.onProgress)
      .then((res) => {
        setAssetImage(res[0]);
        item.onSuccess(res, item.file);
      })
      .catch((err) => console.log(err));
  };
  const dispatch = useDispatch();

  const handleSortChange = (event) => {
    searchParams(event, 'contenttype');
  };

  const replaceImageHandler = () => {
    const addImageUr = appConfig.socketURL + imageItem.attributes.asset.data[0].attributes?.url.replace('/', '');
    const width = 20;
    const height =
      (imageItem.attributes.asset.data[0].attributes?.height /
        imageItem.attributes.asset.data[0].attributes?.width) *
      33;
    let dimension = { width, height };
    dispatch(imageUpdated({ value: addImageUr, type: 'image', dime: dimension, element: elemId }));
    setShowsModel(false);
  };
  const renderAsset = (item, key) => {
    // console.log('asset image url', item.attributes.asset.data[0].attributes.url, item);
    return (
      <div key={`img${key}`} style={{ height: '110px', width: '113px', display: 'flex' }}>
        <img
          style={selectedImage === key ? { ...imageProperty, border: '1px solid #717171' } : imageProperty}
          onClick={() => {
            setImageItem(item);
            setSelectedImage(key);
          }}
          src={appConfig.socketURL + item.attributes.asset?.data?.[0]?.attributes?.url.replace('/', '')}
          className="w-100 edit_images-img Assetimage"
          alt=""
        />
      </div>
    );
  };
  return (
    <div>
      <Button className="animaionaniatecss" minimal={true} style={{ marginTop: '15px' }}>
        <p
          style={{ color: '#717171' }}
          onClick={() => {
            setShowsModel(true);
          }}>
          Replace image
        </p>
      </Button>
      <Dialog
        onClose={() => {
          setShowsModel(false);
        }}
        isOpen={showsModel}
        className="projectDialogone">
        <div className={Classes.DIALOG_BODY}>
          <div>
            <Button
              minimal={false}
              icon="cross"
              style={{
                width: '23px',
                height: '30px',
                marginLeft: '446px',
                marginTop: '-51px',
                borderRadius: '24px',
                backgroundColor: 'white',
              }}
              onClick={() => {
                setShowsModel(false);
              }}
            />
            <h3 style={{ textAlign: 'center', fontSize: '17px', color: 'black', marginTop: '-27px' }}>
              Choose an Image
            </h3>
            <div className="bp4-input-group .modifier" style={{ marginLeft: '16px', marginTop: '15px' }}>
              {/* <InputGroup
                style={{
                  width: '94%',
                  boxShadow: '2px 2px 2px 2px rgba(201, 201, 201, 0.25)',
                  borderRadius: '2px',
                }}
                className="tabsSearchButtonone"
                placeholder="Search for Backgrounds"
                type="search"
                leftIcon="search"
                asyncControl={true}
                onChange={(event) => searchItems(event.target.value)}
              /> */}
              <Select
                className="projectsSelect"
                name="status"
                style={{ width: '100%' }}
                placeholder=" Content Type"
                defaultValue={'background'}
                onChange={handleSortChange}
              >
                {appConfig.sortAssetList?.map((item, key) => (
                  <Select.Option key={item}>
                    {Capitalize(item)}
                  </Select.Option>
                ))}
              </Select>
            </div>
            <div>
              <div>
                <Upload
                  {...uploadProps}
                  maxCount={1}
                  customRequest={(item) => handleUpload(item)}
                  listType="picture-card"
                  onChange={onChange}>
                  <Button className="uploadMediaButtonone">Upload</Button>
                </Upload>
                <Button style={{ marginLeft: '45%' }} onClick={onFinish}>
                  Save
                </Button>

                <br />
              </div>
            </div>
            <div>
              {loading ? (
                <div className="spinner-position">
                  <Spin />
                </div>
              ) : (
                <div className="Assetimageone">
                  <div style={{ display: 'flex', flexWrap: 'wrap' }} className="edit_images ">
                    {assetimage?.map((item, key) => renderAsset(item, key))}
                  </div>
                  <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem' }}>
                    <Button onClick={loadMore}>Load more</Button>
                  </div>
                </div>
              )}
            </div>
            <div className="replaceImageBtn">
              <Button className="uploadMediaButtonone" onClick={replaceImageHandler}>
                Replace
              </Button>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Replaceproperty;
