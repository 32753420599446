import React from 'react';

export const CrossHair = ({
  width = '24',
  height = '24',
  className = '',
  style = {}
}) => {
  return (<svg
    className={className}
    style={style}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M11 9v-5h2v5h-2zm-2 2h-5v2h5v-2zm6 0v2h5v-2h-5zm-4 4v5h2v-5h-2zm2-4h-2v2h2v-2z" />
  </svg>);
};
