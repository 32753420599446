// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_container__R34yi {
    background-color: #E9E9FF;
    padding: 5rem 13rem 3rem 13rem;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-top: 1px solid #000;
}

.styles_heading__FIgBu {
    font-size: 16px;
    color: #7459FB;
    font-weight: 700;
    padding-bottom: 0.5rem;
}

.styles_children__bm3Yb {
    font-size: 16px;
    color: #000;
    font-weight: 300;
    padding: 0.5rem
}

.styles_childrenRow__kooL1 {
    display: flex;
    flex-direction: column;
}

.styles_copyright__sni2N {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    width: 100%;
    padding: 2rem 4rem 0 0
}

.styles_parentCol__-Cfsf {
    width: 20%;
    padding: 0
}

.styles_termandpolicy__kcgFG {
    display: flex;
}

.styles_termandpolicy__kcgFG>div {
    padding-left: 1rem;
    padding-top: 1rem
}

.styles_parentRow__vxEch {
    width: 100%
}`, "",{"version":3,"sources":["webpack://./src/components/Footer/styles.module.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,8BAA8B;IAC9B,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB;AACJ;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,qBAAqB;IACrB,WAAW;IACX;AACJ;;AAEA;IACI,UAAU;IACV;AACJ;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;IAClB;AACJ;;AAEA;IACI;AACJ","sourcesContent":[".container {\n    background-color: #E9E9FF;\n    padding: 5rem 13rem 3rem 13rem;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    border-top: 1px solid #000;\n}\n\n.heading {\n    font-size: 16px;\n    color: #7459FB;\n    font-weight: 700;\n    padding-bottom: 0.5rem;\n}\n\n.children {\n    font-size: 16px;\n    color: #000;\n    font-weight: 300;\n    padding: 0.5rem\n}\n\n.childrenRow {\n    display: flex;\n    flex-direction: column;\n}\n\n.copyright {\n    display: flex;\n    flex-direction: column;\n    justify-content: flex-end;\n    align-items: flex-end;\n    width: 100%;\n    padding: 2rem 4rem 0 0\n}\n\n.parentCol {\n    width: 20%;\n    padding: 0\n}\n\n.termandpolicy {\n    display: flex;\n}\n\n.termandpolicy>div {\n    padding-left: 1rem;\n    padding-top: 1rem\n}\n\n.parentRow {\n    width: 100%\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `styles_container__R34yi`,
	"heading": `styles_heading__FIgBu`,
	"children": `styles_children__bm3Yb`,
	"childrenRow": `styles_childrenRow__kooL1`,
	"copyright": `styles_copyright__sni2N`,
	"parentCol": `styles_parentCol__-Cfsf`,
	"termandpolicy": `styles_termandpolicy__kcgFG`,
	"parentRow": `styles_parentRow__vxEch`
};
export default ___CSS_LOADER_EXPORT___;
