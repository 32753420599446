import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AiOutlinePlusCircle } from 'react-icons/ai';

export const CreateNewCard = () => {
  const history = useNavigate();
  return (
    <div className={`d-flex color-white flex-column align-items-center gap-4 custom-card`}>
      <span className="header">Build my own lesson</span>
      <AiOutlinePlusCircle fontSize={80} onClick={() => history('/lesson')} />
      <span className="description">Start from scratch to create your perfect lesson.</span>
      <span className="description">
                You can save this lesson as your template to make creating lessons even easier!
      </span>
    </div>
  );
};
