import React from 'react';

export const ResizeHandles = ({
  wrapperClassName = '',
  wrapperStyle = {},
}) => {
  return (
    <div className={`parent-marker ${wrapperClassName}`} style={wrapperStyle}>
      <div className="left-top"></div>
      <div className="right-top"></div>
      <div className="right-bottom"></div>
      <div className="left-bottom"></div>
      <div className="left-center"></div>
      <div className="right-center"></div>
      <div className="top-center"></div>
      <div className="bottom-center"></div>
    </div>
  );
};
