import React from 'react';
import { Col, Row } from 'antd';
import NavLinkCards from './NavLinkCards';

export const MainBanner = (props) => {
  const {
    pageTitle = '',
    subText = '',
    greeting = '',
    profileImage = '',
    description = '',
    rightSectionImage = '',
    showNavLinks = false,
    page,
    pageHeading,
  } = props;

  return (
    <Row justify="space-between" align="middle" style={{ padding: 60 }} className="bg-color-1 hide">
      <Col lg={14} md={24}>
        <Row style={{ gap: 24 }}>
          {pageHeading && (
            <Col span={24}>
              <label className="pageHeading">{pageHeading}</label>
            </Col>
          )}
          <Col span={24}>
            <span style={{ fontSize: '2.25rem' }} className="h1 fw-700">
              {pageTitle}
            </span>
          </Col>
          <Col span={24}>
            <span style={{ fontSize: '1.4rem', color: '#000' }} className="fw-300">{subText}</span>
          </Col>
          <Col span={24} className="position-relative">
            {greeting ? <span className="fw-400 fs-xl d-inline primary-color">{greeting}</span> : null}
            {profileImage ? (
                            <img src={profileImage} alt="profile" className="greeting-img d-inline position-absolute" />
                        ) : null}
          </Col>
        </Row>

        {description && (
          <>
            <br />
            <Row>
              <Col span={24}>{description ? description : null}</Col>
            </Row>
          </>
        )}
        {showNavLinks && (
          <>
            <br />
            <Row>
              <Col span={24}>{showNavLinks ? <NavLinkCards page={page} /> : null}</Col>
            </Row>
          </>
        )}
      </Col>

      <Col lg={8} md={0} style={{ marginRight: -100 }}>
        <div className="position-relative overflow-hidden h-100 banner-image-container d-none d-lg-flex align-items-center">
          {rightSectionImage ? <img src={rightSectionImage} alt="dashboard" className="w-100" /> : null}
        </div>
      </Col>
    </Row>
  );
};
