import React from 'react';

export const CheckList = ({
  width = '24',
  height = '24',
  className = '',
  style = {}
}) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      clipRule="evenodd"
      fillRule="evenodd"
      strokeLinejoin="round"
      strokeMiterlimit="2"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="m22 17.75c0-.414-.336-.75-.75-.75h-12.5c-.414 0-.75.336-.75.75s.336.75.75.75h12.5c.414 0 .75-.336.75-.75zm-19.806-.537 1.249 1.114c.13.116.293.173.456.173.184 0 .369-.075.504-.222l2.116-2.313c.119-.131.178-.296.178-.459 0-.375-.302-.682-.683-.682-.185 0-.369.074-.504.221l-1.661 1.815-.746-.665c-.131-.116-.292-.173-.454-.173-.379 0-.683.307-.683.682 0 .188.076.374.228.509zm19.806-3.463c0-.414-.336-.75-.75-.75h-12.5c-.414 0-.75.336-.75.75s.336.75.75.75h12.5c.414 0 .75-.336.75-.75zm-19.806-1.449 1.249 1.114c.13.116.293.173.456.173.184 0 .369-.074.504-.222l2.116-2.313c.119-.131.178-.295.178-.459 0-.375-.302-.682-.683-.682-.185 0-.369.074-.504.221l-1.661 1.815-.746-.665c-.131-.116-.292-.173-.454-.173-.379 0-.683.307-.683.683 0 .187.076.374.228.508zm19.806-2.551c0-.414-.336-.75-.75-.75h-12.5c-.414 0-.75.336-.75.75s.336.75.75.75h12.5c.414 0 .75-.336.75-.75zm-19.806-2.361 1.249 1.114c.13.116.293.173.456.173.184 0 .369-.074.504-.221l2.116-2.313c.119-.131.178-.296.178-.46 0-.374-.302-.682-.683-.682-.185 0-.369.074-.504.221l-1.661 1.815-.746-.664c-.131-.116-.292-.173-.454-.173-.379 0-.683.306-.683.682 0 .187.076.374.228.509zm19.806-1.639c0-.414-.336-.75-.75-.75h-12.5c-.414 0-.75.336-.75.75s.336.75.75.75h12.5c.414 0 .75-.336.75-.75z"
        fillRule="nonzero"
      />
    </svg>
  );
};
