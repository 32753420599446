import React from 'react';
import { Col, Row } from 'antd';
import PaperPlaneImage from '../assets/images/paper_plane.png';

export const SmallBanner = ({ pageTitle = '', subTitle = '', leftSectionComponent, type }) => {
  return (
    <Row className=" bg-color-1 template-listing-header banner_pdg">
      <Col md={24} lg={12}>

        {type === 'assets' ? (
                    <div>
                      <Row>
                        <Col>
                          <span className="h1 mb-4 d-flex cr_title">{pageTitle}</span>
                        </Col>
                        <Col className='d-flex align-items-center justify-content-center'>
                          <img src={PaperPlaneImage} alt="PaperPlaneImage" width={100} />
                        </Col>
                      </Row>
                      <div style={{ width: '64%' }}>
                        {/* <div className="d-flex align-items-center">
                                
                            </div> */}
                        <span className="h4 cr_sub" >{subTitle}</span>
                      </div>
                    </div>
                ) : (
                    <Row>
                      <Col>
                        <span className="h1 mb-4 d-flex cr_title">{pageTitle}</span>
                        <span className="h4 cr_sub" >{subTitle}</span>
                      </Col>
                      <div className="d-flex align-items-center">
                        <img src={PaperPlaneImage} alt="PaperPlaneImage" width={100} />
                      </div>
                    </Row>
                )}

      </Col>

      <Col
        md={24}
        lg={12}
        className={'d-flex justify-content-end align-items-center'}
        style={{ columnGap: '4%', maxWidth: 'none' }}>
        {leftSectionComponent ? leftSectionComponent : null}
      </Col>
    </Row>
  );
};
