import {
  PROJECT_INIT,
  PROJECT_UPDATED,
  SLIDE_SELECTION_UPDATED,
  SLIDE_INDEX_UPDATED,
  ELEMENT_INDEX_UPDATED,
  ELEMENT_CHANGED,
  ELEMENT_UPDATED,
  ELEMENT_DELETED,
  ELEMENT_COPIED,
  SLIDE_DELETED,
  SLIDE_COPIED,
  SELECTION_CHANGED,
  INDEX_UPDATED,
  SELECTION_UPDATED,
  SET_HEADERSTYLEINFO,
  ELEMENT_CUT_ADDED,
  UNDO,
  REDO,
} from './constants';
import { produce } from 'immer';

import { newID } from '../../helpers/studioApi';
import _ from 'lodash';

const INIT_STATE = {
  past: [],
  present: {
    loading: false,
    project: {},
    selectedElement: {},
    selectedSlide: {},
    slides: [],
    elements: [],
  },
  future: [],
};

const applyChange = (state, callback) => {
  let { past, present, future } = state;
  let newPresent = produce(present, callback);
  return {
    past: [...past, present],
    present: _.cloneDeep(newPresent),
    future: [],
  };
};

const Studio = (state = INIT_STATE, action) => {
  let newElementId;
  switch (action.type) {
    case PROJECT_INIT:
      return applyChange(state, draft => {
        draft.projectid = action.payload.projectid;
        draft.projectName = action.payload.projectName;
      });

    case PROJECT_UPDATED:
      return applyChange(state, draft => {
        draft.project = action.payload.project;
        if (action.payload.slides) { draft.slides = action.payload.slides; }
        if (action.payload.title) { draft.title = action.payload.title; }
        if (action.payload.elements) { draft.elements = action.payload.elements; }
      });

    case SLIDE_SELECTION_UPDATED:
      return applyChange(state, draft => {
        draft.selectedSlide = action.payload.slide;
        let selectedElement = _.findIndex(draft.selectedSlide.elements, { id: draft.selectedElement.id });
        if (selectedElement !== -1) {
          draft.selectedElement = draft.selectedSlide.elements[selectedElement];
        }
        // console.log('slideSelectionUpdate', action.payload.slide.id, draft.project);
        let slideIndex = _.findIndex(draft.project.slides, { id: action.payload.slide.id });
        if (slideIndex !== -1) {
          draft.project.slides[slideIndex] = draft.selectedSlide;
        }
      });

    case SLIDE_INDEX_UPDATED:
      return applyChange(state, draft => {
        draft.project.slides = action.payload.slides;
        draft.slides = action.payload.slides;
      });

    case ELEMENT_INDEX_UPDATED:
      return applyChange(state, draft => {
        draft.project.slides = action.payload.slides;
        draft.slides = action.payload.slides;
      });

    case ELEMENT_CHANGED:
      return applyChange(state, draft => {
        draft.selectedElement = action.payload.show;
        let elemIndex = _.findIndex(draft.selectedSlide.elements, { id: action.payload.show.id });
        if (elemIndex !== -1) {
          draft.selectedSlide.elements[elemIndex] = action.payload.show;
          // draft.selectedSlide.elements[elemIndex].isSelect = true;
        }
        let slideIndex = _.findIndex(draft.project.slides, { id: draft.selectedSlide.id });
        if (slideIndex !== -1) {
          draft.project.slides[slideIndex] = draft.selectedSlide;
        }
      });

    case ELEMENT_UPDATED:
      return applyChange(state, draft => {
        let { id, changes } = action.payload;
        let element = draft.elements?.find(el => el.id === id);
        if (element) {
          Object.assign(element, changes);
        }
      });

    case ELEMENT_DELETED:
      return applyChange(state, draft => {
        draft.selectedElement = {};
      });

    case ELEMENT_COPIED:
      return state; // No state change

    case SLIDE_DELETED:
      return state; // No state change

    case SLIDE_COPIED:
      return state; // No state change

    case SELECTION_CHANGED:
      return state; // No state change

    case INDEX_UPDATED:
      return applyChange(state, draft => {
        draft.slides = action.payload.slides;
      });

    case SELECTION_UPDATED:
      return applyChange(state, draft => {
        draft.selectedItem = action.payload.item;
        draft.show = action.payload.show;
      });

    case SET_HEADERSTYLEINFO:
      return applyChange(state, draft => {
        draft.headerStyle = action.payload.item;
      });

    case ELEMENT_CUT_ADDED:
      newElementId = newID('element');
      return applyChange(state, draft => {
        let slide = draft.project.slides.find(slide => slide.id === draft.selectedSlide.id);
        if (slide) {
          slide.elements.push({ ...action.payload.element, id: newElementId });
        }
        draft.selectedSlide.elements.push({ ...action.payload.element, id: newElementId });
        draft.selectedElement = { ...action.payload.element, id: newElementId };
      });

    case UNDO:
      if (state.past.length > 0) {
        let newPresent = state.past[state.past.length - 1];
        return {
          past: state.past.slice(0, state.past.length - 1),
          present: { ..._.cloneDeep(newPresent) },
          future: [state.present, ...state.future],
        };
      }
      return state;

    case REDO:
      if (state.future.length > 0) {
        let newPresent = state.future[0];
        return {
          past: [...state.past, state.present],
          present: { ..._.cloneDeep(newPresent) },
          future: state.future.slice(1),
        };
      }
      return state;

    default:
      return state;
  }
};

export default Studio;
