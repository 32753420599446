import React from 'react';
import { Button, Card, Elevation, Position } from '@blueprintjs/core';
import { Link, useNavigate } from 'react-router-dom';
import { SlidesIcon } from '../../../ProjectSVG';
import ActionPopover from './components/ThemeActionPopover';

export const ThemeCard = ({ id, imageUrl = '', title, noOfSlides = 0, popoverComponent = null }) => {
  const history = useNavigate();
  return (
    <Card interactive={true} elevation={Elevation.TWO} className="projectcards">
      {popoverComponent}
      <div>
        <span>
          <img onClick={() => history(`/theme/${id}`)} className="projectcardImage" src={imageUrl} />
        </span>
      </div>

      <p onClick={() => history(`/theme/${id}`)} className="projectCardsTitle">
        {title}
      </p>
      <p style={{ marginBottom: '9px', marginTop: '10px' }}>
        <SlidesIcon />
        <span style={{ marginLeft: '2%' }}>
          <span style={{ color: '#6362B0' }}>Theme</span> with {noOfSlides} Slides
        </span>
      </p>
      <div style={{ display: 'flex' }} className="projectCardButtons">
        <Button onClick={() => history(`/theme/${id}`)}>KS1</Button>
        <Button onClick={() => history(`/theme/${id}`)}>KS1</Button>
        <Button onClick={() => history(`/theme/${id}`)}>Lesson</Button>
      </div>
    </Card>
  );
};
