// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_infoCard_root__-o6Je {
    margin-left: 1rem;
    margin-right: 1rem;
}

.styles_infoCardBox_img__yeNDT {
    aspect-ratio: 16/9;
    width: 100%;
}

.styles_infoCard_img__RQHDH {
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.styles_infoCard_heading__rs5LT {
    font-size: 22px;
    font-weight: 600;
    color: #000;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.styles_infoCard_desc__O3B4N {
    font-size: 16px;
    font-weight: 300;
}`, "",{"version":3,"sources":["webpack://./src/components/Card/styles.module.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,WAAW;IACX,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB","sourcesContent":[".infoCard_root {\n    margin-left: 1rem;\n    margin-right: 1rem;\n}\n\n.infoCardBox_img {\n    aspect-ratio: 16/9;\n    width: 100%;\n}\n\n.infoCard_img {\n    height: 100%;\n    width: 100%;\n    object-fit: cover;\n}\n\n.infoCard_heading {\n    font-size: 22px;\n    font-weight: 600;\n    color: #000;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n\n.infoCard_desc {\n    font-size: 16px;\n    font-weight: 300;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"infoCard_root": `styles_infoCard_root__-o6Je`,
	"infoCardBox_img": `styles_infoCardBox_img__yeNDT`,
	"infoCard_img": `styles_infoCard_img__RQHDH`,
	"infoCard_heading": `styles_infoCard_heading__rs5LT`,
	"infoCard_desc": `styles_infoCard_desc__O3B4N`
};
export default ___CSS_LOADER_EXPORT___;
