import React, { useState, useEffect, forwardRef } from 'react';

const ResizableDraggable = forwardRef(({
    children,
    onDragStop,
    onResizeStop,
    position: initialPosition,
    size: initialSize,
    className,
    style,
    onMouseDownEvent,
    onContextMenu,
    key,
    id
}, ref) => {
    const [isDragging, setIsDragging] = useState(false);
    const [resizing, setResizing] = useState(null);
    const [position, setPosition] = useState(initialPosition || { x: 100, y: 100 });
    const [size, setSize] = useState({
        width: initialSize?.width || '200px',
        height: initialSize?.height || '200px'
    });

    const handleMouseDownDrag = (e) => {
        onMouseDownEvent();
        if (e.target.classList.contains('resizable-draggable') || e.target.classList.contains('rsw-editor')) {
            setIsDragging(true);
            ref.current = {
                offsetX: e.clientX - position.x,
                offsetY: e.clientY - position.y,
            };
        }
    };

    const handleMouseMoveDrag = (e) => {
        if (isDragging) {
            setPosition({
                x: e.clientX - ref.current.offsetX,
                y: e.clientY - ref.current.offsetY,
            });
        }
    };

    const handleMouseUpDrag = (e) => {
        if (isDragging) {
            setIsDragging(false);
            if (onDragStop) {
                onDragStop(e, position);
            }
        }
    };

    const handleMouseDownResize = (e, direction) => {
        // e.stopPropagation();
        setResizing(direction);
        ref.current = {
            startX: e.clientX,
            startY: e.clientY,
            startWidth: ref.current.width,
            startHeight: ref.current.height,
            startXPos: position.x,
            startYPos: position.y,
        };
    };

    const handleMouseMoveResize = (e) => {
        if (resizing) {
            const deltaX = e.clientX - ref.current.startX;
            const deltaY = e.clientY - ref.current.startY;

            let newSize = { ...size };
            let newPosition = { ...position };

            switch (resizing) {
                case 'right':
                    newSize.width = `${ref.current.startWidth + deltaX}px`;
                    break;
                case 'bottom':
                    newSize.height = `${ref.current.startHeight + deltaY}px`;
                    break;
                case 'bottom-right':
                    newSize.width = `${ref.current.startWidth + deltaX}px`;
                    newSize.height = `${ref.current.startHeight + deltaY}px`;
                    break;
                case 'left':
                    newSize.width = `${ref.current.startWidth - deltaX}px`;
                    newPosition.x = ref.current.startXPos + deltaX;
                    break;
                case 'top':
                    newSize.height = `${ref.current.startHeight - deltaY}px`;
                    newPosition.y = ref.current.startYPos + deltaY;
                    break;
                case 'top-left':
                    newSize.width = `${ref.current.startWidth - deltaX}px`;
                    newSize.height = `${ref.current.startHeight - deltaY}px`;
                    newPosition.x = ref.current.startXPos + deltaX;
                    newPosition.y = ref.current.startYPos + deltaY;
                    break;
                case 'top-right':
                    newSize.width = `${ref.current.startWidth + deltaX}px`;
                    newSize.height = `${ref.current.startHeight - deltaY}px`;
                    newPosition.y = ref.current.startYPos + deltaY;
                    break;
                case 'bottom-left':
                    newSize.width = `${ref.current.startWidth - deltaX}px`;
                    newSize.height = `${ref.current.startHeight + deltaY}px`;
                    newPosition.x = ref.current.startXPos + deltaX;
                    break;
                default:
                    break;
            }

            setSize(newSize);
            setPosition(newPosition);
        }
    };

    const handleMouseUpResize = (e) => {
        if (resizing) {
            setResizing(null);
            if (onResizeStop) {
                onResizeStop(e, resizing, ref, null, position);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('mousemove', handleMouseMoveDrag);
        window.addEventListener('mouseup', handleMouseUpDrag);
        window.addEventListener('mousemove', handleMouseMoveResize);
        window.addEventListener('mouseup', handleMouseUpResize);

        return () => {
            window.removeEventListener('mousemove', handleMouseMoveDrag);
            window.removeEventListener('mouseup', handleMouseUpDrag);
            window.removeEventListener('mousemove', handleMouseMoveResize);
            window.removeEventListener('mouseup', handleMouseUpResize);
        };
    }, [isDragging, resizing, position, size]);

    useEffect(() => {
        setPosition(initialPosition || { x: 100, y: 100 });
    }, [initialPosition]);

    useEffect(() => {
        setSize({
            width: initialSize?.width || '200px',
            height: initialSize?.height || '200px'
        });
    }, [initialSize]);

    useEffect(() => {
        const element = document.getElementById(id);
        ref.current.width = element.clientWidth;
        ref.current.height = element.clientHeight;
    }, [size]);

    return (
        <div
            className={`resizable-draggable edit-textarea ${className}`}
            style={{
                ...style,
                width: size.width,
                height: size.height,
                transform: `translate(${position.x}px, ${position.y}px)`,
                position: 'absolute',
                border: '12px solid transparent',
            }}
            onMouseDown={handleMouseDownDrag}
            onContextMenu={onContextMenu}
            ref={ref}
            key={key}
            id={id}
        >
            {children}
            <div className="resizer top-left" onMouseDown={(e) => handleMouseDownResize(e, 'top-left')} />
            <div className="resizer top" onMouseDown={(e) => handleMouseDownResize(e, 'top')} />
            <div className="resizer top-right" onMouseDown={(e) => handleMouseDownResize(e, 'top-right')} />
            <div className="resizer right" onMouseDown={(e) => handleMouseDownResize(e, 'right')} />
            <div className="resizer bottom-right" onMouseDown={(e) => handleMouseDownResize(e, 'bottom-right')} />
            <div className="resizer bottom" onMouseDown={(e) => handleMouseDownResize(e, 'bottom')} />
            <div className="resizer bottom-left" onMouseDown={(e) => handleMouseDownResize(e, 'bottom-left')} />
            <div className="resizer left" onMouseDown={(e) => handleMouseDownResize(e, 'left')} />
        </div>
    );
});

ResizableDraggable.displayName = 'ResizableDraggable';

export default ResizableDraggable;
