import React, { useState } from 'react';
import ReactCrop, {
  centerCrop,
  makeAspectCrop,
} from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import { Cropbt } from '../../ProjectSVG';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Dialog, Classes } from '@blueprintjs/core';
import { useRef } from 'react';
import { newMediaUpload } from '../../helpers/apiCalls';
import { elementUpdated } from '../../redux/studio/actions';
import appConfig from '../../config';

function centerAspectCrop(
  mediaWidth,
  mediaHeight,
  aspect,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  );
}

function ImageCrops() {
  const [crop, setCrop] = useState({
    unit: 'px',
    width: 300,
    height: 300,
    x: 25,
    y: 25,
  });
  const [showsModel, setShowsModel] = useState(false);
  const { selectedElement: elemId } = useSelector((state) => state.Studio.present);
  const imgRef = useRef(null);
  const dispatch = useDispatch();
  const [completedCrop, setCompletedCrop] = useState(null);


  const createBlobOfCroppedImage = async (pixelCrop) => {
    const canvas = createCanvasOfCroppedImage(imgRef.current, pixelCrop);
    console.log('canvas', canvas);
    // As a blob
    return new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        file.name = 'cropped';
        resolve(file);
      }, 'image/png');
    });
  };

  // console.log('AppConfig', appConfig.socketURL);
  const handleCropImageSubmit = async (pixelCrop) => {
    console.log('imgRef', imgRef);
    if (imgRef.current) {
      const croppedImageBlob = await createBlobOfCroppedImage(pixelCrop);

      let formData = new FormData();
      formData.append('files', croppedImageBlob);
      newMediaUpload(formData).then((res) => {
        // console.log('appConfig', appConfig.socketURL, res);
        dispatch(elementUpdated(elemId, appConfig.socketURL + res[0].url.replace('/', ''), 'imageCrop'));
      });
      // console.log('CroppedImageBlob', croppedImageBlob, formData);
      /**
             * if you want to convert to formdata
             * let formData = new FormData();
             * formData.append("profile_pic", croppedImageBlob,
             * croppedImageBlob.name);
             * please remove comments after implementation
             */

      // dispatch the update call or client call here

      setShowsModel(false);
      // uncomment to close the modal
    }
  };
  const onLoad = (e) => {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;
    setCrop(centerAspectCrop(width, height, 16 / 9));
  };



  const createCanvasOfCroppedImage = (image, pixelCrop) => {
    console.log('image', image, 'pixelCrop', pixelCrop, 'image');
    const { offsetHeight, offsetWidth, naturalHeight, naturalWidth } = image;
    const { width, height, x, y } = pixelCrop;
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext('2d');
    const scaleX = naturalWidth / offsetWidth;
    const scaleY = naturalHeight / offsetHeight;
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(image, x * scaleX, y * scaleY, width * scaleX, height * scaleY, 0, 0, width, height);

    // As Base64 string
    //const base64Image = canvas.toDataURL('image/png');
    return canvas;
  };

  return (
    <div>
      <Button
        className="animaionaniatecss"
        style={{ marginTop: '13px' }}
        minimal={true}
        onClick={() => {
          setShowsModel(true);
        }}>
        <Cropbt />
      </Button>

      <Dialog
        onClose={() => {
          setShowsModel(false);
        }}
        isOpen={showsModel}
        className="projectDialogone">
        <div className={Classes.DIALOG_BODY}>
          <div>
            {/* <Button
              minimal={false}
              icon="cross"
              style={{
                width: '23px',
                height: '30px',
                marginLeft: '446px',
                marginTop: '-51px',
                borderRadius: '24px',
                backgroundColor: 'white',
              }}
              onClick={() => {
                setShowsModel(false);
              }}
            /> */}
            <div className="App">
              <center>
                <div>
                  {elemId?.content?.src && (
                    <div>
                      <ReactCrop
                        crop={crop}
                        imageAlt="crop"
                        ruleOfThirds
                        onChange={(c) => setCrop(c)}
                        onComplete={(c) => setCompletedCrop(c)}
                        crossorigin="Anonymous"
                      // aspect={16 / 9}
                      >
                        <img
                          ref={imgRef}
                          alt="Crop me"
                          src={elemId?.content?.src}
                          onLoad={onLoad}
                          crossOrigin="Anonymous"
                        />
                      </ReactCrop>
                      <br />
                      <Button
                        className="uploadMediaButtonone crop-btn"
                        onClick={() => handleCropImageSubmit(crop)}>
                        Crop
                      </Button>
                      <br />
                      <br />
                    </div>
                  )}
                </div>
              </center>
            </div>
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default ImageCrops;
