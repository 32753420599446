import React from 'react';
import { Icon } from '@blueprintjs/core';

export const ProfileImage = ({
  image = ''
}) => {
  return (
    <button
      style={{
        border: 'aliceblue',
        backgroundColor: 'transparent',
        color: '#6362B0',
        gap: 4,
        marginLeft: 8,
        alignItems: 'center',
        display: 'flex',
      }}>
      <img style={{ height: '30px' }} src={image} alt="img" />
      <Icon icon="chevron-down" />
    </button>

  );
};
