import React from 'react';
import styles from './styles.module.scss';
import { Col } from 'antd';
import AddSchoolTheme from './AddSchoolTheme';

const CardForUpload = ({ children, content, onClick }) => {
  return (
    <Col className={styles.card_upload_root} onClick={onClick}>
      <div className={styles.card_upload_children}>{children}</div>
    </Col >
  );
};

export default CardForUpload;
