import React from 'react';
import { Button, Position } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { Link, useNavigate } from 'react-router-dom';
import { EyeIcon, EditIcon } from '../../../../ProjectSVG';

export const ProjectPreviewPopover = ({ title, id }) => {
  const history = useNavigate();
  return (
    <Popover2
      Position={Position.LEFT_BOTTOM}
      content={
        <div className="tabsPopoverone">
          <p className="popoverTitle">{title}</p>
          <hr />
          <div className="popoverSubtitle">
            <Link className="text-success cursor-pointer me-3" to={{ pathname: '/Project' }}>
              <EditIcon />
            </Link>
            <span onClick={() => history('/Project')} className="popoverSubtitleText">
                            Edit this lesson
            </span>
          </div>
          <div className="popoverSubtitle">
            <Link className="text-success cursor-pointer me-3" to={{ pathname: `/studio/${id}` }}>
              <EyeIcon />
            </Link>
            <span onClick={() => history(`/studio/${id}`)} className="popoverSubtitleText">
                            Preview this lesson
            </span>
          </div>
        </div>
      }>
      <Button icon="more" className="projectcardMore"></Button>
    </Popover2>
  );
};
