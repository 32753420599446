import React from 'react';
import arrowCircle from '../assets/images/arrow_circle.png';
import { LeftArrow, RightArrow } from '../assets/svgs';

export const SubSectionLayout = ({ className = '', style = {}, title, subTitle, arrowDisplay = false, handleNext = () => { }, handlePrev = () => { }, children }) => {
  return (
    <div className={className} style={style || null}>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className="mb-4">
          <h1 className="fw-700 color-purple-2 fs-xxl">{title}</h1>
          <p className="fw-300 fs-md">{subTitle}</p>
        </div>
        {arrowDisplay && (
          <div style={{ display: 'flex', gap: '10px' }}>

            <div className="position-relative" onClick={handlePrev}>
              <img src={arrowCircle} />
              <div className="position-absolute" style={{ margin: '-3rem 0' }}><LeftArrow /></div>
            </div>
            <div className="position-relative" onClick={handleNext}>
              <img src={arrowCircle} />
              <div className="position-absolute" style={{ margin: '-3rem 0' }}><RightArrow /></div>
            </div>
          </div>
        )}
      </div>
      <div style={{ marginLeft: '-12px' }}>{children}</div>
    </div>
  );
};
