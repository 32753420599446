import React from 'react';
import { Button, Card, Elevation, Position } from '@blueprintjs/core';
import { Link, useNavigate } from 'react-router-dom';
import { SlidesIcon } from '../../../ProjectSVG';

export const ProjectCard = ({ id, imageUrl = '', title, noOfSlides = 0, popoverComponent = null }) => {
  const history = useNavigate();
  return (
    <Card interactive={true} elevation={Elevation.TWO} className="projectcards">
      {popoverComponent}
      {/* onClick={() => history.push(`/studio/${id}`)}> */}
      <div>
        <Link
          to={{
            pathname: `/studio/${id}`,
          }}>
          <img className="projectcardImage" src={imageUrl} alt="alt" />
        </Link>
      </div>

      <p onClick={() => history(`/studio/${id}`)} className="projectCardsTitle">
        {title}
      </p>
      <p style={{ marginBottom: '9px', marginTop: '10px' }}>
        <SlidesIcon />
        <span style={{ color: '#0A9764' }}> Lesson </span> with {noOfSlides} Slides
      </p>
      <div style={{ display: 'flex' }} className="projectCardButtons">
        <Button onClick={() => history(`/studio/${id}`)}>KS1</Button>
        <Button onClick={() => history(`/studio/${id}`)}>KS1</Button>
        <Button onClick={() => history(`/studio/${id}`)}>Lesson</Button>
      </div>
    </Card>
  );
};
