import React from 'react';
import { Button, Dialog, Classes, Tooltip } from '@blueprintjs/core';
import { PlusOutlined } from '@ant-design/icons';
import { message, Modal, Upload } from 'antd';

const uploadProps = {
  name: 'file',
  multiple: false,
};
const uploadbutton = (
  <div>
    <PlusOutlined />
    <div className="uploadbutton">Upload</div>
  </div>
);

const UploadMedia = ({ setMediamodel, mediaModel, handleUpload, onFinish }) => {
  const onChange = (item) => {
    const { status } = item.file;
    if (status === 'done') {
      message.success(`${item.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${item.file.name} file upload failed.`);
    }
  };
  return (
    <Modal className='wizard-modal' title="Upload new Media" open={mediaModel} onCancel={() => {
      setMediamodel(false);
    }} footer={[
      <Button key="back" onClick={() => {
        setMediamodel(false);
      }}>
                Close
      </Button>,
      <Button key="submit" type="primary" onClick={onFinish}>
                Save
      </Button>,
    ]}
    >
      <div className={Classes.DIALOG_BODY}>
        <div>
          <Upload
            {...uploadProps}
            maxCount={1}
            customRequest={(item) => handleUpload(item)}
            listType="picture-card"
            onChange={onChange}>
            {uploadbutton}
          </Upload>
          <br />
        </div>
      </div>
    </Modal>
  );
};

export default UploadMedia;
