import Myproject from '../pages/my-creation';
import Asset from '../pages/user/Asset';
import Library from '../pages/user/Library';
import Mylesson from '../pages/user/Mylesson';
import MyTemplate from '../pages/user/MyTemplate';
import CreateTemplate from '../pages/user/CreateTemplate';
import CreateLesson from '../pages/user/CreateLesson';
import Login from '../pages/auth/Login';
import Logout from '../pages/auth/Logout';
import Studio from '../studio/studio';

import { renderRoutes } from './generateRoutes';
//Layouts
import Auth from '../layouts/Auth';
import VerticalLayout from '../layouts/VerticalLayout';
import { libraryIcon, myAssetICon, mycreationIcon } from '../ProjectSVG';
import { Navigate } from 'react-router-dom';
import Dashboard from '../pages/dashboard';
import React from 'react';

export const routes = [
  {
    layout: Auth,
    routes: [
      {
        name: 'home',
        title: 'Home page',
        exact: true,
        component: () => <Navigate to="/my-creation" replace />,
        path: '/',
        icon: libraryIcon,
      },
      {
        name: 'login',
        title: 'Login page',
        component: Login,
        exact: true,
        path: '/account/login',
        isPublic: true,
      },
      {
        name: 'logout',
        title: 'Logout page',
        component: Logout,
        exact: true,
        path: '/account/logout',
        isPublic: true,
      },
      {
        name: 'Studio',
        title: 'Studio page',
        component: Studio,
        exact: true,
        path: '/studio/:cid',
        icon: libraryIcon,
      },
      {
        name: 'New Template',
        title: 'New template page',
        component: Studio,
        exact: true,
        path: 'template/studio/:cid',
        icon: libraryIcon,
      },
      {
        name: 'Studio',
        title: 'Studio page',
        component: Studio,
        exact: true,
        path: '/studio',
        icon: libraryIcon,
      },
      {
        name: 'Studio',
        title: 'Theme page',
        component: Studio,
        exact: true,
        path: '/theme/:cid',
        icon: libraryIcon,
      },

      {
        name: 'projects',
        title: 'Projects page',
        component: Myproject,
        exact: true,
        path: '/projects',
        icon: libraryIcon,
      },
      {
        name: 'lessons',
        title: 'Lessons page',
        component: Mylesson,
        exact: true,
        path: '/lessons',
        icon: libraryIcon,
      },
      {
        name: 'library',
        title: 'Library page',
        component: Library,
        exact: true,
        path: '/library',
        icon: libraryIcon,
      },
      {
        name: 'assets',
        title: 'Assets page',
        component: Asset,
        exact: true,
        path: '/assets',
        icon: libraryIcon,
      },
    ],
  },
  {
    layout: VerticalLayout,
    routes: [
      {
        name: 'My Projects',
        title: 'Assets page',
        component: Dashboard,
        path: '/my-creation',
        icon: mycreationIcon,
      },
      {
        name: 'My Assets',
        title: 'My Assets',
        component: Asset,
        path: '/my-assets',
        icon: myAssetICon,
      },
      {
        name: 'Library',
        title: 'Library page',
        component: Library,
        path: '/creation',
        icon: libraryIcon,
      },
      {
        name: 'My Templates',
        title: 'Templates page',
        component: MyTemplate,
        path: '/my-templates',
        icon: libraryIcon,
      },
      {
        name: 'My Lessons',
        title: 'My lessons page',
        component: Mylesson,
        path: '/my-lessons',
        icon: libraryIcon,
      },
      {
        name: 'Create Template',
        title: 'Create template page',
        component: CreateTemplate,
        path: '/template',
        icon: libraryIcon,
      },
      {
        name: 'Create Lesson',
        title: 'Create lessons page',
        component: CreateLesson,
        path: '/lesson',
        icon: libraryIcon,
      },
    ],
  },
];
export const Routes = renderRoutes(routes);

// <Route path="/" exact element={<MyProjectWith />} />
// <Route path="/template" exact element={<CreateTemplate />} />
// <Route path="/my-lessons" exact element={<Mylesson />} />
// <Route path="/my-templates" exact element={<MyTemplate />} />
// <Route path="/creation" exact element={<Library />} />
// <Route path="/my-assets" exact element={<Asset />} />
// <Route path="/my-creation" exact element={<MyProjectWith />} />

// <Route path="/lesson" exact element={<CreateLesson />} />
// <Route path="/account/login" exact element={<LoginWith />} />
// <Route path="/account/logout" exact element={<Logout />} />
