import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Button, Classes } from '@blueprintjs/core';
import deleteIcon from '../../assets/images/delete_icon.png';
import downloadIcon from '../../assets/images/download_icon.png';
import { AssetImageIcon, DownloadIcon } from '../../assets/svgs';
import { Col, Popover, Row } from 'antd';
import appConfig from '../../config';

const ImageCard = ({ type = 'Image', imgUrl, deleteHandler }) => {
  const [open, setOpen] = useState(false);


  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  const downloadHandler = async () => {
    try {
      const response = await fetch(`${appConfig.socketURL}${imgUrl}`);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'image.png';
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };
  return (
    <div style={{ borderRadius: '8px' }}>
      <div className={styles.image_card_popover_icon}>
        <div className={styles.imageType}><AssetImageIcon />{type}</div>
        <Popover
          arrow={false}
          placement="bottom"
          trigger="click"
          open={open}
          onOpenChange={handleOpenChange}
          content={
            <div className="card_pop" style={{ height: '89px' }}>
              <div className="popoverSubtitle" onClick={downloadHandler}>
                <img src={downloadIcon} />
                <button
                  className={Classes.POPOVER_DISMISS}
                  style={{
                    border: 'white',
                    backgroundColor: 'white',
                    fontFamily: 'Lexend',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    fontSize: '14px',
                  }}
                  // className="btn_pop"
                  // onClick={() => history(`/studio/${id}`, { search: '?type=template' })}
                >
                                    Download Asset
                </button>
              </div>
              <div className="popoverSubtitle" onClick={deleteHandler}>
                <img src={deleteIcon} />
                <button
                  className={Classes.POPOVER_DISMISS}
                  style={{
                    border: 'white',
                    backgroundColor: 'white',
                    fontFamily: 'Lexend',
                    fontStyle: 'normal',
                    fontWeight: 300,
                    fontSize: '14px',
                  }}
                  // className="btn_pop"
                >
                                    Delete Asset
                </button>
              </div>
            </div>
          }>
          <Button icon="more" className="projectcardMore"></Button>
        </Popover>
      </div>
      <img alt="example" src={`${appConfig.socketURL}${imgUrl}`} className={styles.image} />
    </div>
  );
};

export default ImageCard;
