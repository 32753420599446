import React from 'react';
import { Button, Position } from '@blueprintjs/core';
import '@blueprintjs/core/lib/css/blueprint.css';
import Detailoverlay from './Detailoverlay';
import { Popover2 } from '@blueprintjs/popover2';
const Projectproperty = () => {
  return (
    <Popover2
      position={Position.BOTTOM_LEFT}
      content={
        <div className="filesPopover">
          <Detailoverlay />
        </div>
      }>
      <Button className="HomeButton" minimal={true}>
                File
      </Button>
    </Popover2>
  );
};

export default Projectproperty;
