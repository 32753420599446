import React, { useState } from 'react';
import CardForUpload from '../assets-builder/CardForUpload';
import { UploadCloudIcon } from '../../assets/svgs';
import { newMediaUpload } from '../../helpers/apiCalls';
import UploadMedia from '../commonComponent/UploadDialog';
import appConfig from '../../config';

const Image = ({ item, _, setThemeQuestionBySlide, themeQuestionBySlide }) => {
  const [mediaModel, setMediamodel] = useState(false);
  const [image, setImage] = useState({ url: item?.content?.src });
  const handleUpload = (item) => {
    const formData = new FormData();
    formData.append('files', item.file);
    newMediaUpload(formData, item.onProgress)
            .then((res) => {
              setImage({
                ...res[0],
                url: appConfig?.socketURL + res[0]?.url?.replace('/', '')
              });
              item.onSuccess(res, item.file);
            })
            .catch((err) => console.log(err));
  };

  const onFinish = () => {
    const quesDetail = {
      ...item,
      content: { src: image?.url },
    };
    let elements =
            themeQuestionBySlide?.elements;
    elements[_] = quesDetail;
    setThemeQuestionBySlide({
      ...themeQuestionBySlide,
      elements,
    });
    setMediamodel(false);
  };

  return (
    <div className="question-container" key={_}>
      <div className="question">{item?.question}</div>
      <div className="options">
        <div className="option">
          <CardForUpload onClick={() => {
            setMediamodel(true);
          }}>
            {image?.url ? (
                            <img src={image?.url} style={{ height: '100%', width: '100%', objectFit: 'cover' }} />
                        ) : <UploadCloudIcon />}

          </CardForUpload>
        </div>
      </div>
      <UploadMedia setMediamodel={setMediamodel} mediaModel={mediaModel} handleUpload={handleUpload} onFinish={onFinish} />
    </div>
  );
};

export default Image;
