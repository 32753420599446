/* eslint-disable no-unused-vars */
import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './slidesItem';
import { useDispatch, useSelector } from 'react-redux';
import { slideSelectionChanged, slideIndexChanged } from '../../redux/studio/actions';
import { Icon, InputGroup } from '@blueprintjs/core';
import appConfig from '../../config';
import { getAssets } from '../../helpers/apiCalls';
import { Popover } from '@blueprintjs/core';
import { slideCopied, slideDeleted, slideUpdated, slideimageUpdated } from '../../redux/studio/actions';
import { ContextcopyIcon, ContextdeleteIcon, PenIcon } from '../../ProjectSVG';
import CustomSlideColor from '../../components/CustomSlideColor';
import { AddBackgroundDrawerRight } from './LeftSideDrawers/AddBackgroundDrawerRight';
import { debounce, hideTextIfExceedsLimit } from '../../utils';
import SlidePreview from './SlidePreview';

const style = {
  width: '105px !important',
  height: '65px',
  opacity: 1,
  boxShadow: '0 2px 10px rgba(0,0,0,.15)',
  borderRadius: '10px',
  cursor: 'move',
  pointerEvents: 'auto'
};

export const Card = ({ text, index, moveCard, style: divStyle, card }) => {
  const ref = useRef(null);
  const {
    id,
    style: payload,
  } = card;
  const dispatch = useDispatch();
  // const [state, setState] = useState({
  //   project: appConfig.project,
  //   buttonId: '',
  //   buttonURL: '',
  //   dimension: {},
  //   id: 'raj',
  // });
  const [showsModel, setShowsModel] = useState(false);
  // const [assetimage, setAssetimage] = useState([]);
  // const [loading, setLoading] = useState(false);
  // const [contentType, setContenttype] = useState('background');
  // const [referesh, setReferesh] = useState(true);
  // const { user, error } = useSelector((state) => state.Auth);
  const [chnagedIndex, setChangedIndex] = useState();
  // const [initialdata, setInitialdata] = useState([]);
  const { project, selectedSlide } = useSelector((state) => state.Studio.present);
  // To Save Each slide indx after drag and drop


  // const handleClose = () => setState({ isOpen: false });
  let data = project.slides;
  const slideIndex = data.findIndex((object) => {
    if (selectedSlide) { return object?.id === selectedSlide?.id; }
    else { return 0; }
  });
  // const element = data.splice(slideIndex, 1)[0];
  // const toIndex = chnagedIndex;
  // data.splice(toIndex, 0, element);

  // const [opacity1, setOpacity1] = React.useState('1');
  const [dindex, setDindex] = React.useState(null);
  const [hindex, setHindex] = React.useState(null);

  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      // setOpacity1('1')
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      setChangedIndex(dragIndex);
      const hoverIndex = index;
      setDindex(dragIndex);
      setHindex(hoverIndex);
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        // setOpacity1('0.5');

        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.right - hoverBoundingRect.left) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.x - hoverBoundingRect.left;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      setDindex(null);
      setHindex(null);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;

  drag(drop(ref));
  const copyslide = (ev) => {
    ev.stopPropagation();
    selectedSlide.index = slideIndex;
    dispatch(slideCopied(selectedSlide));
  };
  let sldId = selectedSlide;
  let backgroundColor;
  let backgroundImage;

  if (selectedSlide === undefined) {
    backgroundColor = '#ffff';
    backgroundColor = '';
  } else {
    backgroundColor = sldId.style?.['background-color'];
    backgroundImage = sldId.style?.['background-image'];
    var matches = backgroundImage?.match(
      /(http|ftp|https):([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:~+#-]*[\w@?^=%&~+#-])/gi
    );
  }
  const getMatch = (url) => {
    let matches = url.split('"')[1];
    return matches;
  };
  const deleteslide = (ev) => {
    ev.stopPropagation();
    dispatch(slideDeleted(selectedSlide));
  };
  const onChange = (e) => {
    dispatch(slideUpdated(sldId, e.target.value, e.target.name));
  };

  // const getAsset = () => {
  //   setLoading(true);
  //   getAssets('', 60, 5)
  //     .then((res) => {
  //       const data = res.data;
  //       const background = data.filter((item) => item.attributes.contenttype === contentType);
  //       setAssetimage(background);
  //       setInitialdata(background);
  //       setReferesh(!referesh);
  //     })
  //     .catch((error) => console.log(error))
  //     .finally(() => setLoading(false));
  // };
  // const buttonSelected = (id, url, dimension) => {
  //   setState({
  //     buttonURL: url,
  //     buttonId: id,
  //     dimension,
  //   });
  // };
  // const myfunction = (data) => {
  //   setState({
  //     id: data,
  //   });
  // };
  // const addButtonToCanvas = () => {
  //   if (state.buttonId && state.buttonURL) {
  //     slideimageUpdated({
  //       image: state.buttonURL,
  //       type: 'background',
  //       dimension: state.dimension,
  //     });
  //     handleClose();
  //   }
  // };
  // const renderAsset = (item, key) => {
  //   return (
  //     <div key={key} className="slidecardmaincss">
  //       <img
  //         style={{ borderRadius: '20px', height: '106px' }}
  //         key={key}
  //         onClick={() => {
  //           dispatch(
  //             slideimageUpdated(
  //               sldId,
  //               appConfig.socketURL + item.attributes.asset.data[0].attributes?.url.replace('/', '')
  //             )
  //           );
  //           setShowsModel(false);
  //         }}
  //         src={appConfig.socketURL + item.attributes.asset.data[0].attributes?.url.replace('/', '')}
  //         className="w-100  Assetimage"
  //         alt=""
  //       />
  //     </div>
  //   );
  // };
  // const searchBackground = (event) => {
  //   if (event.target.value === '') {
  //     setAssetimage(initialdata);
  //   } else {
  //     const filteredData = assetimage.filter(
  //       (ele, ind) => ele?.attributes?.asset?.data?.[0]?.attributes?.name === event.target.value
  //     );
  //     setAssetimage(filteredData);
  //   }
  // };

  if (!id || id === null) { return null; }

  const handleChangeName = (e) => {
    dispatch(slideUpdated(sldId, e.target.value, e.target.name));
  };

  const processChange = debounce((e) => handleChangeName(e), 3000);

  return (
    <div key={index}>
      <div
        onChange={onChange}
        ref={ref}
        style={{ ...style, ...divStyle, opacity }}
        data-handler-id={handlerId}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            height: '65px',
            width: '100%',
            // marginBottom: '30%',
            borderRadius: '10px',
            backgroundSize: 'cover',
            backgroundRepeat: 'repeat',
            objectFit: 'cover',
            opacity: hindex && dindex && hindex === dindex ? 0.2 : 1,
            backgroundImage: payload?.['background-image'] ? payload['background-image'] : null,
            backgroundColor: payload?.['background-color'] ? payload['background-color'] : '#fff',
            border: id === selectedSlide?.id && '2px solid #6362B0',
            position: 'relative'
          }}
          className="selectedSlide"
          onClick={(ev) => {
            dispatch(slideSelectionChanged(id));
          }}
        >
          <SlidePreview slide={card} slidePreview={true} />
          <Popover
            content={
              <div className="slidePropertyPopover">
                <div className="d-flex align-items-center" style={{ gap: 12 }}>
                  <InputGroup
                    name="Name"
                    asyncControl={true}
                    value={selectedSlide?.Meta?.Name}
                    onChange={(e) => processChange(e)}
                    style={{ boxShadow: 'none', border: '1px solid #cacacaca', borderRadius: 6 }}
                  />
                  <PenIcon />
                </div>
                <div className="horizontalLine" />
                <div onClick={copyslide} className="slidePropertyPopoverInnerDiv cursor-pointer">
                  <ContextcopyIcon />
                  <span className="popoverSubtitleText">Copy slide</span>
                </div>
                <div
                  onClick={(e) => data.length > 1 && deleteslide(e)}
                  style={{ cursor: data.length > 1 ? 'pointer' : 'not-allowed' }}
                  className="slidePropertyPopoverInnerDiv">
                  <ContextdeleteIcon />
                  <span className="popoverSubtitleText">Move to trash</span>
                </div>
                <div className="horizontalLine" />
                <div className="d-flex flex-column gap-4">
                  <span className="popoverSubtitleText">Background Color</span>
                  <div
                    className="slidePropertyPopoverInnerDiv cursor-pointer"
                    style={{ display: 'flex' }}>
                    <CustomSlideColor slideId={sldId} bgcolor={backgroundColor} />
                    {/* <PenIcon /> */}
                  </div>
                </div>
                <div className="horizontalLine" />
                <div className="d-flex flex-column gap-4">
                  <span className="popoverSubtitleText">Background Image</span>
                  <div className="d-flex flex-column align-items-center justify-content-center gap-3">
                    {payload?.['background-image']?.length > 10 ? (
                      <img
                        id="image"
                        alt="img"
                        style={{
                          backgroundRepeat: 'repeat',
                          display: 'flex',
                          flexDirection: 'column',
                          objectFit: 'cover',
                          width: '100px',
                          aspectRatio: '2/1.25',
                          border: '1px solid #cacaca',
                          borderRadius: 10,
                          boxShadow: 'rgb(218 218 218 / 40%) 2px 2px 2px 4px',
                        }}
                        src={getMatch(payload?.['background-image'])}
                        onClick={(ev) => {
                          ev.stopPropagation();
                          // getAsset();
                          setShowsModel(true);
                        }}
                      />
                    ) : (
                      <AddBackgroundDrawerRight />
                    )}
                    {payload?.['background-image']?.length > 10 && (
                      <div
                        className="cursor-pointer"
                        style={{
                          display: 'flex',
                          padding: 8,
                          border: '1px solid #cacaca',
                          boxShadow: 'rgb(218 218 218 / 40%) 2px 2px 2px 4px',
                          borderRadius: '50%',
                        }}
                        onClick={(ev) => {
                          ev.stopPropagation();
                          dispatch(slideimageUpdated(sldId, ''));
                        }}>
                        <ContextdeleteIcon />
                      </div>
                    )}
                  </div>
                </div>
              </div>
            }
            placement="top"
          >
            <button className="slideDetailbutton">
              <Icon icon="more"></Icon>
            </button>
          </Popover>
        </div>
      </div>
      <h6 style={{ display: 'flex', marginTop: '10px', marginLeft: '1rem' }}>
        {index + 1 + '. ' + (hideTextIfExceedsLimit(card?.Meta?.Name, 11) || '')}
      </h6>
    </div>
  );
};
