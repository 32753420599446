import React from 'react';

export const ArrowSolidUp = ({
  width = '24',
  height = '24',
  className = '',
  style = {}
}) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M12 0l8 9h-6v15h-4v-15h-6z" />
    </svg>
  );
};
