import React, { useEffect, useState } from 'react';
import appConfig from '../../config';
import http from '../../services/http.service';
import { ListContainer } from '../ListContainer';
import { CreateNewCard } from './CreateNewCard';
import { TemplateCard } from './TemplateCard/TemplateCard';
import { getProjects } from '../../helpers/apiCalls';
import qs from 'qs';
import { createQuery } from '../../utils';
import { useSelector } from 'react-redux';

const selectData = [
  {
    value: 'popularity',
    label: 'Popularity',
  },
];


const LessonListingTab = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setAppliedFilter] = useState({
    fitlers: {
      $and: [],
    },
  });
  const [pagination, setPagination] = useState({
    start: 0,
    limit: 10
  });
  const { user } = useSelector(state => state.Auth);


  useEffect(() => {
    filterProjects(filter, pagination);
    return () => setData([]);
  }, [pagination, filter]);

  const filterProjects = async (filter, pagination) => {
    setLoading(true);
    const { start, limit } = pagination;
    const result = await http.get(appConfig.collection + '/projects?' + createQuery({
      filters: {
        $and: [
          {
            username: {
              $eq: user?.user?.username
            }
          }
        ]
      }
    }, limit, start));
    setData(result.data);
    setLoading(false);
  };


  const loadMoreHandle = async () => {
    setPagination((prev) => ({
      ...prev,
      start: prev.start + 10
    }));
  };


  async function searchFilterHandler(e) {
    setAppliedFilter(prev => ({
      ...prev,
      filters: {
        $and: [
          {
            title: {
              $contains: e.target.value
            }
          }
        ]
      }
    }));
  }

  return (
    <ListContainer
      subText="Choose from hundreds of templates designed specifically for children or build your own!"
      selectProps={{
        options: selectData,
        onChange: () => console.log('on change'),
        defaultValue: 'popularity',
      }}
      loading={loading}
      loadMoreHandle={loadMoreHandle} searchFilterHandler={searchFilterHandler}>
      {!loading && (
        <>
          <CreateNewCard />
          {data?.map((item) => (
            <TemplateCard data={item} key={item.id} showHover />
          ))}
        </>
      )}
    </ListContainer>
  );
};



export default LessonListingTab;
