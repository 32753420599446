import React, { useState, useCallback } from 'react';
import { Button, Position, Tooltip, Classes } from '@blueprintjs/core';
import { Form, Input } from 'antd';
import { Row, Col, message } from 'antd';
import question from '../../../assets/images/question.png';
import { useDispatch, useSelector } from 'react-redux';
import { elementUpdated } from '../../../redux/studio/actions';
import { Drawer } from '../../../ui/Drawer/Drawer';
import { isThemeOrTemplate } from '../../utils';

// TODO: handle visibility with location pathname, and see if we can reuse the Question component form frameCanvas

export const AddQuestion = () => {
  const [cards, setCards] = useState([]);
  const { selectedSlide } = useSelector((state) => state.Studio.present);
  const dispatch = useDispatch();

  const [slideName, setslidename] = useState([]);

  const [isDrawerOpen, setIsDrawerOpen] = useState(() => false);

  const onFinishtext = (value, id) => {
    const que = { question: value };
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].id === id && que.question?.length) {
        dispatch(elementUpdated(cards[i], que.question, 'question'));
        // message.success('Question added successfully');
      }
    }
  };

  React.useEffect(() => {
    if (selectedSlide?.id) {
      setCards(selectedSlide?.elements);
      setslidename(selectedSlide?.Meta?.Name);
    }
  }, [selectedSlide?.elements]);
  const renderCard = useCallback((card, index) => {
    return (
      <div key={index}>
        <p className="wizardTitle1">
          Question - {index + 1} ({card?.name}){' '}
        </p>
        <p className="wizardTitle1">({card?.type})</p>
        <hr className="hrline" />
        <Row>
          <Col>
            <img src={question} alt="" height="30" className="icons" />
          </Col>
          <Col style={{ marginLeft: '10px' }}>
            <Form onFinish={onFinishtext}>
              <Form.Item name="question">
                <Input.TextArea
                  placeholder="Add your question"
                  defaultValue={card?.question}
                  onChange={(e) => onFinishtext(e.target.value, card?.id)}
                />
              </Form.Item>
            </Form>
          </Col>
        </Row>

        <hr className="hrline" />
      </div>
    );
  });
  return (
    <div>
      {isThemeOrTemplate ? (
        <Tooltip content="Add Question" position={Position.RIGHT_TOP}>
          <Button onClick={() => setIsDrawerOpen(true)} className="drawerbuttons" minimal={true}>
            <img src={question} alt="" height="30" className="icons" />
            <p className="textQus">Question</p>
          </Button>
        </Tooltip>
      ) : null}
      <Drawer
        className="drawerWizard"
        position={Position.LEFT}
        onClose={() => setIsDrawerOpen(false)}
        isOpen={isDrawerOpen}
        title={<span style={{ fontSize: '18px' }}>Wizard Quesions </span>}>
        <div className={Classes.DRAWER_BODY}>
          <div style={{ marginTop: '3px' }}>
            <hr className="hrline" />
            <h6 className="wizardTitle">slideName - {slideName}</h6>
            <hr className="hrline" />
          </div>
          <div className="elementsection">
            <div
              style={{
                width: '280px',
                height: 'inherit',
              }}>
              {cards.map((card, i) => renderCard(card, i))}
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};
