import dashboard from '../assets/images/dashboard.png';
import dashboardAssessments from '../assets/images/dashboard_assessments.png';
import dashboardCurriculum from '../assets/images/dashboard_curriculum.png';
import dashboardSaved from '../assets/images/dashboard_saved.png';
import dashboardStudents from '../assets/images/dashboard_students.png';
import studioProject from '../assets/images/dashboard_studio_projects.png';
import resourceCollection from '../assets/images/resource_collection.png';
import resourceCurriculum from '../assets/images/resource_curriculum.png';
import resourceGame from '../assets/images/resource_game.png';
import resourceLesson from '../assets/images/resource_lesson.png';
import resourceLibrary from '../assets/images/resource_library_icon.png';
import resourcePlanning from '../assets/images/resource_planning.png';

export const menuItems = [
  {
    id: 1,
    name: 'Dashboard',
    isDrop: true,
    content: [
      {
        id: 1,
        heading: 'Dashboard',
        description: 'Looking for theme-based activities for your students? We\'ve got cross-curricular groupings of lessons',
        color: '#F2E2B7',
        icon: dashboard,
      },
      {
        id: 2,
        heading: 'Students',
        description: 'In student dashboard you can add your student in .csv format and manual.',
        color: '#C8D5FF',
        icon: dashboardStudents
      },
      {
        id: 3,
        heading: 'Saved Resources',
        description: 'Saving resources which you like will help you are see in futuer and play it as per your conviencences',
        color: '#88ECCA',
        icon: dashboardSaved
      },
      {
        id: 4,
        heading: 'Assessments',
        description: 'Assessment Dashboards combine data from direct assessments, to share wtih parents',
        color: '#F4CCBA',
        icon: dashboardAssessments
      },
      {
        id: 5,
        heading: 'Curriculums',
        description: 'Browse curriculumes as per age group, learning area, topics and collections',
        color: '#E7D2B0',
        icon: dashboardCurriculum
      },
      {
        id: 6,
        heading: 'Studio Projects',
        description: 'This is the place to use your full brain and hear to make something good for your kids.',
        color: '#EBD9FC',
        icon: studioProject
      }
    ]
  },
  {
    id: 2,
    name: 'Templates',
    isDrop: false,
    route: '/my-templates'
  },
  {
    id: 3,
    name: 'My Creations',
    isDrop: true,
    route: '/my-lessons'
  },
  {
    id: 4,
    name: 'Assets',
    isDrop: false,
    route: '/my-assets'
  },
  {
    id: 5,
    name: 'Resources',
    isDrop: true,
    content: [
      {
        id: 1,
        heading: 'Resource Library',
        description: 'Browse more than 2000+ teaching resources specially designed for you keeping in mind your need.',
        color: '#B7EBF2',
        icon: resourceLibrary
      },
      {
        id: 2,
        heading: 'Curriculums',
        description: 'We have covered whole school curriculums and also included PSHE and RSE too.',
        color: '#C8D5FF',
        icon: resourceCurriculum
      },
      {
        id: 3,
        heading: 'Collections',
        description: 'SuperTeach Collections are easy way to explore teaching resources in bundle way and categories.',
        color: '#DED8FF',
        icon: resourceCollection
      },
      {
        id: 4,
        heading: 'Planning Documents',
        description: 'This will help you organized yourself in better way and make your life simpler',
        color: '#F4DEB3',
        icon: resourcePlanning
      },
      {
        id: 5,
        heading: 'Lesson plans',
        description: 'Specially designed for teacher, headteacher, parents and children to learn in nice way.',
        color: '#FAE9CB',
        icon: resourceLesson
      },
      {
        id: 6,
        heading: 'Games',
        description: 'Kids love games we all know so we covered brain games, mind games and IQ games to help nuturing.',
        color: '#EFE1FD',
        icon: resourceGame
      }
    ]
  }
];

export const footerItems = [
  {
    id: 1,
    name: 'Educational Tools',
    children: [
      {
        id: 1,
        name: 'Resource Library',
      },
      {
        id: 2,
        name: 'Games and Quizzes',
      },
      {
        id: 3,
        name: 'Interactive Lessons',
      },
      {
        id: 4,
        name: 'Assessments',
      },
      {
        id: 5,
        name: 'Studio Editor',
      },
      {
        id: 6,
        name: 'Lesson Plans',
      },
    ]
  },
  {
    id: 2,
    name: 'Use Cases',
    children: [
      {
        id: 1,
        name: 'Teacher',
      },
      {
        id: 2,
        name: 'PSHE Lead',
      },
      {
        id: 3,
        name: 'Whole School',
      },
      {
        id: 4,
        name: 'Parent',
      },
    ]
  },
  {
    id: 3,
    name: 'Support',
    children: [
      {
        id: 1,
        name: 'Help Centre',
      },
      {
        id: 2,
        name: 'FAQ',
      },
      {
        id: 3,
        name: 'Contact us',
      },
      {
        id: 4,
        name: 'Pricing',
      },
      {
        id: 5,
        name: 'Chat to us',
      },
      {
        id: 6,
        name: 'Blog',
      },
    ]
  },
  {
    id: 1,
    name: 'Solutions',
    children: [
      {
        id: 1,
        name: 'Turn-key PSHE Curriculum',
      },
      {
        id: 2,
        name: 'Innovative resource Library',
      },
      {
        id: 3,
        name: 'Buld a bespoke Curriculum',
      },
      {
        id: 4,
        name: 'Teacher community',
      },

    ]
  },
  {
    id: 1,
    name: 'About',
    children: [
      {
        id: 1,
        name: 'Company',
      },
      {
        id: 2,
        name: 'Vision and Mission',
      },
      {
        id: 3,
        name: 'In the Press',
      },
      {
        id: 4,
        name: 'Privacy Policy',
      },
      {
        id: 5,
        name: 'Terms of Service',
      },
    ]
  }
];


export const randomBackgroundColor = [
  {
    id: 1,
    color: '#FDF8EC'
  },
  {
    id: 2,
    color: '#E7F8FE'
  },
  {
    id: 3,
    color: '#E9FFEE'
  },
  {
    id: 4,
    color: '#FAF3FF'
  },
  {
    id: 5,
    color: '#F9F4E8'
  },
  {
    id: 6,
    color: '#EBE8FE'
  },
  {
    id: 7,
    color: '#EAFCF8'
  },
  {
    id: 8,
    color: '#FAEEFF'
  },
  {
    id: 9,
    color: '#ECFAFD'
  },
  {
    id: 10,
    color: '#EAFCF8'
  }
];
