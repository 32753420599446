import React from 'react';

export const Iframe = ({
  className,
  title,
  allowfullscreen,
  style = {},
  src,
  frameborder,
  width,
  height,
  allow,
  referrerpolicy,
  loading,
  ...rest
}) => {
  if (!src) {return null;}
  return (
    <iframe
      src={src}
      className={className}
      title={title}
      allowFullScreen={!!allowfullscreen}
      allow={allow}
      loading={loading}
      referrerPolicy={referrerpolicy}
      style={{ border: frameborder, width, height, ...(typeof style === 'object' ? style : {}) }}
      {...rest}
    />
  );
};
