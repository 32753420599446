import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { elementChanged, elementUpdated } from '../../redux/actions';
import SimpleWysiwyg from './CustomEditor';

export const MultilineEdit = ({ value, style, elementObj, toolbar }) => {
  const [editingValue, setEditingValue] = React.useState(value);
  const dispatch = useDispatch();
  const editorRef = React.useRef();
  // editorRef.current.innerHTML = value;
  const updateElement = (value) => {
    if (value) {
      dispatch(elementUpdated(elementObj, value, 'text'));
    } else {
      let editorData = editorRef.current.innerHTML;
      if (editorData !== elementObj.content.text) {
        dispatch(elementUpdated(elementObj, editorRef.current.innerHTML, 'text'));
      }
    }
  };

  useEffect(() => {
    if (editorRef.current) {
      editorRef.current.innerHTML = value;
    }
  }, [style.fontSize, style.fontFamily, style.color, style.backgroundColor, style.textAlign, style.textTransform]);

  // console.log('editingValue', 'value', value);
  return (
    <SimpleWysiwyg
      className="edit-textarea"
      style={{ ...style, resize: 'none' }}
      rows={1}
      aria-label="Field name"
      value={editingValue}
      // ref={textareaRef}
      displayToolbar={toolbar}
      placeholder={elementObj?.content?.placeholder || ''}
      onMouseDown={(e) => dispatch(elementChanged(elementObj))}
      updateElement={updateElement}
      key={JSON.stringify(style)} // Force re-render when style changes
      ref={editorRef}
      elementObj={elementObj}
    />
  );

  // return (
  //   <textarea
  //     className="edit-textarea"
  //     style={{ ...style, resize: 'none' }}
  //     rows={1}
  //     aria-label="Field name"
  //     value={editingValue}
  //     onBlur={onBlur}
  //     onChange={onChange}
  //     onKeyDown={onKeyDown}
  //     onInput={(event) => onInput(event.target)}
  //     ref={textareaRef}
  //     placeholder={elementObj?.content?.placeholder || ''}
  //     onMouseDown={(e) => dispatch(elementChanged(elementObj))}
  //   />
  // );
};
