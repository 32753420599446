/* AUTH */

export const SHOW_BEGIN = 'SHOW_BEGIN';
export const SHOW_END = 'SHOW_END';
export const ELEMENT_UPDATE = 'ELEMENT_UPDATE';
export const USER_JOIN = 'USER_JOIN';
export const ROOM_UPDATE = 'ROOM_UPDATE';

export const PROJECT_INIT = 'PROJECT_INIT';
export const INDEX_UPDATED = 'INDEX_UPDATED';
export const PROJECT_UPDATED = 'PROJECT_UPDATED';

export const SELECTION_CHANGED = 'SELECTION_CHANGED';
export const SELECTION_UPDATED = 'SELECTION_UPDATED';

export const ELEMENT_CHANGED = 'ELEMENT_CHANGED';
export const ELEMENT_UPDATED = 'ELEMENT_UPDATED';

export const ELEMENT_COPIED = 'ELEMENT_COPIED';
export const SLIDE_COPIED = 'SLIDE_COPIED';

export const ELEMENT_DELETED = 'ELEMENT_DELETED';
export const SLIDE_DELETED = 'SLIDE_DELETED';

export const ELEMENT_ADD = 'ELEMENT_ADD';
export const SLIDE_ADDED = 'SLIDE_ADDED';

export const IMAGE_UPDATED = 'IMAGE_UPDATED';
export const SLIDE_IMAGE_UPDATED = ' SLIDE_IMAGE_UPDATED';

export const SLIDE_CHANGED = 'SLIDE_CHANGED';
export const SLIDE_UPDATED = 'SLIDE_UPDATED';

export const SLIDE_SELECTION_CHANGED = 'SLIDE_SELECTION_CHANGED';
export const SLIDE_SELECTION_UPDATED = 'SLIDE_SELECTION_UPDATED';

export const SLIDE_INDEX_CHANGED = 'SLIDE_INDEX_CHANGED';
export const SLIDE_INDEX_UPDATED = 'SLIDE_INDEX_UPDATED';

export const ELEMENT_INDEX_CHANGED = 'ELEMENT_INDEX_CHANGED';
export const ELEMENT_INDEX_UPDATED = 'ELEMENT_INDEX_UPDATED';

export const SET_HEADERSTYLEINFO = 'SET_HEADERSTYLEINFO';

export const ELEMENT_CUT_ADDED = 'ELEMENT_CUT_ADDED';
export const REDO = 'REDO';
export const UNDO = 'UNDO';

// export const SHOW_BEGIN = 'SHOW_BEGIN';
// export const SHOW_END = 'SHOW_END';
// export const ELEMENT_UPDATE = 'ELEMENT_UPDATE';
// export const USER_JOIN = 'USER_JOIN';
// export const ROOM_UPDATE = 'ROOM_UPDATE';
//
// export const ROOM_INIT = 'ROOM_INIT';
// export const ROOM_UPDATED = 'ROOM_UPDATED';
// export const ROOM_DISPOSE = 'ROOM_DISPOSE';
//
// export const SELECTION_CHANGED = 'SELECTION_CHANGED';
// export const SELECTION_UPDATED = 'SELECTION_UPDATED';
//
// export const ELEMENT_CHANGED = 'ELEMENT_CHANGED';
// export const ELEMENT_UPDATED = 'ELEMENT_UPDATED';
//
// export const REMOTE_CHANGED = 'REMOTE_CHANGED';
// export const REMOTE_UPDATED = 'REMOTE_UPDATED';
//
// export const LOCAL_CHANGED = 'LOCAL_CHANGED';
// export const LOCAL_UPDATED = 'LOCAL_UPDATED';
//
// export const TRACKS_CHANGED = 'TRACKS_CHANGED';
// export const TRACKS_UPDATED = 'TRACKS_UPDATED';
//
// export const FEED_CHANGED = 'FEED_CHANGED';
// export const FEED_UPDATED = 'FEED_UPDATED';

// ROOM_INIT
// ROOM_UPDATED
// ROOM_DISPOSE
// ELEMENT_CHANGED
// ELEMENT_UPDATED
// REMOTE_CHANGED
// REMOTE_UPDATED
// LOCAL_CHANGED
// LOCAL_UPDATED
