import { Skeleton } from 'antd';
import React from 'react';


let customWidth = {
  width: '236px'
};

let customHeight = {
  height: '208px'
};

const CardLoader = () => {
  return (
    <div style={{ display: 'grid', gap: '10px' }}>
      <Skeleton.Image style={{ ...customWidth, ...customHeight }} active={true} />
      <Skeleton.Input style={customWidth} active={true} size={'default'} />
      <Skeleton.Input style={customWidth} active={true} size={'default'} />
    </div>
  );
};

export default CardLoader;
