import { InputGroup, Label, Switch } from '@blueprintjs/core';
import { Col, Input, message, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { editProject, editTemplate } from '../../helpers/apiCalls';
import React from 'react';
import appConfig from '../../config';
import { PenIcon } from '../../ProjectSVG';
import { projectUpdated } from '../../redux/actions';
// window.project = this.state.project

const Detailoverlay = () => {
  let Details = appConfig.project;
  const dispatch = useDispatch();
  const [showindex, setShowIndex] = React.useState(false);
  const [showNav, setShowNav] = React.useState(false);
  const [showclass, setShowClass] = React.useState(false);
  const { project, projectid, title, slides, projectName } = useSelector((state) => state.Studio.present);
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'width' || name === 'height') {
      let newProject = JSON.parse(JSON.stringify(project));
      newProject.meta.size[name] = value * 1;
      dispatch(projectUpdated(newProject));
    }
    if (name === 'title') {
      // let projectTitle = JSON.parse(JSON.stringify(title));
      // projectTitle = value;
      dispatch(projectUpdated(project, slides, value));
    }
  };
  let color = Details.meta.style['background-color'];
  const handleClick = (e) => {
    if (e.target.value === 'showIndex') {
      setShowIndex(!showindex);
      appConfig.project.meta['showindex'] = !showindex;
    } else if (e.target.value === 'showNav') {
      setShowNav(!showNav);
      appConfig.project.meta['showNav'] = !showNav;
    } else if (e.target.value === 'showclass') {
      setShowClass(!showclass);
      appConfig.project.meta['showclass'] = !showclass;
    }
  };
  const save = () => {
    const obj = project;
    const JSONproject = JSON.stringify(obj);
    if (projectName === 'template') {
      let data = {
        data: {
          title: title,
          template: JSONproject,
        },
      };

      editTemplate(projectid, data)
        .then((res) => {
          message.success('Template Saved!');
        })
        .catch((err) => {
          message.error('Template not saved, Plz try again!', err);
        });
    } else {
      let data = {
        data: {
          title: title,
          project: JSONproject,
        },
      };
      editProject(projectid, data)
        .then((res) => {
          message.success('Project Saved!');
        })
        .catch((err) => {
          message.error('Project not saved, Plz try again!', err);
        });
    }
  };
  return (
    <div className="detailsoverlaycss">
      <div style={{ marginBottom: '-44px' }}>
        <Row>
          <div style={{ display: 'flex' }} className="slidePropertyPopoverInner">
            <InputGroup
              name="title"
              style={{
                width: '187px',
                height: '28px',
                fontSize: '13px',
                color: '#000000',
                marginLeft: '-4px',
                boxShadow: 'none',
                fontWeight: '500',
              }}
              asyncControl={true}
              value={title}
              onChange={(e) => handleChange(e)}
            />

            <PenIcon />
          </div>
        </Row>
        <hr />
        <Row style={{ marginTop: '-6px' }}>
          <Label style={{ fontSize: '12px', fontWeight: '300', color: '#545353', marginBottom: '4px' }}>
            Project size
          </Label>
          <Input
            style={{
              width: '95px',
              height: '25px',
              borderRadius: '6px',
              fontSize: '10px',
              marginTop: '25PX',
              marginLeft: '-64px',
              color: '#717171',
            }}
            name="height"
            // value={edit.height}
            type="number"
            onChange={(e) => handleChange(e)}
            defaultValue={project?.meta?.size.height}
          />
          <Input
            style={{
              width: '97px',
              height: '26px',
              borderRadius: '6px',
              fontSize: '10px',
              marginTop: '24px',
              marginLeft: '4px',
              color: '#717171',
            }}
            name="width"
            // value={edit.width}
            type="number"
            onChange={(e) => handleChange(e)}
            defaultValue={project?.meta?.size.width}
          />
        </Row>
        <hr />
        <Row style={{ marginTop: '-6px' }}>
          {/* <Label>{size}</Label> */}
          <Label style={{ fontSize: '12px', fontWeight: '300', color: '#545353', marginBottom: '4px' }}>
            Project color
          </Label>

          <Input
            style={{
              width: '195px',
              height: '25px',
              borderRadius: '6px',
              fontSize: '10px',
              color: '#717171',
            }}
            name="color"
            // value={edit.color}
            onChange={(e) => handleChange(e)}
            defaultValue={color}
          />
        </Row>
        <hr />
        <Row style={{ marginBottom: '-22px' }}>
          <Col span={12}>
            <Label style={{ fontSize: '12px', fontWeight: '300', color: '#545353', width: '162px' }}>
              Show Resource Index Menu{' '}
            </Label>
          </Col>
          <Col span={12} style={{ paddingLeft: '65px' }}>
            <Switch value="showIndex" onChange={handleClick} />
          </Col>
        </Row>
        <hr />
        <Row style={{ marginBottom: '-22px' }}>
          <Col span={12}>
            <Label style={{ fontSize: '12px', fontWeight: '300', color: '#545353', width: '162px' }}>
              Show navigation buttons
            </Label>
          </Col>
          <Col span={12} style={{ paddingLeft: '65px' }}>
            <Switch value="showNav" onChange={handleClick} />
          </Col>
        </Row>
        <hr />
        <Row style={{ marginTop: '-6px', marginBottom: '-22px' }}>
          <Col span={12}>
            <Label style={{ fontSize: '12px', fontWeight: '300', color: '#545353', width: '162px' }}>
              Show class breaker button
            </Label>
          </Col>
          <Col span={12} style={{ paddingLeft: '65px' }}>
            <Switch value="showclass" onChange={handleClick} />
          </Col>
        </Row>
        <hr />
        <Row style={{ marginTop: '-6px' }}>
          <Col span={12}>
            <Label
              style={{
                fontSize: '12px',
                fontWeight: '300',
                color: '#545353',
                width: '162px',
                cursor: 'move',
              }}
              onClick={save}>
              Save Project
            </Label>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Detailoverlay;
