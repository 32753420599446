import React, { Component } from 'react';
import { Routes } from './routes';
import { BrowserRouter } from 'react-router-dom';
import { EuiProvider } from '@elastic/eui';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@elastic/eui/dist/eui_theme_light.css';
// import 'antd/dist/antd.css';

// setup fake backend
import { configureFakeBackend } from './helpers';
//Css
import './style.css';
import './vanimation.css';
// Themes
// default
import './assets/scss/theme.scss';
import ReactGA from 'react-ga';
import { isUserAuthenticated } from './helpers/authUtils';
import { useSelector } from 'react-redux';
// dark
// import './assets/scss/theme-dark.scss';

// rtl
// import './assets/scss/theme-rtl.scss';

ReactGA.initialize('UA-171648221-1', {
  debug: false,
  titleCase: false,
  gaOptions: {
    siteSpeedSampleRate: 100,
  },
});

// configure fake backend
configureFakeBackend();

/**
 * Main app component
 */
const App = () => {
  // const user = useSelector((state) => state.Auth.user);
  const [isAuthorized] = React.useState(isUserAuthenticated());
  // React.useEffect(() => {
  //     let isUser = isUserAuthenticated();
  //     if (isUser) {
  //         setIsAuthorized(true);
  //     }
  // }, [user]);

  // console.log("user:::::::", user)

  return (
    <EuiProvider>
      <BrowserRouter>
        <Routes isAuthorized={isAuthorized} />
      </BrowserRouter>
    </EuiProvider>
  );
};
export default App;
