import React, { useEffect, useState } from 'react';
import { Button } from '@blueprintjs/core';
import appConfig from '../../../config';
import { Select } from 'antd';
import usePaginatedApi from '../../../customHooks/usePaginationApi';

export const Capitalize = (stringData) => stringData.charAt(0).toUpperCase() + stringData.slice(1);

function AddImage({ imageSelected, imageData }) {
  const { Option } = Select;
  const { data: assets, error, loading, loadMore, searchParams } = usePaginatedApi('assets', 10);
  const [contentType, setContenttype] = useState('background');

  const sortChildren = [];
  const sortOptions = appConfig.sortAssetList;
  for (let i = 0; i < sortOptions.length; i++) {
    sortChildren.push(<Option key={sortOptions[i]}>{Capitalize(sortOptions[i])}</Option>);
  }

  useEffect(() => {
    if (contentType) {
      searchParams(contentType, 'contenttype');
    }

  }, [contentType]);

  const handleSortChange = (value) => {
    setContenttype(value);
  };

  return (
    <div className="drawer-container">
      <div>
        <Select
          className="projectsSelect"
          name="status"
          style={{ width: '100%' }}
          placeholder=" Content Type"
          defaultValue={'background'}
          onChange={handleSortChange}
        >
          {sortChildren}
        </Select>
      </div>

      <div className="photos-menu-conatiner">
        {assets?.map((item, index) => {
          if (item.attributes.asset.data) {
            const isSelected = imageData?.findIndex((img) => img.imageId === item.id) > -1;
            return (
              <div className="photos-menu-image" key={index}>
                <img
                  key={item.id}
                  className={`image-shadow ${isSelected ? 'tickmark' : null}`}
                  style={{}}
                  onClick={(ev) => {
                    ev.stopPropagation();
                    const addImageUrl =
                      appConfig.socketURL +
                      item.attributes.asset.data[0].attributes?.url.replace('/', '');

                    const width = 20;
                    const height =
                      (item.attributes.asset.data[0].attributes?.height /
                        item.attributes.asset.data[0].attributes?.width) *
                      33;
                    let dimension = { width, height };
                    imageSelected(item.id, addImageUrl, dimension);
                    // dispatch(elementadd(addImageUrl, cal));
                  }}
                  alt={'Bg-Image' + index}
                  src={
                    appConfig.socketURL +
                    item.attributes.asset.data[0].attributes?.url.replace('/', '')
                  }
                />
                {isSelected && <span className="tickmark">&#10004;</span>}
              </div>
            );
          } else {
            return null;
          }
        })}
      </div>
      {assets?.length >= 10 ? (
        <Button loading={loading} className="loadMoreBtn" onClick={loadMore}>
          Load more
        </Button>
      ) : (
        <p className="fw-2 text-center">
          No data to display{' '}
          <span
            className="fw-3 text-success cursor-pointer"
            onClick={(ev) => {
              console.log('Reset', ev);
            }}>
            Reset
          </span>
        </p>
      )}
      {/* <div className="loading-container">
        <div className="loader"></div>
      </div>
      {loading ? (
        <div className="loading-container">
          <div className="loader"></div>
        </div>
      ) : (
        <>
          
        </>
      )} */}

    </div>
  );
}

export default AddImage;
