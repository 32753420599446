import React from 'react';

export const X = ({
  width = '25',
  height = '25',
  className = '',
  style = {}
}) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.8711 7.40609L18.4611 5.99609L12.8711 11.5861L7.28109 5.99609L5.87109 7.40609L11.4611 12.9961L5.87109 18.5861L7.28109 19.9961L12.8711 14.4061L18.4611 19.9961L19.8711 18.5861L14.2811 12.9961L19.8711 7.40609Z"
        fill="black"
        fillOpacity="0.54"
      />
    </svg>
  );
};
