import React, {useState} from 'react';
import {Button, Classes, Position, Tooltip} from '@blueprintjs/core';
import AddBackground from './AddBackground';
import {BackgroundIcon} from '../../../ProjectSVG';
import {Col, Row} from 'antd';
import {slideimageUpdated} from '../../../redux/studio/actions';
import Backgro from '../../../assets/images/Backgro.png';
import {useDispatch} from 'react-redux';
import {Drawer} from '../../../ui/Drawer/Drawer';


export const AddBackgroundDrawer = () => {

  const [buttonData, setButtonData] = useState({
    buttonURL: '',
    buttonId: '',
    dimension: {}
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(() => false);
  const dispatch = useDispatch();

  const buttonSelected = (id, url, dimension) => {
    setButtonData({
      buttonURL: url,
      buttonId: id,
      dimension
    });
  };

  const addButtonToCanvas = () => {
    if (buttonData.buttonId && buttonData.buttonURL) {
      dispatch(slideimageUpdated({
        image: buttonData.buttonURL,
        type: 'background',
        dimension: buttonData.dimension,
      }));
      setIsDrawerOpen(false);
    }
  };
  return (
    <div>
      <Tooltip content="Add Background" position={Position.RIGHT_TOP}>
        <Button onClick={() => setIsDrawerOpen(true)} className="drawerbuttons" minimal={true}>
          <BackgroundIcon/>
          <img src={Backgro} className="backgro_css" alt=""/>
        </Button>
      </Tooltip>
      <Drawer
        className="drawer"
        position={Position.LEFT}
        onClose={() => setIsDrawerOpen(false)}
        isOpen={isDrawerOpen}
        title="Background"
      >
        <div className={Classes.DRAWER_BODY} onClick={() => setButtonData({buttonURL: '', buttonId: ''})}>
          <AddBackground buttonSelected={buttonSelected} id={buttonData.buttonId}/>
        </div>
        <div className={Classes.DRAWER_FOOTER} style={{overflowX: 'hidden'}}>
          <Row style={{display: 'flex', justifyContent: 'right'}}>
            <Col>
              <div style={{display: 'flex', justifyContent: 'right'}}>
                <p>{buttonData.buttonId ? 1 : 0} Selected</p>{' '}
                <Button className="drawerinsertButton" onClick={addButtonToCanvas}>
                  Insert
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Drawer>
    </div>
  );
};
