// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_dashboard_root__MsTAE {}

.styles_banner_root__Vydzi {
    background-color: #FAF7EF;
    margin-top: 22px;
}

.styles_banner_left__cPIif {
    width: 30%;
    display: flex;
    justify-content: flex-end;
    height: 80%
}

.styles_banner_card__GNvKt {
    width: 109px;
    height: 145px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-right: 20px;
}

.styles_banner_card_text__sTG-X {
    font-size: 16px;
    font-weight: 500;
    color: #181616;
    margin-top: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/pages/dashboard/styles.module.css"],"names":[],"mappings":"AAAA,+BAAiB;;AAEjB;IACI,yBAAyB;IACzB,gBAAgB;AACpB;;AAEA;IACI,UAAU;IACV,aAAa;IACb,yBAAyB;IACzB;AACJ;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;IACvB,kBAAkB;IAClB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,gBAAgB;AACpB","sourcesContent":[".dashboard_root {}\n\n.banner_root {\n    background-color: #FAF7EF;\n    margin-top: 22px;\n}\n\n.banner_left {\n    width: 30%;\n    display: flex;\n    justify-content: flex-end;\n    height: 80%\n}\n\n.banner_card {\n    width: 109px;\n    height: 145px;\n    border-radius: 10px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n    text-align: center;\n    margin-right: 20px;\n}\n\n.banner_card_text {\n    font-size: 16px;\n    font-weight: 500;\n    color: #181616;\n    margin-top: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dashboard_root": `styles_dashboard_root__MsTAE`,
	"banner_root": `styles_banner_root__Vydzi`,
	"banner_left": `styles_banner_left__cPIif`,
	"banner_card": `styles_banner_card__GNvKt`,
	"banner_card_text": `styles_banner_card_text__sTG-X`
};
export default ___CSS_LOADER_EXPORT___;
