import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { logoutUser } from '../../redux/actions';
import ReactGA from 'react-ga';

const Logout = (props) => {
  React.useEffect(() => {
    console.log('THIS PROPS', props);

    let path = 'app' + props?.location?.pathname;
    ReactGA.pageview(path);

    props.logoutUser(null);
  }, []);

  return (
    <React.Fragment>
      <Navigate to="/account/login" replace={true} />
    </React.Fragment>
  );
};

export default connect(null, { logoutUser })(Logout);
