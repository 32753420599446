import React, { useCallback, useState } from 'react';
import { Button, Dialog } from '@blueprintjs/core';

import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import appConfig from '../../../config';

function Preview({ disabled }) {
  const { projectid } = useSelector((state) => state.Studio.present);
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = useCallback(() => setIsOpen(false), []);

  const publish = () => {
    setIsOpen(true);
  };
  function copyEmbed() {
    /* Get the text field */
    var copyText = document.getElementById('copyEmbed');

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    /* Alert the copied text */
    alert('Embed Data: ' + copyText.value);
  }

  function copyLink() {
    /* Get the text field */
    var copyText = document.getElementById('copyLink');

    /* Select the text field */
    copyText.select();
    copyText.setSelectionRange(0, 99999); /* For mobile devices */

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(copyText.value);

    /* Alert the copied text */
    alert('Link Copied: ' + copyText.value);
  }

  return (
    <div>
      <button className="primary-button" onClick={publish} disabled={disabled}>
        Preview
      </button>
      <Dialog
        isOpen={isOpen}
        onClose={handleClose}
        isCloseButtonShown={true}
        title={'Preview'}
        className="previewBox">
        <Row className="previewBoxRow">
          <Col className="previewBoxiframe ">
            <iframe title="title" src={`${appConfig.player}?prj=${projectid}`}></iframe>
          </Col>
          <Col className="previewBoxInfo ">
            <Row>
              <Col>
                <span>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="15" cy="15" r="15" fill="#D9D9D9" fillOpacity="0.7" />
                    <path
                      d="M14.375 19.1667H10.8333C9.65278 19.1667 8.66319 18.7674 7.86458 17.9688C7.06597 17.1701 6.66667 16.1806 6.66667 15C6.66667 13.8194 7.06597 12.8299 7.86458 12.0313C8.66319 11.2326 9.65278 10.8333 10.8333 10.8333H14.375V12.0833H10.8333C10 12.0833 9.30555 12.3611 8.75 12.9167C8.19444 13.4722 7.91667 14.1667 7.91667 15C7.91667 15.8333 8.19444 16.5278 8.75 17.0833C9.30555 17.6389 10 17.9167 10.8333 17.9167H14.375V19.1667ZM11.7708 15.625V14.375H18.2292V15.625H11.7708ZM15.625 19.1667V17.9167H19.1667C20 17.9167 20.6944 17.6389 21.25 17.0833C21.8056 16.5278 22.0833 15.8333 22.0833 15C22.0833 14.1667 21.8056 13.4722 21.25 12.9167C20.6944 12.3611 20 12.0833 19.1667 12.0833H15.625V10.8333H19.1667C20.3472 10.8333 21.3368 11.2326 22.1354 12.0313C22.934 12.8299 23.3333 13.8194 23.3333 15C23.3333 16.1806 22.934 17.1701 22.1354 17.9688C21.3368 18.7674 20.3472 19.1667 19.1667 19.1667H15.625Z"
                      fill="#939393"
                    />
                  </svg>
                </span>
              </Col>
              <Col>
                <h5 style={{ marginTop: '14px' }}>Preview Link</h5>
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col>
                <input id="copyLink" defaultValue={`${appConfig.player}?prj=${projectid}`} />
              </Col>
              <Col>
                <Button className="previewBoxbutton" onClick={copyLink}>
                  Copy
                </Button>
              </Col>
            </Row>
            <Row className="previewBoxInfoRow">
              <Col>
                <span>
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle cx="15" cy="15" r="15" fill="#D9D9D9" fillOpacity="0.7" />
                    <path d="M13 20L8 15L13 10" stroke="#939393" strokeWidth="1.5" />
                    <path d="M16 20L21 15L16 10" stroke="#939393" strokeWidth="1.5" />
                  </svg>
                </span>
              </Col>
              <Col>
                <h5 style={{ marginTop: '14px' }}>Embed Link</h5>
              </Col>
            </Row>
            <Row style={{ marginTop: '10px' }}>
              <Col>
                <input
                  id="copyEmbed"
                  defaultValue={`<iframe src="${appConfig.player}?prj=${projectid}"></iframe>`}
                />
              </Col>
              <Col>
                <Button className="previewBoxbutton" onClick={copyEmbed}>
                  Copy
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      </Dialog>
    </div>
  );
}

export default Preview;
