import React, { useEffect, useState } from 'react';
import { Collapse, Input, Modal } from 'antd';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useRef } from 'react';
import {
  AddCircleIcon,
  ArrowDownIcon,
  ArrowDownOutlinedIcon,
  ArrowLineRightOutlinedIcon,
  ArrowUpIcon,
  ArrowUpOutlinedIcon,
  CopyIcon,
  DeleteIcon,
  DragIndicatorIcon,
  EyeIcon,
  PlusIcon,
} from '../components/Icon/icons';

const customExpandIcon = (props) => {
  if (props.isActive) {
    return (
      <div
        onClick={(e) => {
          props.expandIcon(props.record, e);
        }}>
        <ArrowDownOutlinedIcon />
      </div>
    );
  } else {
    return (
      <div
        onClick={(e) => {
          props.expandIcon(props.record, e);
        }}>
        <ArrowUpOutlinedIcon />
      </div>
    );
  }
};

const emptySlides = [{}, {}, {}, {}, {}, {}, {}, {}, {}];

const CustomTransfer = ({ dataSource, dataTarget, slidesTitleName, dataUpdated, sourceRef, targetRef }) => {
  const [showMore, setShowMore] = useState({});
  const [slides, setSlides] = useState(dataTarget?.length ? dataTarget : emptySlides);
  const [showDesignTemplateModal, setShowDesignTemplateModal] = useState({});
  const [editSlideTitle, setEditSlideTitle] = useState({});
  const inputRef = useRef('');

  React.useEffect(() => dataUpdated(slides), [slides]);
  const fetchIcon = (url) => 'https://apilw.html5.run' + url;

  useEffect(() => {
    if (sourceRef.current) {
      sourceRef.current.addEventListener('scroll', () => {
        if (sourceRef.current) {
          localStorage.setItem('sourceScrollPosition', sourceRef.current.scrollTop);
        }
      });
    }
  }, [sourceRef.current]);
  useEffect(() => {
    if (targetRef.current) {
      targetRef.current.addEventListener('scroll', () => {
        if (targetRef.current) {
          localStorage.setItem('targetScrollPosition', targetRef.current.scrollTop);
        }
      });
    }
  }, [targetRef.current]);

  const TransferSource = ({ dataSource }) => {
    return (
      <Droppable droppableId="source" isDropDisabled={true}>
        {(provided, snapshot) => (
          <div ref={provided.innerRef} {...provided.droppableProps} className="source">
            <div className="source-container" ref={sourceRef}>
              {Object.keys(dataSource)
                .sort()
                .map((slideKey, idx) => {
                  const index = idx + 1;
                  const slidesData = dataSource[slideKey];
                  return (
                    <Collapse
                      defaultActiveKey={[1]}
                      style={
                        index < Object.keys(dataSource).length
                          ? {}
                          : {
                            borderBottom: 'none',
                          }
                      }
                      key={index}
                      ghost
                      expandIcon={customExpandIcon}
                      expandIconPosition={'end'}>
                      <Collapse.Panel
                        header={
                          <div className="source-title">
                            <span className="title">
                              {slideKey} Slide{slidesData.length !== 1 ? 's' : ''}
                            </span>
                            <span className="subtitle">({slidesData.length})</span>
                          </div>
                        }
                        key={1}>
                        <div className="slide-container">
                          {slidesData?.map((slide, slideIdx) => {
                            if (slideIdx > 3 && !showMore[index]) { return null; }
                            const icon = slide?.icon?.data?.attributes?.url;
                            return (
                              <Draggable
                                key={slide?.id}
                                draggableId={`${slideKey}-${slideIdx}`}
                                index={slide?.id}>
                                {(provided, snapshot) => (
                                  <>
                                    <div
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                      className="dragable-content">
                                      <div className="slide-content">
                                        <div className="slide">
                                          <img
                                            style={{ width: 32 }}
                                            src={fetchIcon(icon)}
                                            alt="temp"
                                          />
                                          <span>{slide?.name}</span>
                                        </div>
                                        <div
                                          className="slide-review"
                                          onClick={() =>
                                            setShowDesignTemplateModal({
                                              ...slide,
                                              icon,
                                            })
                                          }>
                                          <EyeIcon className="icon" />
                                        </div>
                                      </div>
                                      <div {...provided.dragHandleProps}>
                                        <DragIndicatorIcon className="icon" />
                                      </div>
                                    </div>
                                    {snapshot.isDragging ? (
                                      <div className="dragable-content dnd-copy">
                                        <div className="slide-content">
                                          <div className="slide">
                                            <img
                                              style={{ width: 32 }}
                                              src={fetchIcon(icon)}
                                              alt="temp"
                                            />
                                            <span>{slide?.name}</span>
                                          </div>
                                          <div className="slide-review">
                                            <EyeIcon className="icon" />
                                          </div>
                                        </div>
                                        <DragIndicatorIcon className="icon" />
                                      </div>
                                    ) : null}
                                  </>
                                )}
                              </Draggable>
                            );
                          })}
                          {slidesData?.length > 3 && (
                            <div className="show-more-btn">
                              <div
                                className="button"
                                onClick={() =>
                                  showMore[index]
                                    ? setShowMore(delete showMore.index)
                                    : setShowMore({ ...showMore, [index]: index })
                                }>
                                {showMore[index] ? 'Show less' : 'Show more'}
                              </div>
                            </div>
                          )}
                          {showDesignTemplateModal?.name && (
                            <Modal
                              open={true}
                              footer={null}
                              destroyOnClose
                              onCancel={() => setShowDesignTemplateModal(false)}>
                              <div className="modal-container">
                                <div className="header">
                                  <img
                                    style={{ width: 32 }}
                                    src={fetchIcon(showDesignTemplateModal?.icon)}
                                    alt="img"
                                  />
                                  <span className="title">
                                    {showDesignTemplateModal?.name}
                                  </span>
                                </div>
                              </div>
                            </Modal>
                          )}
                        </div>
                      </Collapse.Panel>
                    </Collapse>
                  );
                })}
            </div>
          </div>
        )}
      </Droppable>
    );
  };

  const TransferTarget = ({ targetTitle }) => {
    const addNewSlide = (slideNo) => {
      if (slideNo) {
        const otherHalfSlides = slides.splice(slideNo);
        setSlides([...slides, {}, ...otherHalfSlides]);
      } else { setSlides([...slides, {}]); }
    };
    const deleteSlide = (index) => {
      if (slides.length === 1) { return null; }
      slides.splice(index, 1);
      setSlides([...slides]);
    };
    const copySlide = (slideNo) => {
      const otherHalfSlides = slides.splice(slideNo);
      setSlides([...slides, slides[slideNo - 1], ...otherHalfSlides]);
    };
    const moveDownSlide = (index) => {
      const element = slides.splice(index, 1)[0];
      slides.splice(index + 1, 0, element);
      setSlides([...slides]);
    };
    const moveUpSlide = (index) => {
      const leftSlide = slides.splice(index, 1)[0];
      slides.splice(index - 1, 0, leftSlide);
      setSlides([...slides]);
    };
    const handleEditTitleNameBtnEvent = (slideNo) => {
      slides.splice(slideNo, 1, { ...slides[slideNo], title: inputRef?.current?.input?.value });
      setSlides([...slides]);
      if (editSlideTitle[slideNo]) {
        const slideData = editSlideTitle;
        delete slideData[slideNo];
        setEditSlideTitle({ ...slideData });
      } else { setEditSlideTitle({ ...editSlideTitle, [slideNo]: true }); }
    };
    const handleSlideDataDeletion = (index) => {
      const slidesData = slides;
      slidesData.splice(index, 1, { title: slidesData[index]?.title });
      setSlides([...slidesData]);
    };

    const RenderSlideNameComponent = ({ slide, index, slideNo }) => {
      return (
        <div className="slide-name">
          <div className="text-muted" style={{ whiteSpace: 'pre' }}>
            Slide {slideNo} -
          </div>
          {!editSlideTitle[index] ? (
            <div className="slide-title" onClick={() => handleEditTitleNameBtnEvent(index)}>
              {slide?.title ? slide.title : 'Add slide title'}
            </div>
          ) : (
            <Input
              autoFocus="autoFocus"
              placeholder="Add slide title"
              ref={inputRef}
              onBlur={() => handleEditTitleNameBtnEvent(index)}
            />
          )}
        </div>
      );
    };

    const RenderSlideOperationsComponent = ({ index, slideNo }) => {
      return (
        <div className="slide-operations">
          <div
            onClick={() => moveUpSlide(index)}
            style={{ cursor: index !== 0 ? 'pointer' : 'not-allowed' }}
            className="icon">
            <ArrowUpIcon />
          </div>

          <div
            onClick={() => moveDownSlide(index)}
            style={{ cursor: index !== slides.length - 1 ? 'pointer' : 'not-allowed' }}
            className="icon">
            <ArrowDownIcon />
          </div>
          <div onClick={() => copySlide(slideNo)} className="icon clickable">
            <CopyIcon />
          </div>
          {slides.length !== 1 && (
            <div className={`icon clickable`} onClick={() => deleteSlide(index)}>
              <DeleteIcon />
            </div>
          )}
          <div onClick={() => addNewSlide(slideNo)} className="icon clickable">
            <AddCircleIcon />
          </div>
        </div>
      );
    };

    return (
      <div className="target">
        <div className="target-container" ref={targetRef}>
          <div className="title">{targetTitle}</div>
          <div className="slides">
            {slides.map((slide, index) => {
              const slideNo = index + 1;
              return slide?.name ? (
                <div className="slide" key={slideNo}>
                  <div className="slide-header">
                    <RenderSlideNameComponent slide={slide} index={index} slideNo={slideNo} />
                    <RenderSlideOperationsComponent index={index} slideNo={slideNo} />
                  </div>

                  <div className="slide-content">
                    <div className="drop-container">{slide?.name}</div>
                    <div
                      className="delete-slide icon clickable"
                      onClick={() => handleSlideDataDeletion(index)}>
                      <DeleteIcon />
                    </div>
                  </div>
                </div>
              ) : (
                <Droppable droppableId={slideNo.toString()} key={slideNo}>
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                      <div className="slide">
                        <div className="slide-header">
                          <RenderSlideNameComponent
                            slide={slide}
                            index={index}
                            slideNo={slideNo}
                          />
                          <RenderSlideOperationsComponent index={index} slideNo={slideNo} />
                        </div>
                        <div className="slide-content has-content">{provided.placeholder}</div>
                      </div>
                    </div>
                  )}
                </Droppable>
              );
            })}
          </div>

          <button className="add-slide" onClick={() => addNewSlide()}>
            <PlusIcon />
          </button>
        </div>
      </div>
    );
  };

  const onDragEnd = (result) => {
    if (!result?.destination?.droppableId) { return null; }
    const [draggedKey, draggedIndex] = result.draggableId.split('-');
    const slidesData = slides;
    const targetIndex = Number(result.destination.droppableId) - 1;
    const { name, type, id } = dataSource[draggedKey][draggedIndex];
    slidesData[targetIndex] = { ...slidesData[targetIndex], name, type, id };
    setSlides([...slidesData]);
  };

  return (
    <DragDropContext onDragEnd={(result) => onDragEnd(result)}>
      <div className="transfer-component">
        <TransferSource dataSource={dataSource} />
        <div className="switch">
          <ArrowLineRightOutlinedIcon className="icon" />
        </div>
        <TransferTarget targetTitle={slidesTitleName} />
      </div>
    </DragDropContext>
  );
};

export default CustomTransfer;
