import React, {useState} from 'react';
import AssetsListingTab from './assetsListingTab';
import BuilderLayout from '../BuilderLayout';
import CheckboxFilterWrapper from '../CheckboxFilter/CheckboxFilterWrapper';
import {Tabs} from 'antd';
import SchoolTheme from './SchoolTheme';


const sideData = {
  ['Asset Type']: ['Image', 'Gif', 'Logo'],
};

const AssetsBuilder = (props) => {
  const {
    totalMedia,
    loading,
    logoImage,
    setLogoImage,
    setUploadType,
    setMediamodel,
    setDeleteLoader,
    setPagination,
    setFilter
  } = props;
  const [tabNumber, setTabNumber] = useState(0);

  function handleSearch(e) {
    setFilter(prev => ({
      ...prev,
      filters: {
        ...prev?.filters,
        $and: [
          {
            name: {
              $contains: e.target.value
            }
          }
        ]
      }
    }));
  }

  function loadMoreHandle() {
    setPagination((prev) => ({
      ...prev,
      start: prev.start + 20
    }));
  }

  function handleChangeFilter(e) {
    console.log('eveny', e);
  }

  const tabItems = [
    {
      label: 'My Assets',
      children: <AssetsListingTab totalMedia={totalMedia} loading={loading} handleSearch={handleSearch} handleChangeFilter={handleChangeFilter} setDeleteLoader={setDeleteLoader}
        loadMoreHandle={loadMoreHandle}/>,
    },
    {
      label: 'My School theme',
      children: <SchoolTheme logoImage={logoImage} setLogoImage={setLogoImage} setUploadType={setUploadType} setMediamodel={setMediamodel}/>,
    },
  ];

  const tabChangeHandler = (e) => {
    setTabNumber(e);
  };

  const handleOnCheck = (data) => {
    setFilter(prev => ({
      ...prev,
      filters: {
        ...prev?.filters,
        $or: data['Asset Type']?.map((item) => ({
          assetType: {
            $eq: item
          }
        }))
      }
    }));

  };


  return (
    <BuilderLayout
      sideComponent={tabNumber === 1 ? null : <CheckboxFilterWrapper data={sideData} handleOnCheck={handleOnCheck}/>}
      contentComponent={
        <Tabs className={`primary-custom-tab asset-tab-height`} onChange={tabChangeHandler}>
          {tabItems.map(({label, children, ...rest}, index) => {
            return (
              <Tabs.TabPane
                tab={<h2 className="tab-text primary-header">{label}</h2>}
                key={index}
                {...rest}>
                {children}
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      }
    />
  );
};

export default AssetsBuilder;
