import React from 'react';
import { Icon, Label, Button } from '@blueprintjs/core';
import { Row, Col } from 'antd';

function AddShapes() {
  return (
    <div>
      <Row>
        <Col span={12} className="shapeTitle">
          <Label>Insert a Shape</Label>
        </Col>
        <Col span={12}>
          <div className="bp4-input-group .modifier">
            <Icon icon="search" />
            <input className="bp4-input" type="search" placeholder="Search input" dir="auto" />
          </div>
        </Col>
      </Row>
      <Row className="shapesIcons">
        <Icon icon="symbol - triangle - up" size="150" />
        <Icon icon="cloud" size="150" />
        <Icon icon="square" size="150" />
        <Icon icon="circle" size="150" />
        <Icon icon="cube" size="150" />
        <Icon icon="cloud" size="150" />
        <Icon icon="circle" size="150" />
        <Icon icon="cube" size="150" />
        <Icon icon="symbol - triangle - up" size="150" />
        <Icon icon="square" size="150" />
      </Row>
      <div className="addShapesButtons">
        <Button className="addShapesButtonsCancel">Cancel</Button>
        <Button className="addShapesButtonsSave">Add</Button>
      </div>
    </div>
  );
}

export default AddShapes;
