import React from 'react';
import { Label } from '@blueprintjs/core';
import { Row, Col, Form, Upload } from 'antd';
import FormItem from '../../FormItem';

export const AddProjectForm = ({
  onValuesChange = () => { },
  layout = {},
  onFinish = () => { },
  uploadProps = {},
  form,
  title = ''
}) => {
  return (<Form
    onValuesChange={onValuesChange}
    {...layout}
    form={form}
    name="control-hooks"
    onFinish={onFinish}>
    <div className="d-flex mb-4 px-3 align-items-center justify-content-between">
      <h2>{title}</h2>
      <div>
        <button
          className="rounded-pill outline-none  text-light"
          type="submit"
          style={{ background: '#6362B0 ', border: 'none', padding: '10px 15px' }}>
                    Save & Publish
        </button>
      </div>
    </div>

    <div className="card p-4 shadow schoolForm">
      <Row>
        <Col span={12}>
          <Label>Project Title</Label>
          <FormItem
            name="title"
            wrapperCol={{ span: 22 }}
            placeholder="Enter Projects"
          />
        </Col>
        <Col>
          <Label>Add Thumbnail</Label>
          <Upload
            {...uploadProps}>
            {uploadProps.childComponent}
          </Upload>
        </Col>
      </Row>
    </div>
  </Form>);
};
