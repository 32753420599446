// @flow

import { combineReducers } from 'redux';
import Layout from './layout/reducers';
import Auth from './auth/reducers';
import AppMenu from './appMenu/reducers';
import Studio from './studio/reducers';
import undoable from 'redux-undo';


export default combineReducers({
  Auth,
  AppMenu,
  Layout,
  Studio: Studio,
});
