import {Card, Col, message} from 'antd';
import React from 'react';
import styles from './styles.module.scss';
import moment from 'moment';

import ImageCard from './ImageCard';
import {deleteItem} from '../../helpers/apiCalls';

const MediaCard = ({id, media = {}, setDeleteLoader}) => {
  const {Meta} = Card;
  let imgUrl = media?.attributes?.url?.replace('/', '');
  let createdDate = moment(media?.attributes?.createdAt).format('Do MMMM YYYY');


  const deleteHandler = () => {
    deleteItem('assets', media?.id).then((res) => {
      message.success('Asset deleted successfully!');
      setDeleteLoader((prev) => !prev);
    }).catch((err) => {
      message.error('Plz try again!', err);
    });
  };

  return (
    <Col id={id} className={styles.colCard}>
      <Card
        hoverable
        style={{
          width: 240,
        }}
        className={`${styles.card} asset-card`}
        cover={<ImageCard imgUrl={imgUrl} deleteHandler={deleteHandler}/>}
      >
        <Meta title={media?.attributes?.name} description={`Uploaded on ${createdDate}`}/>
      </Card>
    </Col>
  );
};

export default MediaCard;
