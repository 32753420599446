import React from 'react';
import { Popover2 } from '@blueprintjs/popover2';
import { getLoggedInUser } from '../../helpers/authUtils';
import Male from '../../assets/images/users/male.jpg';
import { AccountAction } from './AccountAction';
import { ProfileImage } from './ProfileImage';

function AccountDetail() {
  const loggedInUser = getLoggedInUser();

  return (
    <Popover2
      content={
        <AccountAction
          userEmail={loggedInUser?.user?.email}
          userName={loggedInUser?.user?.username}
          image={Male}
          expiry="Expiry on 24 August 2022"
        />
      }>
      <ProfileImage image="/profilephoto.png" />
    </Popover2>
  );
}

export default AccountDetail;
