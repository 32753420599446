import { Button } from 'antd';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { elementadd } from '../../../redux/studio/actions';
import usePaginatedApi from '../../../customHooks/usePaginationApi';
import appConfig from '../../../config';


const AddTextType = () => {
  const dispatch = useDispatch();
  const { data, loading, error, loadMore, searchParams } = usePaginatedApi('assets', 10);

  useEffect(() => {
    searchParams('textboxes', 'contenttype');
  }, []);

  const addTextImageBox = (attributes) => {
    let url = appConfig.socketURL + attributes?.asset?.data[0]?.attributes?.url?.replace('/', '');
    let width = 30;
    let height = (attributes?.asset?.data[0]?.attributes?.height / attributes?.asset?.data[0]?.attributes?.width) * 33;
    dispatch(
      elementadd({
        code: 'Double click to edit',
        type: 'complex_text',
        fontFamily: 'Source Serif Pro, serif',
        url: url,
        width,
        height
      })
    );
  };
  const addTextBox = (subType) => {
    dispatch(
      elementadd({
        code: 'Double click to edit',
        type: 'texts',
        fontFamily: 'Source Serif Pro, serif',
        subType: subType,
      })
    );
  };

  return (
    <div className="addText-container" style={{ width: '100%' }}>
      <div style={{ padding: 24, width: '100%' }}>
        {/* <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('text box')}>
          Add a text box
        </Button> */}
        <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('heading')}>
          Add a heading
        </Button>
        {/* <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('title')}>
          Add a title
        </Button> */}
        <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('subTitle')}>
          Add a subtitle
        </Button>
        <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('body text')}>
          Add a body text
        </Button>
        <div>
          <p>Text Boxes</p>
        </div>
        {error ? (
          <p>There is an error</p>
        ) : (
          <div style={{ display: 'flex', flexWrap: 'wrap', width: '100%', gap: '1rem' }}>
            {data.map(({ attributes }, index) => (
              <div style={{ width: '43%', cursor: 'pointer' }} key={index} onClick={() => addTextImageBox(attributes)}>
                <img style={{ objectFit: 'contain', width: '100%', aspectRatio: '1/1' }} src={appConfig.socketURL + attributes?.asset?.data[0]?.attributes?.url?.replace('/', '')} alt={attributes?.asset?.name} />
              </div>
            ))}
          </div>
        )}
        {loading ? (
          <p>Loading</p>
        ) : (
          <Button className="loadMoreBtn" onClick={loadMore}>
            Load more
          </Button>
        )}
        {/* <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('bulleted text')}>
          Add bulleted body text
        </Button>
        <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('numbered text')}>
          Add numbered body text
        </Button>
        <Button
          className="w-100 margin-y-1 border-5 border-blue custom-btn"
          onClick={() => addTextBox('foodnote')}>
          Add footnote
        </Button> */}
      </div>
    </div>
  );
};

export default AddTextType;
