import React from 'react';
import { Col, Row } from 'antd';
import { CarouselSection } from './CarouselSection';
import { DescriptionSection } from './DescriptionSection';
import { LogoIcon } from '../../assets/svgs';

export const LessonTemplatePreview = ({ images, id, tags, themeViewModal = false, title, description, project = {}, titleName = '' }) => {
  return (
    <div>
      {themeViewModal && (
        <Row style={{ marginBottom: 24, padding: 12 }}>
          <Col>
            <div style={{ fontSize: 24, fontWeight: 500, marginBottom: 6 }} className="title">
              {title}
            </div>
            <div style={{ fontSize: 14, fontWeight: 300 }} className="description">
              {description}
            </div>
          </Col>
        </Row>
      )}
      <Row className='theme-preview-modal'>
        <Col md={24} lg={!themeViewModal ? 12 : 24}>
          <CarouselSection images={images} themeViewModal={themeViewModal} />
        </Col>
        {!themeViewModal && (
          <Col md={24} lg={12}>
            <DescriptionSection id={id} tags={tags} titleName={titleName} project={project} />
          </Col>
        )}
      </Row>
      <div className='d-flex justify-content-end' style={{ marginTop: '2rem' }}>
        <LogoIcon />
      </div>
    </div>
  );
};
