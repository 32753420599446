import React from 'react';
import { useSelector } from 'react-redux';
import _ from 'lodash';

function ProjectTitle() {
  const { title } = useSelector((state) => state.Studio.present);

  return <input className="lesson" value={title || ''} onChange={() => { }}></input>;
}

export default ProjectTitle;
