import React from 'react';
import { Button, Col, Row } from 'antd';
import { TemplateCardHoverSide } from './TemplateCardHoverSide';
import { imagesObjectReMap } from '../../../utils';
import { Assembly, Game, Lesson, Quiz, Worksheet, LogoIcon } from '../../../assets/svgs';
import { randomBackgroundColor } from '../../../constants/menuItems';

export const TemplateCard = ({ showHover = false, data = {} }) => {
  const { id, attributes } = data;
  let { type, title, description = '', images: imagesData = [], tags = [], project } = attributes;
  type = type?.toLowerCase() || 'assembly';
  const images = imagesObjectReMap(imagesData.data);
  const imageUrl = images.length ? images[0].imageUrl : '';
  const tagsList = createTagList(tags);
  const renderIcon = (type) => {
    switch (type) {
      case 'game':
        return <Game />;
      case 'lesson':
        return <Lesson />;
      case 'quiz':
        return <Quiz />;
      case 'assembly':
        return <Assembly />;
      case 'worksheet':
        return <Worksheet />;
      default:
        return null;
    }
  };

  function getRandomColor(colors) {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex].color;
  }

  const getBGColor = getRandomColor(randomBackgroundColor);

  return (
    <div key={id} className={`custom-card color-black d-flex flex-column gap-3 position-relative`} style={{ background: getBGColor }}>
      <div className="d-flex icon-button-link gap-5 align-items-center">
        <div
          style={{ padding: 8, borderRadius: '50%' }}
          className={`d-flex align-items-center justify-content-center bg-icon-${type} cursor-pointer`}>
          {renderIcon(type)}
        </div>
        <span style={{ fontSize: 12 }}>{type}</span>
      </div>
      <img style={{ aspectRatio: '2/1', borderRadius: 8, padding: 8 }} className="image" src={imageUrl} alt="" />
      <div className="title">{title}</div>
      <div title={description} className="description">
        {description?.length > 100 ? `${description.substring(0, 100)}...` : description}
      </div>

      <Row className="mt-auto" justify="space-between">
        <Col span={20} className="d-flex flex-wrap">
          {tagsList.length ? tagsList : null}
        </Col>
        <Col span={2}>
          <LogoIcon />
        </Col>
      </Row>

      {showHover ? <TemplateCardHoverSide images={images} id={id} tags={tags} title={title} project={project} /> : null}
    </div>
  );
};

const createTagList = (tags) => {
  return tags?.map(({ attributes, id }) => (
    <Button type="link" key={id}>
      {attributes.tagName}
    </Button>
  ));
};

