import React from 'react';
import { Rnd as ReactRndRotate } from 'react-rnd';
import { ResizeHandles } from './components/ResizeHandles';

export const Rnd = ({
  children,
  ...rest
}) => {
  // console.log('rest', rest);
  return (
    <ReactRndRotate
      {...rest}
    >

      <ResizeHandles isEditable={rest.disableDragging} />
      {children}
    </ReactRndRotate>
  );
};
