import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import $ from 'jquery';
import {
  AdvanceElement,
  AnimateElement,
  AnticlockIcontwo,
  BackElement,
  BackendElement,
  Clock90Icon,
  CopyElement,
  DeleteElement,
  FlipHorizontal,
  FlipVertical,
  ForendElement,
  FrontElement,
  Order,
  Questionbt,
  Rotate,
} from '../../ProjectSVG';
import appConfig from '../../config';
import 'react-contexify/ReactContexify.css';
import { elementAddCut, elementChanged, elementCopied, elementDeleted, elementUpdated, redoBehaviour, undoBehaviour } from '../../redux/actions';
import { Classes, Position } from '@blueprintjs/core';
import AdvancedFormat from './Forms/AdvancedFormatting/AdvancedFormat';
import ElementAnimation from './ElementAnimation';
import { Item, Menu as LolMenu, Separator, Submenu } from 'react-contexify';
import ToolbarDrawer from '../components/ToolbarDrawer';
import { ELEMENT_Type } from '../../constants';
import { WizardQuestion } from './Forms/WizardQuestion/WizardQuestion';
import { Drawer } from '../../ui/Drawer/Drawer';
import { isThemeOrTemplate, STUDIO_ELEMENTS } from '../utils';
import CanvasElements from './Canvas/CanvasElements';

const { CANVAS_EL, CANVAS_FRAME_EL } = STUDIO_ELEMENTS;


const MENU_ID = 'menu-id';
const styleRnd = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
};

const { DIV, IMG, SVG, TEXT, INDIV, ALL, POS, RND } = ELEMENT_Type;


const CanvasFrame = (props) => {
  const dispatch = useDispatch();
  const { selectedElement, selectedSlide, project } = useSelector((state) => state.Studio.present);
  const pastArray = useSelector((state) => state.Studio.past);
  const futureArray = useSelector((state) => state.Studio.future);
  const { style, setStyle } = props;
  const [slide, setSlide] = useState(appConfig.project.slides[0]);
  const [textDrawer, setTextDrawer] = useState(false);
  const [advancedFormattingDrawer, setAdvancedFormattingDrawer] = useState(() => false);
  const [animateDrawer, setAnimateDrawer] = useState(() => false);
  const [wizardQuestionDrawer, setWizardQuestionDrawer] = useState(() => false);
  const [cards, setCards] = useState([]);
  const [slideName, setslidename] = useState([]);
  const [copiedElement, setCopiedElement] = useState({
    action: '',
    element: {},
  });
  const lcanvasRef = useRef();


  React.useEffect(() => {
    if (selectedSlide?.id) {
      setCards(selectedSlide?.elements);
      setslidename(selectedSlide?.Meta?.Name);
    }
  }, [selectedSlide?.elements]);

  const onFinishtext = (value, id) => {
    const que = { question: value };
    for (let i = 0; i < cards.length; i++) {
      if (cards[i].id === id && que.question?.length) {
        dispatch(elementUpdated(cards[i], que.question, 'question'));
        message.success('Question added successfully');
      }
    }
  };


  //Context Menu Functions

  const copyElement = ({ props }) => {
    let { element } = props;
    if (!element) {
      return;
    }
    dispatch(elementCopied(element));
  };
  const deleteElement = ({ props }) => {
    let { element } = props;
    dispatch(elementDeleted(element.id));
  };

  //90 degree counter clockwise  rotation
  const handleCounterClockWise = ({ props }) => {
    let { element } = props;
    if (element.style.transformRotate.length) {
      let angle = element.style.transformRotate.split('(')[1].split('d')[0];
      if (typeof parseInt(angle) === 'number') {
        angle = parseInt(angle) - 90;
        return angle <= -360
          ? dispatch(elementUpdated(element, `rotate(0deg)`, 'transformRotate'))
          : dispatch(elementUpdated(element, `rotate(${angle}deg)`, 'transformRotate'));
      } else {
        console.log('no ide anto', angle);
      }
    } else {
      dispatch(elementUpdated(element, 'rotate(-90deg)', 'transformRotate'));
    }
    return;
  };
  //90 degree clockwise rotation
  const handleClockWise = ({ props }) => {
    let { element } = props;
    if (element.style.transformRotate.length) {
      let angle = element.style.transformRotate.split('(')[1].split('d')[0];
      if (typeof parseInt(angle) === 'number') {
        angle = parseInt(angle) + 90;
        return angle <= 360
          ? dispatch(elementUpdated(element, `rotate(${angle}deg)`, 'transformRotate'))
          : dispatch(elementUpdated(element, `rotate(0deg)`, 'transformRotate'));
      } else {
        console.log('no  ides', angle);
      }
    } else {
      dispatch(elementUpdated(element, 'rotate(90deg)', 'transformRotate'));
    }
    return;
  };
  // Flip vertically
  const onChangeflipright = ({ props }) => {
    let { element } = props;
    if (element.style.transformSkew === 'scaleX(-1)') {
      dispatch(elementUpdated(element, '', 'transformSkew'));
    } else if (element.style.transformSkew === 'scaleY(-1)') {
      dispatch(elementUpdated(element, 'scale(-1)', 'transformSkew'));
    } else if (element.style.transformSkew === 'scale(-1)') {
      dispatch(elementUpdated(element, 'scaleY(-1)', 'transformSkew'));
    } else {
      dispatch(elementUpdated(element, 'scaleX(-1)', 'transformSkew'));
    }
  };
  //Flip Horizontally
  const onChangefliptop = ({ event, props }) => {
    let { element } = props;
    if (element.style.transformSkew === 'scaleY(-1)') {
      dispatch(elementUpdated(element, '', 'transformSkew'));
    } else if (element.style.transformSkew === 'scaleX(-1)') {
      dispatch(elementUpdated(element, 'scale(-1)', 'transformSkew'));
    } else if (element.style.transformSkew === 'scale(-1)') {
      dispatch(elementUpdated(element, 'scaleX(-1)', 'transformSkew'));
    } else {
      dispatch(elementUpdated(element, 'scaleY(-1)', 'transformSkew'));
    }
  };
  //-----End Context Menu Functions


  React.useEffect(() => {
    if (selectedSlide && selectedSlide?.id) {
      setSlide(selectedSlide);
      window.canvasSlide = selectedSlide;
    }
  }, [selectedSlide]);

  // React.useEffect(() => {
  // }, [selectedElement]);

  React.useEffect(() => {
    let el = $(CANVAS_FRAME_EL);
    let hight = project.meta.size.height || '500';
    let width = project.meta.size.width || '1000';
    let top = (el.height() - (el.width() * 9) / 16) / 2;
    let left = (el.width() - (el.height() * 16) / 9) / 2;
    let elh = el.height();
    let elw = el.width();

    if (width > el.width()) {
      hight = (el.width() * 9) / 16;
      setStyle({
        height: hight + 'px',
        width: elw + 'px',
        top: top + 'px',
        left: '0px',
      });
    } else if (hight > el.height()) {
      width = (el.height() * 16) / 9;
      setStyle({
        height: elh + 'px',
        width: width + 'px',
        top: '0px',
        left: left,
      });
    } else {
      width = (el.height() * 16) / 9;
      setStyle({
        height: elh + 'px',
        width: width + 'px',
        top: '0px',
        left: left,
      });
    }


  }, [project]);


  const handleBringForward = ({ event, props }) => {
    const { element } = props;
    dispatch(elementUpdated(element, 'bringForward', 'z-index'));
  };
  const handleBringFront = ({ event, props }) => {
    const { element } = props;
    dispatch(elementUpdated(element, 'bringFront', 'z-index'));
  };
  const handleSendBack = ({ event, props }) => {
    const { element } = props;
    dispatch(elementUpdated(element, 'sendBack', 'z-index'));
  };
  const handleSendBackward = ({ event, props }) => {
    const { element } = props;
    dispatch(elementUpdated(element, 'sendBackward', 'z-index'));
  };
  const deleteElementText = (el) => {
    dispatch(elementDeleted(el.id));
    setTextDrawer(false);
  };


  const handleKeyPress = useCallback((event, props) => {
    const isMac = navigator.platform.toUpperCase().includes('MAC');
    const deleteKey = isMac ? 'Backspace' : 'Delete';
    const controlKey = isMac ? 'Meta' : 'Control';

    if (['Backspace', 'Delete'].includes(event.key)) {
      if (event.key === deleteKey && selectedElement.id) {
        if (selectedElement.type === TEXT) {
          let carretPosition = window.getSelection().anchorOffset;
          if (carretPosition > 0) {
            return;
          } else {
            dispatch(elementDeleted(selectedElement.id));
          }
        } else {
          dispatch(elementDeleted(selectedElement.id));
        }
        // dispatch(elementDeleted(selectedElement.id));
      }
      return;
    }
    // let element = _.cloneDeep(selectedElement);
    // switch (event.key) {

    //   case 'ArrowDown':
    //     dispatch(elementUpdated(element, {
    //       y: element.position.y + 10
    //     }, 'arrowMovement'));
    //     break;
    //   case 'ArrowUp':
    //     dispatch(elementUpdated(element, {
    //       y: element.position.y - 10
    //     }, 'arrowMovement'));
    //     break;
    //   case 'ArrowRight':
    //     dispatch(elementUpdated(element, {
    //       x: element.position.x + 10
    //     }, 'arrowMovement'));
    //     break;
    //   case 'ArrowLeft':
    //     dispatch(elementUpdated(element, {
    //       x: element.position.x - 10
    //     }, 'arrowMovement'));
    //     break;
    //   default:
    //     break;
    // }


    if (event.ctrlKey || event.metaKey) {
      switch (event.key) {
        case 'c':
          setCopiedElement({
            action: 'copy',
            element: selectedElement,
          });
          break;
        case 'v':
          if ('id' in copiedElement.element) {
            if (project.slides.findIndex((slide) => slide.elements.findIndex((element) => element.id === copiedElement.element.id) > -1) > -1) {
              if (copiedElement?.element?.id) {
                if (copiedElement?.action === 'copy') {
                  dispatch(elementCopied(copiedElement.element));
                } else if (copiedElement?.action === 'cut') {
                  dispatch(elementAddCut(copiedElement.element));
                }
              }
            }
          }
          break;
        case 'x':
          if (selectedElement?.id) {
            setCopiedElement({
              action: 'cut',
              element: selectedElement,
            });
            dispatch(elementDeleted(selectedElement.id));
          }
          break;
        case 'z':
          if (pastArray.length > 3) {
            dispatch(undoBehaviour());
          }

          break;
        case 'y':
          if (futureArray.length > 0) {
            dispatch(redoBehaviour());
          }
          break;
        default:
          break;
      }
    }
  }, [selectedElement, copiedElement]);


  useEffect(() => {
    window.addEventListener('keydown', handleKeyPress);
    return () => {
      window.removeEventListener('keydown', handleKeyPress);
    };
  }, [handleKeyPress]);


  return (
    <div
      id="canvasFrame"
      style={{ width: '100%', height: '100%' }}
      onDoubleClick={(e) => {
        let id = e.target.id;
        if (id === 'lcanvas' || id === 'pcanvas' || id === 'canvasFrame') {
          dispatch(elementChanged({}));
        }
      }}
      onClick={(e) => {
        if (e.target.offsetParent?.className === 'rsw-ce' || e.target.className.includes('resizer') || e.target.className.includes('resizable-draggable') || e.target.className.includes('rsw-editor')) {
          return;
        }
        if (!(e?.target?.className === 'edit-textarea') && !(e?.target?.className === 'iframe-wrapper') && !(e?.target?.className === 'rsw-ce') && !(e?.target?.className === 'rsw-toolbar')) {
          if (typeof e?.target?.className === 'string') {
            if (!e?.target?.className?.includes('parent-marker') && !e?.target?.className?.includes('resize-handle')) {
              dispatch(elementChanged({}));
            }
          }
        }


      }}>
      <div
        id="lcanvas"
        className="lcanvas"
        ref={lcanvasRef}
        style={{
          width: style.width,
          height: style.height,
          borderRadius: '10px',
          position: 'absolute',
          // maxWidth: 'calc(100vw - 110px)',
          // maxHeight: 'calc(100vh)',
          backgroundImage: slide.style ? slide.style['background-image'] : '',
          backgroundColor: slide.style ? slide.style['background-color'] : '',
          objectFit: 'cover',
          backgroundSize: 'cover',
          // border: '1px solid #d1d1d1',
        }}>
        <div
          className="grid-area bg-remove"
          // cellwidth={60}
          // strokeWidth={2}
          // cellwidth2={12}
          // linecolor={'#d71414'}
          ref={props.gridRef}>
          <LolMenu id={MENU_ID}>
            <Item onClick={copyElement}>
              <CopyElement />
              <div style={{ marginLeft: '10px' }}>Copy</div>
            </Item>
            <Item onClick={deleteElement}>
              <DeleteElement />
              <div style={{ marginLeft: '10px' }}>Move to trash</div>
            </Item>
            <Separator />
            {/* <Item onClick={handleClick}>Order</Item> */}
            <Submenu label={<Order />}>
              <Item onClick={handleBringForward}>
                <ForendElement />
                <div style={{ marginLeft: '10px' }}> Bring Forward</div>
              </Item>
              <Item onClick={handleBringFront}>
                <FrontElement />
                <div style={{ marginLeft: '10px' }}>Bring to front</div>
              </Item>
              <Item onClick={handleSendBack}>
                <BackElement />
                <div style={{ marginLeft: '10px' }}> Send to back</div>
              </Item>
              <Item onClick={handleSendBackward}>
                <BackendElement />
                <div style={{ marginLeft: '10px' }}> Send backward</div>
              </Item>
            </Submenu>
            <Submenu label={<Rotate />}>
              <Item onClick={handleClockWise}>
                <Clock90Icon />
                <div style={{ marginLeft: '10px' }}>Clockwise 90</div>
              </Item>
              <Item onClick={handleCounterClockWise}>
                <AnticlockIcontwo />
                <div style={{ marginLeft: '10px' }}> counter clockwise 90</div>
              </Item>
              <Item onClick={onChangefliptop}>
                <FlipVertical />
                <div style={{ marginLeft: '10px' }}> Flip Vertically</div>
              </Item>
              <Item onClick={onChangeflipright}>
                <FlipHorizontal />
                <div style={{ marginLeft: '10px' }}>Flip Horizontally</div>
              </Item>
            </Submenu>
            <Separator />
            <Item onClick={() => setAnimateDrawer(true)}>
              <AnimateElement />
              <div style={{ marginLeft: '10px' }}>Animate</div>
            </Item>
            <Item onClick={() => setAdvancedFormattingDrawer(true)}>
              <AdvanceElement />
              <div style={{ marginLeft: '10px' }}>Advance Format</div>
            </Item>
            {isThemeOrTemplate ? (
              <Item onClick={() => setWizardQuestionDrawer(true)}>
                <Questionbt />
                <div style={{ marginLeft: '10px' }}>Question</div>
              </Item>
            ) : null}
          </LolMenu>
          {slide?.elements?.length > 0 && slide.elements.filter((el) => el).map((el, index) => (
            <CanvasElements el={el} index={index} key={index} style={style} setStyle={setStyle} aspectRatio={props.aspectRatio} />
          ))}

        </div>
      </div>
      <Drawer
        className="drawer-text"
        position={Position.RIGHT}
        onClose={() => setTextDrawer(false)}
        isOpen={textDrawer}
        style={{ height: '100%' }}
        title={<b style={{ fontSize: '17px' }}>Edit text</b>}>
        <div style={{ overflow: 'hidden' }} className={Classes.DRAWER_BODY}>
          <div className="horizontal-line"></div>
          <div className="body-text">
            <ToolbarDrawer deleteElementText={deleteElementText} />
          </div>
        </div>
      </Drawer>
      <Drawer
        className="drawerWizard"
        position={Position.LEFT}
        isOpen={wizardQuestionDrawer}
        title="Wizard Questions"
        onClose={() => setWizardQuestionDrawer(false)}>
        <WizardQuestion slideName={slideName} data={cards} onFinishtext={onFinishtext} />
      </Drawer>

      <Drawer
        className="rightSideDrawe"
        title={'Advanced formatting'}
        onClose={() => setAdvancedFormattingDrawer(false)}
        isOpen={advancedFormattingDrawer}>
        <AdvancedFormat />
      </Drawer>
      <Drawer
        className="rightSideDrawe"
        onClose={() => setAnimateDrawer(false)}
        isOpen={animateDrawer}
        title={selectedElement.type === TEXT ? 'Add Object Animation' : 'Add Text Animation'}>
        <ElementAnimation />
      </Drawer>
    </div>
  );
  // }
};


export default CanvasFrame;
