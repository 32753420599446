import { EuiIcon } from '@elastic/eui';
import { Button, Carousel } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import dummyImage from '../../assets/images/b1.png';

const ArrowWrapper = ({ className, style, direction = 'right', currentSlide, slideCount, themeViewModal, ...rest }) => {
  return (
    <Button
      {...rest}
      type="default"
      className={`carousel-nav-default ${direction} ${
                themeViewModal
                    ? 'theme-view-carousel'
                    : `circle btn-outline-primary ${currentSlide === 0 ? 'slick-disabled' : ''}`
      }`}
      style={style}
      aria-hidden="true"
      aria-disabled={currentSlide === 0 ? true : false}
      icon={
        <EuiIcon
          style={{ margin: 10, width: 32, height: 32 }}
          type={direction === 'right' ? 'arrowRight' : 'arrowLeft'}
        />
      }></Button>
  );
};

export const CarouselSection = ({ images, themeViewModal = false }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1 = useRef(null);
  const slider2 = useRef(null);

  useEffect(() => {
    setNav1(slider1.current);
    setNav2(slider2.current);
  }, []);

  return (
    <>
      <Carousel
        asNavFor={nav2}
        ref={slider1}
        dots={false}
        swipeToSlide={true}
        className={'primary-carousel'}
        focusOnSelect={true}
        arrows={themeViewModal}
        nextArrow={<ArrowWrapper themeViewModal />}
        prevArrow={<ArrowWrapper direction="left" themeViewModal />}>
        {images.map((k, i) => {
          return (
            <div className="d-flex justify-content-center" key={k.id}>
              <img
                src={dummyImage}
                alt={`image_carousel_${k.id}`}
                className="image-fit"
                style={!themeViewModal ? { width: '90%', height: '350px' } : {}}
              />
            </div>
          );
        })}
      </Carousel>

      <div style={{ padding: '0 36px 36px', marginTop: 24 }}>
        <Carousel
          asNavFor={nav1}
          ref={slider2}
          slidesToShow={themeViewModal ? 5 : 4}
          swipeToSlide={true}
          focusOnSelect={true}
          arrows={themeViewModal ? false : true}
          className={'secondary-carousel'}
          dots={false}
          nextArrow={<ArrowWrapper />}
          prevArrow={<ArrowWrapper direction="left" />}>
          {images.map((k, i) => {
            return (
              <div className="d-flex justify-content-center carousel-thumbnail" key={i}>
                <img src={dummyImage} alt={`image_carousel_${k.id}`} className="image-fit br-sm" />
              </div>
            );
          })}
        </Carousel>
      </div>
    </>
  );
};
