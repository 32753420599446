import React, { useState } from 'react';
import styles from './styles.module.scss';
import { SketchPicker } from 'react-color';
import { Col, Row } from 'antd';

const AddSchoolTheme = ({ values, setValues, content, isClicked, clickHandler, children, setMediamodel }) => {
  const [pickColor, setPickColor] = useState('#fff');
  return (
    <>
      {content.id === 'logo' && (
        <div className={styles.add_school_theme_logo_root}>
          {values[content.id].length !== 0 && values[content.id].map((colors, id) => (
            <div className={styles.add_school_theme_logo_card} key={id}>
              <span className={styles.add_school_theme_logo_circle}></span>
              <span className={styles.add_school_theme_logo_hexcode}>{colors}</span>
            </div>
          ))}
          {isClicked[content.id] ? (
                        <SketchPicker width="235px" color={pickColor} onChangeComplete={(e) => {
                          console.log('PickColor', e);
                          setPickColor(e.hex);
                          // clickHandler(content.id)
                        }} />
                    ) : (
                        <Row>
                          <Col>Add colour</Col>
                          <Col>{children}</Col>
                        </Row>
                    )}
        </div>
      )}
      {content.id === 'color' && (
        <div className={styles.add_school_theme_color_root}>
          {values[content.id].length !== 0 && values[content.id].map((colors, id) => (
            <div className={styles.add_school_theme_color_card} key={id}>
              <span className={styles.add_school_theme.color_circle}></span>
              <span className={styles.add_school_theme.color_hexcode}>{colors}</span>
            </div>
          ))}
          {isClicked[content.id] ? (
                        <SketchPicker width="235px" color={pickColor} onChangeComplete={(e) => {
                          console.log('PickColor', e);
                          setPickColor(e.hex);
                          // clickHandler(content.id)
                        }} />
                    ) : (
                        <Row>
                          <Col>Add colour</Col>
                          <Col>{children}</Col>
                        </Row>
                    )}
        </div>
      )}
      {content.id === 'font' && (
        <div className={styles.add_school_theme_font_root}>
          {values[content.id].length !== 0 && values[content.id].map((colors, id) => (
            <div className={styles.add_school_theme_font_card} key={id}>
              <span className={styles.add_school_theme.font_circle}></span>
              <span className={styles.add_school_theme.font_hexcode}>{colors}</span>
            </div>
          ))}
          {isClicked[content.id] ? (<SketchPicker />) : (
                        <Row>
                          <Col>Add font</Col>
                          <Col>{children}</Col>
                        </Row>
                    )}
        </div>
      )}
    </>
  );
};

export default AddSchoolTheme;
