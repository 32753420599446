import React, { useState, useRef } from 'react';
import appConfig from '../../config';
import { Card } from './slidesCard';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Button, InputGroup, Icon, Spinner } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { slideIndexUpdated, slideadded } from '../../redux/studio/actions';
import { Dialog } from '@blueprintjs/core';
import { Select, Carousel } from 'antd';
import { AddslideIcon } from '../../ProjectSVG';
import '@blueprintjs/core/lib/css/blueprint.css';
import { GreaterArrow } from '../../components/Icon/icons';
import _ from 'lodash';
import SlidePreview from './SlidePreview';
import { createAISlide, getSlideTemplate, newMediaUpload } from '../../helpers/apiCalls';
const { Option } = Select;

const Slides = () => {
  const dispatch = useDispatch();
  const { slides, project } = useSelector((state) => state.Studio.present);
  let Project = project;
  let data = project.slides;
  const [presetSlides, setPresetSlides] = useState(appConfig.project.slides);
  const [showPopup, setShowPopup] = useState(false);
  const [showAIPopup, setShowAIPopup] = useState(false);
  const [cards, setCards] = useState(data);
  const [slideData, setSlideData] = useState({
    slides: [
      {
        reference_Image_for_Slide: '',
        instructions: '',
      },
    ],
  });
  const [instructionFields, setInstructionFields] = useState([{ id: Date.now(), value: '' }]);
  const [loading, setLoading] = useState(false);
  const slider = useRef(null);


  React.useEffect(() => {
    setCards(data);
    // getSlideTemplate().then((res) => {
    //   console.log('response', res);
    // });
  }, [Project.slides, slides]);

  const togglePopup = () => setShowPopup(!showPopup);
  const toggleAIPopup = () => setShowAIPopup(!showAIPopup);

  const moveCard = (dragIndex, hoverIndex) => {
    // setCards((prevCards) =>
    //     update(prevCards, {
    //         $splice: [
    //             [dragIndex, 1],
    //             [hoverIndex, 0, prevCards[dragIndex]],
    //         ],
    //     })
    // );
    let updatedCards = _.cloneDeep(cards); // Make a deep copy of the original array
    let hoverItem = cards[hoverIndex];
    let dragItem = cards[dragIndex];
    updatedCards.splice(hoverIndex, 1, dragItem);
    updatedCards.splice(dragIndex, 1, hoverItem);
    setCards(updatedCards);
    dispatch(slideIndexUpdated(updatedCards));
  };
  const handleSubmit = (slide) => {
    if (!slide?.id) {
      return null;
    }
    // console.log("slideId", slide?.id)
    dispatch(slideadded(slide?.id));
    // setCards(Project.slides);
    togglePopup();
  };

  const breakpoints = [
    { width: 1, itemsToShow: 1 },
    { width: 50, itemsToShow: 5 },
    { width: 765, itemsToShow: 5 },
    { width: 1200, itemsToShow: 8 },
    { width: 1300, itemsToShow: 10 },
    { width: 1400, itemsToShow: 12 },
    { width: 1500, itemsToShow: 14 },
    { width: 1600, itemsToShow: 16 },
    { width: 1700, itemsToShow: 18 },
  ];
  const searchSlides = (event) => {
    const query = event.target.value;
    if (query) {
      setPresetSlides(
        appConfig.project.slides.filter(
          ({ Meta }) =>
            Meta?.Name?.toLocaleLowerCase().includes(query?.toLocaleLowerCase()) ||
            Meta?.Keywords?.toLocaleLowerCase().includes(query?.toLocaleLowerCase())
        )
      );
    } else {
      setPresetSlides(appConfig.project.slides);
    }
  };

  const sortChildren = [];
  const sortOptions = ['Date'];
  for (let i = 0; i < sortOptions.length; i++) {
    sortChildren.push(<Option key={sortOptions[i]}>{sortOptions[i]}</Option>);
  }

  {
    /* Helper function to calculate the number of slides to show based on the viewport width */
  }

  const getSlidesToShowCount = () => {
    const viewportWidth = window.innerWidth;
    // console.log("getSlides")
    if (viewportWidth >= 1600) {
      return 12;
    } else if (viewportWidth >= 1400) {
      return 10;
    } else if (viewportWidth >= 1200) {
      return 8;
    } else if (viewportWidth >= 765) {
      return 6;
    } else {
      return 4;
    }
  };

  const handleAIGeneration = () => {
    setShowAIPopup(true);
    setShowPopup(false);
  };

  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    if (name === 'reference_Image_for_Slide') {
      setSlideData((prevData) => ({
        slides: [
          {
            ...prevData.slides[0],
            [name]: value,
          },
        ],
      }));
    } else {
      const newInstructionFields = instructionFields.map((field, idx) => (idx === index ? { ...field, value } : field));
      setInstructionFields(newInstructionFields);

      const instructions = newInstructionFields.map((field) => field.value).join(', ');
      setSlideData((prevData) => ({
        slides: [
          {
            ...prevData.slides[0],
            instructions,
          },
        ],
      }));
    }
  };

  const handleFileUpload = async (e, field, index) => {
    const file = e.target.files[0];
    if (file) {
      const formData = new FormData();
      formData.append('files', file);

      try {
        const response = await newMediaUpload(formData);
        const fileName = response[0]?.url;

        if (field === 'instruction') {
          setInstructionFields((prevFields) => {
            const updatedFields = prevFields.map((field, idx) =>
              idx === index ? { ...field, value: `${field.value} https://apilw.html5.run${fileName}` } : field
            );

            const instructions = updatedFields.map((field) => field.value).join(', ');

            setSlideData((prevData) => ({
              slides: [
                {
                  ...prevData.slides[0],
                  instructions,
                },
              ],
            }));

            return updatedFields;
          });
        } else {
          setSlideData((prevData) => ({
            slides: [
              {
                ...prevData.slides[0],
                [field]: `https://apilw.html5.run${fileName}`,
              },
            ],
          }));
        }
      } catch (error) {
        console.error('Error uploading file:', error);
      }
    }
  };

  const addInstructionField = () => {
    setInstructionFields([...instructionFields, { id: Date.now(), value: '' }]);
  };

  const handleGenerateSlide = async () => {
    setLoading(true);
    const data = {
      json: {
        slides: [
          {
            reference_Image_for_Slide: slideData.slides[0].reference_Image_for_Slide,
            instructions: slideData.slides[0].instructions,
          },
        ],
      },
    };

    try {
      const response = await createAISlide(data);
      console.log('Slide generated:', response.data);
      dispatch(slideadded(response.data?.list[0]));
      setLoading(false);
      setShowAIPopup(false);
      setSlideData({
        slides: [
          {
            reference_Image_for_Slide: '',
            instructions: '',
          },
        ],
      });
      setInstructionFields([{ id: Date.now(), value: '' }]);
    } catch (error) {
      console.error('Error generating slide:', error);
      setLoading(false);
    }
  };

  return (
    <div className="d-flex align-items-center">
      <div className="slider-arrow slider-arrow-left" onClick={() => slider.current.prev()}>
        <GreaterArrow width="1.5em" height="2em" />
      </div>
      <div style={{ width: '80%' }}>
        <DndProvider backend={HTML5Backend}>
          <Carousel
            ref={slider}
            slidesToShow={getSlidesToShowCount()}
            dots={false}
            dotPosition="bottom"
            slidesToScroll={1}
            infinite={false}
            itemClass="carousel-item"
            responsive={breakpoints}
            key={cards.length}>
            {/* {console.log("cards", cards)} */}
            {cards
              .filter((item) => item)
              .map((card, index) => (
                <div className="carousel-item" key={index} style={{ width: '110px', marginRight: '10px' }}>
                  <Card
                    key={index}
                    index={index}
                    card={card}
                    text={index + 1}
                    style={{ width: '110px' }}
                    moveCard={moveCard}
                  />
                </div>
              ))}
          </Carousel>
        </DndProvider>

        {showPopup && (
          <Dialog isOpen={true} onClose={togglePopup} style={{ height: '65vh', width: '80%', maxWidth: 700 }}>
            <div
              style={{
                padding: 24,
                display: 'flex',
                position: 'relative',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '2.2px dashed lightgray',
              }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '300px' }}>
                <span style={{ fontSize: 24, fontWeight: 'bolder', color: 'black' }}>Insert Slide</span>
                <Button icon={<Icon icon="lightbulb" />} onClick={() => handleAIGeneration()}>
                  Create with AI
                </Button>
              </div>

              <InputGroup
                className="tabsSearchButton"
                placeholder="Search Slide by Keywords"
                type="search"
                leftIcon="search"
                asyncControl={true}
                onChange={(event) => searchSlides(event)}
              />
              <Button
                icon="small-cross"
                onClick={togglePopup}
                style={{
                  width: 24,
                  height: 30,
                  borderRadius: '50%',
                  position: 'absolute',
                  top: -12,
                  right: -12,
                  backgroundColor: 'white',
                }}
              />
            </div>
            <div
              style={{
                display: 'grid',
                padding: 24,
                gridTemplateColumns: 'repeat(auto-fill, minmax(125px, 1fr))',
                gap: 24,
                overflowY: 'auto',
                margin: '12px 0',
              }}>
              {presetSlides.map((slide) => {
                if (slide.children.length === 0) {
                  return (
                    <div
                      key={slide.id}
                      className="d-flex flex-column align-items-center gap-2"
                      style={{ width: '100%' }}>
                      <SlidePreview slide={slide} handleSubmit={handleSubmit} />
                      <span style={{ fontSize: 14, fontWeight: 500 }}>{slide?.Meta?.Name}</span>
                    </div>
                  );
                } else {
                  return (
                    <div
                      key={slide.id}
                      className="d-flex flex-column align-items-center gap-2"
                      style={{ width: '100%' }}>
                      {/* <SlidePreview slide={slide} handleSubmit={handleSubmit} /> */}
                      <div
                        style={{
                          aspectRatio: 16 / 9,
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          backgroundColor: '#fff',
                          cursor: 'pointer',
                        }}
                        onClick={() => handleSubmit(slide)}>
                        {slide?.Meta?.Name}
                      </div>
                      <span style={{ fontSize: 14, fontWeight: 500 }}>{slide?.Meta?.Name}</span>
                    </div>
                  );
                }
              })}
            </div>
          </Dialog>
        )}
        {showAIPopup && (
          <Dialog isOpen={showAIPopup} onClose={toggleAIPopup} style={{ height: 'auto' }}>
            <div
              style={{
                padding: 24,
                display: 'flex',
                position: 'relative',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '2.2px dashed lightgray',
              }}>
              <span style={{ fontSize: 24, fontWeight: 'bolder', color: 'black' }}>Generate AI Slide</span>
              <Button
                icon="small-cross"
                onClick={toggleAIPopup}
                style={{
                  width: 24,
                  height: 30,
                  borderRadius: '50%',
                  position: 'absolute',
                  top: -12,
                  right: -12,
                  backgroundColor: 'white',
                }}
              />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                justifyContent: 'start',
                padding: '24px',
              }}>
              <label>
                Upload Reference Image for Slide:
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, 'reference_Image_for_Slide')}
                  style={{ margin: '8px 0', padding: '8px', width: '452px' }}
                />
              </label>
              <label>
                Reference Image File Name:
                <input
                  type="text"
                  name="reference_Image_for_Slide"
                  value={slideData.slides[0].reference_Image_for_Slide}
                  onChange={(e) => handleInputChange(e)}
                  style={{ margin: '8px 0', padding: '8px', width: '452px' }}
                  readOnly
                />
              </label>
              {instructionFields.map((field, index) => (
                <div key={field.id} style={{ marginBottom: '16px' }}>
                  <label>
                    Instruction:
                    <textarea
                      name={`instruction_${index}`}
                      value={field.value}
                      onChange={(e) => handleInputChange(e, index)}
                      style={{ margin: '8px 0', padding: '8px', width: '452px' }}
                    />
                  </label>
                  <label>
                    Upload File for Instruction:
                    <input
                      type="file"
                      accept="image/*,video/*"
                      onChange={(e) => handleFileUpload(e, `instruction`, index)}
                      style={{ margin: '8px 0', padding: '8px', width: '452px' }}
                    />
                  </label>
                </div>
              ))}
              <Button onClick={addInstructionField} style={{ marginBottom: '16px' }}>
                Add Instruction
              </Button>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'end', width: '100%' }}>
                <Button onClick={() => handleGenerateSlide()}>
                  {loading ? (
                    <>
                      <Spinner size={16} /> Generating
                    </>
                  ) : (
                    'Generate Slide'
                  )}
                </Button>
              </div>
            </div>
          </Dialog>
        )}
      </div>
      <div className="slider-arrow slider-arrow-right" onClick={() => slider.current.next()}>
        <GreaterArrow width="1.5em" height="2em" />
      </div>
      <div
        style={{
          marginTop: '10px',
          justifyContent: 'end',
          textAlign: 'center',
          display: 'flex',
          marginLeft: 12,
        }}>
        <Button className="addSlideButton" onClick={togglePopup} style={{ textAlign: 'center' }}>
          <div style={{ marginTop: '7px' }}>
            <AddslideIcon />
            <p style={{ color: '#8D8D8D', fontWeight: '600' }}>Add New Slide</p>
          </div>
        </Button>
      </div>
    </div>
  );
};
export default Slides;
