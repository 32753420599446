import React from 'react';
import { Input } from 'antd';

const FormInput = ({ label, value, onChange, className = '' }) => {
  return (
    <div className={`form-input ${className}`}>
      {label ? (
        <label className={'labels'}>{label}</label>
      ) : null}

      <Input
        className={'input'}
        value={value}
        onChange={({ target }) =>
          onChange(target.value[0] === ' ' ? target.value.substring(1) : target.value.replace(/  +/g, ' '))
        }
      />
    </div>
  );
};

export default FormInput;
