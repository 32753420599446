import React, { useState } from 'react';
import { Button, Classes, Position, Tooltip } from '@blueprintjs/core';
import AddButton from './AddButton';
import { ButtonIcon } from '../../../ProjectSVG';
import { Row, Col } from 'antd';
import { elementadd } from '../../../redux/studio/actions';
import { useDispatch } from 'react-redux';
import { Drawer } from '../../../ui/Drawer/Drawer';

export const AddButtonDrawer = () => {

  const [buttonData, setButtonData] = useState({
    buttonURL: '',
    buttonId: '',
    dimension: {},
    attributes: {}
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(() => false);
  const dispatch = useDispatch();
  const buttonSelected = (id, url, dimension, attributes) => {
    setButtonData({
      buttonURL: url,
      buttonId: id,
      dimension,
      attributes
    });
  };
  const addButtonToCanvas = () => {
    if (buttonData.buttonId && buttonData.buttonURL) {
      dispatch(elementadd({ value: buttonData.buttonURL, type: 'button', dimension: buttonData.dimension, attributes: buttonData.attributes }));
      setIsDrawerOpen(false);
    }
  };
  return (
    <div>
      <Tooltip content="Add Buttons" position={Position.RIGHT_TOP}>
        <Button onClick={() => setIsDrawerOpen(true)} className="drawerbuttons" minimal={true}>
          <ButtonIcon />
        </Button>
      </Tooltip>
      <Drawer
        className="drawer add-button"
        position={Position.LEFT}
        onClose={() => setIsDrawerOpen(false)}
        isOpen={isDrawerOpen}
        title="Buttons"
      >
        <div className={Classes.DRAWER_BODY} onClick={() => setButtonData({ buttonURL: '', buttonId: '' })}>

          <AddButton buttonSelected={buttonSelected} id={buttonData.buttonId} />
        </div>
        <div className={Classes.DRAWER_FOOTER} style={{ overflowX: 'hidden' }}>
          <Row style={{ display: 'flex', justifyContent: 'right' }}>
            <Col>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <p>{buttonData.buttonId ? 1 : 0} Selected</p>{' '}
                <Button className="drawerinsertButton" onClick={addButtonToCanvas}>
                  Insert
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Drawer>
    </div>
  );
};

