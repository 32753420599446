import React from 'react';

export const CircleSolid = ({
  width = '24',
  height = '24',
  className = '',
  style = {}
}) => {
  return (
    <svg className={className}
      style={style}
      width={width}
      height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15 15"><path fill="#000" d="M7.5 0a7.5 7.5 0 1 0 0 15a7.5 7.5 0 0 0 0-15" /></svg>
    // <svg
    //   className={className}
    //   style={style}
    //   width={width}
    //   height={height}
    //   clipRule="evenodd"
    //   fillRule="evenodd"
    //   strokeLinejoin="round"
    //   strokeMiterlimit="2"
    //   viewBox="0 0 24 24"
    //   xmlns="http://www.w3.org/2000/svg">
    //   <circle cx="11.998" cy="11.998" fillRule="nonzero" r="9.998" />
    // </svg>
  );
};
