import React from 'react';
import { Pagination as PaginationAnt } from 'antd';

export const Pagination = ({
  ...rest
}) => {
  return (
    <PaginationAnt
      {...rest}
    />
  );
};
