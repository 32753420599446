import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import appConfig from '../config';
import _ from 'lodash';

const usePaginatedApi = (initialUrl, initialPageSize) => {
  const [data, setData] = useState([]);
  const [start, setStart] = useState(0);
  const [limit, setLimit] = useState(initialPageSize);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [search, setSearch] = useState('');
  const [params, setParams] = useState({});


  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    try {

      let response = await axios.get(appConfig[initialUrl], {
        params: {
          sort: 'updatedAt:DESC',
          start,
          limit,
          filters: {
            $and: [
              {},
              {
                name: {
                  $contains: search
                }
              },
              params
            ]
          }
        },
      });
      // console.log('responseessss', response.data);
      if (response) {
        setData(prevData => [...prevData, ...response.data.data]);
      }
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [initialUrl, start, limit, search, params]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const loadMore = () => {
    setStart(prevPage => prevPage + initialPageSize);
  };

  const searchItems = useCallback(
    _.debounce((query) => {
      setData([]); // Clear current data
      setStart(1); // Reset to the first page
      setSearch(query);
    }, 500), // 500ms debounce delay
    []
  );

  const searchParams = useCallback(
    _.debounce((query, key) => {
      setData([]); // Clear current data
      setStart(1); // Reset to the first page
      setParams({
        [key]: {
          $eq: query
        }
      });
    }, 500), // 500ms debounce delay
    []
  );

  const refreshData = useCallback(
    _.debounce(() => {
      setData([]); // Clear current data
      setStart(1); // Reset to the first page
      fetchData();
    }, 1000), // 500ms debounce delay
    []
  );

  return { data, loading, error, loadMore, searchItems, searchParams, refreshData };
};

export default usePaginatedApi;
