import React from 'react';
import FormInput from '../../IndividualComponent/FormInput';

const Input = ({ item, _, setThemeQuestionBySlide, themeQuestionBySlide }) => {
  return (
    <div className="question-container" key={_}>
      <div className="question">{item?.question}</div>
      <div className="options">
        <div className="option">
          <FormInput
            value={item?.content?.text}
            onChange={(answer) => {
              const quesDetail = {
                ...item,
                content: { text: answer },
              };
              let elements =
                                themeQuestionBySlide?.elements;
              elements[_] = quesDetail;
              setThemeQuestionBySlide({
                ...themeQuestionBySlide,
                elements,
              });
            }}
            className={'question-input'}
          />
        </div>
      </div>
    </div>
  );
};

export default Input;
