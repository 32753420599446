import React from 'react';
import {Col, Row} from 'reactstrap';
import {Link} from 'react-router-dom';

export const AccountAction = ({userName = '', userEmail = '', image = '', expiry = ''}) => {
  return (
    <div className="accountDetail">
      <Row>
        <Col xs={4}>
          <img alt="img" className="userProfile" src={image}/>
        </Col>
        <Col xs={20}>
          <p className="userProfileName">{userName}</p>
          <p className="userProfileRole ">{userEmail}</p>
        </Col>
      </Row>
      <hr/>
      <Row>
        <Col>
          <p style={{lineHeight: '0px', fontSize: '14px'}}>Your Subscription</p>
          <p className="userProfileExpiry">{expiry}</p>
        </Col>
      </Row>
      <Row style={{marginTop: '4px'}}>
        <Col className="row21">
          <p>Account Settings</p>
        </Col>
      </Row>
      <Row>
        <Col className="row21">
          <p>Get Help</p>
        </Col>
      </Row>
      <Row>
        <Col className="row21">
          <p>Privacy Policy</p>
        </Col>
      </Row>
      <Row>
        <Col className="row21">
          <Link to="/account/logout">
            <p className="text-dark fw-2">Logout</p>
          </Link>
        </Col>
      </Row>
    </div>
  );
};
