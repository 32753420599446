import React, { useEffect, useState } from 'react';
import appConfig from '../../config';
import http from '../../services/http.service';
import { ListContainer } from '../ListContainer';
import { createQuery, getImageFallback } from '../../utils';
import { LessonCard } from '../LessonCard';
import PopOverContent from '../PopOverContent';
import { useSelector } from 'react-redux';

const selectData = [
  {
    value: 'popularity',
    label: 'Popularity',
  },
];


const EditableList = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [filter, setAppliedFilter] = useState({
    fitlers: {
      $and: [],
    },
  });
  const [pagination, setPagination] = useState({
    start: 0,
    limit: 10
  });
  const { user } = useSelector(state => state.Auth);


  useEffect(() => {
    filterProjects(filter, pagination);
    return () => setData([]);
  }, [pagination, filter]);

  const filterProjects = async (filter, pagination) => {
    setLoading(true);
    const { start, limit } = pagination;
    const result = await http.get(appConfig.collection + '/projects?_sort=createdAt&' + createQuery({
      filters: {
        $and: [
          {
            username: {
              $eq: user?.user?.username
            }
          }
        ]
      }
    }, limit, start));
    setData(result.data);
    setLoading(false);
  };


  const loadMoreHandle = async () => {
    setPagination((prev) => ({
      ...prev,
      start: prev.start + 10
    }));
  };


  async function searchFilterHandler(e) {
    setAppliedFilter(prev => ({
      ...prev,
      filters: {
        $and: [
          {
            title: {
              $contains: e.target.value
            }
          }
        ]
      }
    }));
  }

  const imageUrlFallback = (data) => {
    if (!data) {
      return getImageFallback();
    }
    return appConfig.socketURL + data[0]?.attributes.url.replace('/', '');
  };

  const handleCopy = async (item) => {
    try {
      setLoading(true);
      let payload = {
        project: item.attributes.project,
        title: item.attributes.title,
        description: item.attributes.description,
        username: user?.user?.username
      };
      const result = await http.post(appConfig.collection + '/projects', { data: payload });
      await filterProjects(filter, pagination);
    } catch (error) {
      console.log('error', error);
    }
  };

  return (
    <ListContainer
      subText="Choose from hundreds of templates designed specifically for children or build your own!"
      selectProps={{
        options: selectData,
        onChange: () => console.log('on change'),
        defaultValue: 'popularity',
      }}
      loading={loading}
      loadMoreHandle={loadMoreHandle} searchFilterHandler={searchFilterHandler}>
      {!loading && (
        <>
          {/* <CreateNewCard /> */}
          {data?.map((item) => (
            <LessonCard
              key={item.id}
              popoverTitle={item.attributes.title}
              id={item.id}
              imageUrl={imageUrlFallback(item.attributes.thumbnail.data)}
              noOfSlides={item.attributes.project?.slides?.length}
              title={item.attributes.title}
              popoverComponent={
                <PopOverContent
                  title={item.attributes.title}
                  id={item.id}
                  handleCopy={(e) => handleCopy(item)}
                  type={'lesson'}
                />
              }
            />
          ))}
        </>
      )}
    </ListContainer>
  );
};


export default EditableList;
