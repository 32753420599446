import questionImage from '../assets/images/question.png';

export const getImageQuestion = () => questionImage;

export const isThemeOrTemplate = () => window.location.pathname.includes('theme') || window.location.search.split('=')[1] === 'template';

export const STUDIO_ELEMENTS = {
  CANVAS_FRAME_EL: '#canvasFrame',
  CANVAS_EL: '#lcanvas'
};
