import React, { useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { ItemTypes } from './ItemTypes';
import { useDispatch} from 'react-redux';
import { elementDeleted, elementCopied,  elementUpdated } from '../../redux/studio/actions';
import { Icon } from '@blueprintjs/core';

const style = {
  cursor: 'move',
  overFlow: 'hidden',
};

export const Card = ({ id, text, index, moveCard, icon, elementId, disable }) => {
  const dispatch = useDispatch();
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }

      // if (monitor.isOver({ shallow: true })) {
      //     return;
      // }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      // Determine mouse position
      const clientOffset = monitor.getClientOffset();
      // Get pixels to the top
      const hoverClientY = clientOffset.y - hoverBoundingRect.top;
      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%
      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }
      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.CARD,
    item: () => {
      return { id, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  drag(drop(ref));
  const [visibilityoff, setVisibility] = useState(false);
  // const [eye, setEye] = useState('eye-open');
  // const changeEye = (state) => {
  //     if (state === 'eye-open') {
  //         return 'eye-off';
  //     }
  //     return 'eye-open';
  // };
  const [lock, setLock] = useState(disable ? 'lock' : 'unlock');
  const changeLock = (state) => {
    if (state === 'unlock') {
      dispatch(elementUpdated({ id: elementId }, true, 'disable'));
      return 'lock';
    }
    dispatch(elementUpdated({ id: elementId }, false, 'disable'));

    return 'unlock';
  };
  const deleteElement = () => {
    dispatch(elementDeleted(elementId));
  };
  const copyElement = () => {
    dispatch(elementCopied(elementId));
  };
  return (
    <div
      className="ElementCard"
      hidden={visibilityoff}
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        borderRadius: '4px',
      }}
      ref={ref}
      data-handler-id={handlerId}>
      <span
        dangerouslySetInnerHTML={{
          __html: icon,
        }}></span>
      <div style={{ ...style }}>{text}</div>
      <span>
        <button className="elementbuttons" onClick={() => setLock((old) => changeLock(old))} icon={lock}>
          <Icon icon={lock} />
        </button>
        <button className="elementbuttons" onClick={copyElement}>
          <Icon icon={'duplicate'} />
        </button>
        <button className="elementbuttons">
          <Icon icon="trash" onClick={deleteElement} />
        </button>
      </span>
    </div>
  );
};
