import React from 'react';
import {Form, Input, Row} from 'antd';
import {BackArrow} from '../../ProjectSVG';
import {getLoggedInUser} from '../../helpers/authUtils';

const WizardsHome = ({nextStep, loading, state, setBasicState, onCancel}) => {
  const onFinish = (val) => {
    const loggedInUser = getLoggedInUser();
    const username = loggedInUser.user.username;
    let data = {title: val.title, username};
    setBasicState(data);
    nextStep();
  };
  if (!state.id) {
    return null;
  }

  return (
    <div className="w01">
      <div className="Wcolumn">
        <div onClick={onCancel} className="backLink cursor-pointer">
          <BackArrow/> Back
        </div>
        <Form
          name="basic"
          onFinish={onFinish}
          style={{display: 'flex', height: 'calc(100% - 64px)', overflowY: 'auto', padding: 12}}
          autoComplete="off">
          <div className="w123">
            <span>
                            Hi, <b style={{color: '#6362B0'}}>welcome to the Superteach wizard.</b>
            </span>
            <span>
                            Our handy tool helps you build an interactive lesson in a matter of minutes. Simply answer
                            the questions on the next screens.
            </span>
            <span>Dont worry, you can skip a question and jump into the editor at any time.</span>

            <Row className="d-flex flex-column gap-4 justify-content-center">
              <span className="WQ2">Title of Project</span>

              <Form.Item name="title">
                {console.log('state', state)}
                <Input
                  defaultValue={state?.attributes?.title}
                  className="wizardsInputs"
                  placeholder="add title"
                />
              </Form.Item>
            </Row>

            <button
              type="submit"
              className="w2 nextButton"
              style={{display: 'flex'}}
              disabled={loading}>
              {loading && (
                <div className="spinner-border spinner-border-sm text-light mx-1" role="status">
                  <span className="visually-hidden"></span>
                </div>
              )}
              Get started
            </button>
          </div>
        </Form>
      </div>
    </div>
  );
};

export default WizardsHome;
