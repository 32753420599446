import React, { useState } from 'react';
import { Button, Position, Classes } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
// import { DeleteSvg, EditIcon, ProjSvg } from '../../src/ProjectSVG';
import { Link, useNavigate } from 'react-router-dom';
import { Popover } from 'antd';
import editIcon from '../../src/assets/images/edit_icon.svg';
import projSvg from '../../src/assets/images/copy_icon.svg';
import deleteSvg from '../../src/assets/images/delete_icon.svg';
import publicSvg from '../../src/assets/images/publish_icon.svg';

const PopOverContent = ({ title = '', id = '', handleCopy = () => { }, handleDelete = () => { }, type = 'template' }) => {
  const history = useNavigate();
  const [open, setOpen] = useState(false);

  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };

  return (
    <Popover
      arrow={false}
      placement="bottom"
      trigger="click"
      open={open}
      onOpenChange={handleOpenChange}
      content={
        <div className="card_pop" style={type === 'template' ? { height: '171px' } : { height: '87px' }}>
          <div className="popoverSubtitle">
            <img src={editIcon} />
            <button
              className={Classes.POPOVER_DISMISS}
              style={{
                border: 'white',
                backgroundColor: 'white',
                fontFamily: 'Lexend',
                fontStyle: 'normal',
                fontWeight: 300,
                fontSize: '14px',
              }}
              // className="btn_pop"
              onClick={() => {
                // Update the route based on the type
                const route = type === 'template'
                  ? `/studio/${id}?type=template`
                  : `/studio/${id}`;

                // Navigate to the updated route
                history(route);
              }}>
              Continue Editing
            </button>
          </div>
          {type === 'template' && (
            <div className="popoverSubtitle">
              <img src={publicSvg} />
              <button
                className={Classes.POPOVER_DISMISS}
                style={{
                  border: 'white',
                  backgroundColor: 'white',
                  fontFamily: 'Lexend',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  fontSize: '14px',
                }}
              // className="btn_pop"
              >
                Publish this Creation
              </button>
            </div>
          )}
          <div className="popoverSubtitle">
            <img src={projSvg} />

            <button
              className={Classes.POPOVER_DISMISS}
              style={{
                border: 'white',
                backgroundColor: 'white',
                fontFamily: 'Lexend',
                fontStyle: 'normal',
                fontWeight: 300,
                fontSize: '14px',
              }}
              onClick={(e) => {
                handleCopy(e);
                setOpen(false);
              }}>
              Make a Copy
            </button>
          </div>

          {type === 'template' && (
            <div className="popoverSubtitle">
              <img src={deleteSvg} />

              <button
                className={Classes.POPOVER_DISMISS}
                style={{
                  border: 'white',
                  backgroundColor: 'white',
                  fontFamily: 'Lexend',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  fontSize: '14px',
                }}
                onClick={(e) => {
                  handleDelete(e);
                  setOpen(false);
                }}>
                Delete this Creation
              </button>
            </div>
          )}


        </div>
      }>
      <Button icon="more" className="projectcardMore"></Button>
    </Popover>
  );
};
export default PopOverContent;
