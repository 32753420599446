import React, { useState } from 'react';
import { Button, Classes, Position, Tooltip } from '@blueprintjs/core';
import AddBackground from './AddBackground';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer } from '../../../ui/Drawer/Drawer';
import { slideimageUpdated } from '../../../redux/actions';

export const AddBackgroundDrawerRight = () => {
  const [buttonData, setButtonData] = useState({
    buttonId: '',
    buttonURL: '',
    dimension: {},
  });
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const { project, selectedSlide } = useSelector((state) => state.Studio.present);
  const dispatch = useDispatch();

  const buttonSelected = (id, url, dimension) => {
    setButtonData({
      buttonId: id,
      buttonURL: url,
      dimension,
    });
  };

  const addButtonToCanvas = () => {
    if (buttonData.buttonId && buttonData.buttonURL) {
      console.log('ButtonData', buttonData);
      dispatch(
        slideimageUpdated({
          image: buttonData.buttonURL,
          type: 'background',
          dimension: buttonData.dimension,
        })
      );
      setIsDrawerOpen(false);
    }
  };
  return (
    <div>
      <Tooltip position={Position.RIGHT_TOP}>
        <Button onClick={() => setIsDrawerOpen(true)}>Add Image</Button>
      </Tooltip>
      <Drawer
        className="rightSideDrawe"
        onClose={() => setIsDrawerOpen(false)}
        isOpen={isDrawerOpen}
        title={<b style={{ fontSize: '17px' }}>Background </b>}>
        <div className={Classes.DRAWER_BODY} onClick={() => setButtonData({ buttonURL: '', buttonId: '' })}>
          <AddBackground buttonSelected={buttonSelected} id={buttonData.buttonId} />
        </div>
        <div className={Classes.DRAWER_FOOTER} style={{ overflowX: 'hidden' }}>
          <Row style={{ display: 'flex', justifyContent: 'right' }}>
            <Col>
              <div style={{ display: 'flex', justifyContent: 'right' }}>
                <p>{buttonData.buttonId ? 1 : 0} Selected</p>{' '}
                <Button className="drawerinsertButton" onClick={addButtonToCanvas}>
                  Insert
                </Button>
              </div>
            </Col>
          </Row>
        </div>
      </Drawer>
    </div>
  );
};
