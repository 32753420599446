import React from 'react';

export const StackHorizontal = ({
  width = '24',
  height = '24',
  className = '',
  style = {}
}) => {
  return (
    <svg
      className={className}
      style={style}
      width={width}
      height={height}
      xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M4 17.162l-2 .838v-12.972l12-5.028v2.507l-10 4.19v10.465zm16-8.156v8.635l-8 3.352v-8.635l8-3.352zm2-3.006l-12 5.028v12.972l12-5.028v-12.972zm-14 3.697l10-4.19v-2.507l-12 5.028v12.972l2-.838v-10.465z" />
    </svg>

  );
};
