// @flow
import {
  ELEMENT_ADD,
  ELEMENT_CHANGED,
  ELEMENT_COPIED,
  ELEMENT_CUT_ADDED,
  ELEMENT_DELETED,
  ELEMENT_INDEX_CHANGED,
  ELEMENT_INDEX_UPDATED,
  ELEMENT_UPDATED,
  IMAGE_UPDATED,
  INDEX_UPDATED,
  PROJECT_INIT,
  PROJECT_UPDATED,
  SELECTION_CHANGED,
  SELECTION_UPDATED,
  SET_HEADERSTYLEINFO,
  SLIDE_ADDED,
  SLIDE_COPIED,
  SLIDE_DELETED,
  SLIDE_IMAGE_UPDATED,
  SLIDE_INDEX_CHANGED,
  SLIDE_INDEX_UPDATED,
  SLIDE_SELECTION_CHANGED,
  SLIDE_SELECTION_UPDATED,
  SLIDE_UPDATED,
} from './constants';
// import {localTracks, remoteTracks} from "../../helpers/studioApi";

export const projectInit = (projectid, projectName) => ({
  type: PROJECT_INIT,
  payload: { projectid, projectName },
});
export const slideIndex = (slides) => ({
  type: INDEX_UPDATED,
  payload: { slides },
});
//
export const projectUpdated = (project, slides, title, elements) => ({
  type: PROJECT_UPDATED,
  payload: { project, slides, title, elements },
});

export const slideSelectionChanged = (selectedSlide) => ({
  type: SLIDE_SELECTION_CHANGED,
  payload: { selectedSlide },
});

export const slideIndexChanged = (slides) => ({
  type: SLIDE_INDEX_CHANGED,
  payload: { slides },
});

export const slideIndexUpdated = (slides) => ({
  type: SLIDE_INDEX_UPDATED,
  payload: { slides },
});

export const elementIndexChanged = (elements) => ({
  type: ELEMENT_INDEX_CHANGED,
  payload: { elements },
});

export const elementIndexUpdated = (elements) => ({
  type: ELEMENT_INDEX_UPDATED,
  payload: { elements },
});

export const slideadded = (index) => ({
  type: SLIDE_ADDED,
  payload: { index },
});

export const slideSelectionUpdated = (slide) => ({
  type: SLIDE_SELECTION_UPDATED,
  payload: { slide },
});

//
// export const roomDispose = (show, room) => ({
//     type: ROOM_DISPOSE,
//     payload: {show, room},
// });
export const elementDeleted = (show) => ({
  type: ELEMENT_DELETED,
  payload: { show },
});
export const elementCopied = (show) => ({
  type: ELEMENT_COPIED,
  payload: { show },
});
export const slideDeleted = (show) => ({
  type: SLIDE_DELETED,
  payload: { show },
});
export const slideCopied = (show) => ({
  type: SLIDE_COPIED,
  payload: { show },
});
export const elementChanged = (show) => ({
  type: ELEMENT_CHANGED,
  payload: { show },
});
export const elementUpdated = (element, value, propName, actives, activeItalic) => ({
  type: ELEMENT_UPDATED,
  payload: { element, value, propName, actives, activeItalic },
});
export const slideUpdated = (element, value, propName) => ({
  type: SLIDE_UPDATED,
  payload: { element, value, propName },
});
export const elementadd = (type, cal) => ({
  type: ELEMENT_ADD,
  payload: { type, cal },
});

export const elementAddCut = (element) => ({
  type: ELEMENT_CUT_ADDED,
  payload: { element }
});
export const imageUpdated = (element) => ({
  type: IMAGE_UPDATED,
  payload: { element },
});
export const slideimageUpdated = (element, image) => ({
  type: SLIDE_IMAGE_UPDATED,
  payload: { element, image },
});
export const selectionChanged = (item, show) => ({
  type: SELECTION_CHANGED,
  payload: { item, show },
});

export const selectionUpdated = (item, show) => ({
  type: SELECTION_UPDATED,
  payload: { item, show },
});
export const headerStyleInfo = (item) => ({
  type: SET_HEADERSTYLEINFO,
  payload: { item },
});

export const undoBehaviour = () => ({
  type: 'UNDO'
});

export const redoBehaviour = () => ({
  type: 'REDO'
});
