
import { Button as AntButton } from 'antd';
import React from 'react';
const Button = ({ children, className, ...rest }) => {

  return <AntButton
    type='primary'
    className={`${className ? className : ''}`}
    {...rest}
  >
    {children}</AntButton>;
};

export default Button;
