import React, { Component } from 'react';
import {
  Button,
  Tooltip,
  Position,
  MenuItem,
  Navbar,
  Menu,
  Dialog,
  Classes,
  ButtonGroup,
  NavbarGroup,
} from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import Detailoverlay from './Detailoverlay';
import AddShapes from './AddShapes';
import { ElementsDrawer } from './LeftSideDrawers/ElementsDrawer';
import { AddButtonDrawer } from './LeftSideDrawers/AddButtonDrawer';
import { AddImageDrawer } from './LeftSideDrawers/AddImageDrawer';
import { AddBackgroundDrawer } from './LeftSideDrawers/AddBackgroundDrawer';
import { AddTextDrawer } from './LeftSideDrawers/AddTextDrawer';
import { AddShapesDrawer } from './LeftSideDrawers/AddShapesDrawer';
import AddVideo from './LeftSideDrawers/AddVideo';
import { AddCustomImageDrawer } from './LeftSideDrawers/AddCustomImageDrawer';
import { EmbedIcon, EmbedtextIcon } from '../../ProjectSVG';
import { AddQuestion } from './LeftSideDrawers/AddQuestion';

const StudioToolbarLeft = (props) => {

  const [showsModel, setShowsModel] = React.useState(false);

  const exampleMenu = (
    <div className="FileDetail">
      <Detailoverlay />
    </div>
  );

  const embedMenu = (
    <div className="FileDetail">
      <div className="embedCloseButton_div">
        <Button
          icon="cross"
          className="embedCloseButtons"
          onClick={() => {
            setShowsModel(false);
          }}></Button>
      </div>
      <AddVideo
        handleClose={() => {
          setShowsModel(false);
        }}
      />
    </div>
  );

  const fontStyling = (
    <Menu>
      <MenuItem icon="graph" text="Style1" />
      <MenuItem icon="map" text="Style2" />
    </Menu>
  );

  return (
    <React.Fragment>
      <Navbar vertical="true" style={{ boxShadow: 'none', height: 'auto', padding: 0 }}>
        <NavbarGroup>
          <ElementsDrawer />
        </NavbarGroup>

        <NavbarGroup style={{ marginTop: '-8px' }}>
          <AddShapesDrawer />
        </NavbarGroup>

        <NavbarGroup style={{ marginTop: '10px' }}>
          <AddTextDrawer />
        </NavbarGroup>

        <NavbarGroup style={{ marginTop: '-2px' }}>
          <AddImageDrawer />
        </NavbarGroup>

        <NavbarGroup>
          <AddCustomImageDrawer />
        </NavbarGroup>

        <NavbarGroup>
          <Popover2
            content={embedMenu}
            placement="right"
            style={{ paddingRight: '0px' }}
            isOpen={showsModel}>
            <Tooltip content="Embed Data" position={Position.RIGHT_TOP}>
              <Button
                onClick={() => {
                  setShowsModel(true);
                }}
                minimal={true}
                className="drawerbuttons">
                <EmbedIcon />
              </Button>
            </Tooltip>
          </Popover2>
        </NavbarGroup>
        <NavbarGroup style={{ marginTop: '5px' }}>
          <AddButtonDrawer />
        </NavbarGroup>

        <NavbarGroup style={{ marginTop: '14px', marginLeft: '5px' }}>
          <AddBackgroundDrawer />
        </NavbarGroup>
        <NavbarGroup style={{ marginTop: '-18px', marginLeft: '-9px' }}>
          <AddQuestion />
        </NavbarGroup>
      </Navbar>

      {/* <Dialog
                className="shapesOverlay"
                enforceFocus={false}
                onClose={() => {
                    setShowsModel(false);
                }}
                isOpen={showsModel}
                autoFocus={true}
                canEscapeKeyClose={true}
                canOutsideClickClose={true}
                usePortal={false}>
                <div className={Classes.DIALOG_BODY}>
                    <AddShapes />
                </div>
            </Dialog> */}
    </React.Fragment>
  );
};

export default StudioToolbarLeft;
