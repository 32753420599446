import React from 'react';
import { Button, Card, Elevation, Position } from '@blueprintjs/core';
import { Link, useNavigate } from 'react-router-dom';
import { Col, Row } from 'antd';
import { DocumentIcon } from '../assets/svgs';
import { randomBackgroundColor } from '../constants/menuItems';
export const LessonCard = ({ id, imageUrl = '', title, noOfSlides = 0, popoverComponent = null }) => {
  const history = useNavigate();
  function getRandomColor(colors) {
    const randomIndex = Math.floor(Math.random() * colors.length);
    return colors[randomIndex].color;
  }

  const getBGColor = getRandomColor(randomBackgroundColor);
  return (
    <Card interactive={true} className="card_creation" style={{ background: getBGColor }}>
      <Row className="mb-4">
        <Col span={22}>
          <Button
            type="default"
            size="small"
            className="fw-400 d-flex button-icon"
            shape="round"
            style={{
              color: 'white',
              boxShadow: 'none',
              width: '58.67px',
              height: '20px',
              left: '350px',
              top: '474px',
              background: '#878787',
              border: ' 1px solid #878787',
              borderRadius: '30px',
              padding: '1rem 2.5rem'
            }}>
            <DocumentIcon />
                        Draft
          </Button>
        </Col>
        <Col span={2}>{popoverComponent}</Col>
      </Row>

      <div>
        <span>
          <img
            // onClick={() => history(`/studio/${id}?type=template`)}
            className="projectcardImage"
            src={imageUrl}
          />
        </span>
      </div>

      <p onClick={() => history(`/studio/${id}?type=template`)} className="projectCardsTitle" style={{ marginTop: '0' }}>
        {title}
      </p>
      <p>
        <span>
          <span style={{ color: '#6362B0' }}>Template</span> with {noOfSlides} Slides
        </span>
      </p>
    </Card>
  );
};
