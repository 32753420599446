import React, { forwardRef, useEffect, useMemo, useState } from 'react';
import {
  BtnBold,
  BtnBulletList,
  BtnClearFormatting,
  BtnItalic,
  BtnLink,
  BtnNumberedList,
  BtnRedo,
  BtnStrikeThrough,
  BtnStyles,
  BtnUnderline,
  BtnUndo,
  Editor,
  EditorProvider,
  HtmlButton,
  Toolbar,
} from 'react-simple-wysiwyg';

const EditorWrapper = forwardRef(({ displayToolbar, value: propValue, ...props }, ref) => {
  const [value, setValue] = useState(propValue);




  function onChange(e) {
    setValue(e.target.value);
    if (props.onChange) {
      props.onChange(e);
    }
  }

  return (
    <EditorProvider>
      <Editor {...props} value={value} onChange={onChange} ref={ref}>
        {displayToolbar &&
          <Toolbar>
            <BtnBold />
            <BtnItalic />
            <BtnUnderline />
            <BtnStrikeThrough />
            {/*<BtnStyles />*/}
            {/*<BtnLink />*/}
            <BtnBulletList />
            <BtnNumberedList />
            <BtnClearFormatting />
            {/*<BtnUndo />*/}
            {/*<BtnRedo />*/}
            {/*<HtmlButton />*/}

          </Toolbar>
        }

      </Editor>
    </EditorProvider>
  );
});

EditorWrapper.displayName = 'EditorWrapper';


export default EditorWrapper;
