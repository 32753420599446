import React from 'react';
import { Drawer as CoreDrawer, Classes } from '@blueprintjs/core';

export const Drawer = ({
  className = '',
  usePortal = false,
  isCloseButtonShown = true,
  title = null,
  children,
  ...rest
}) => {

  return (
    <CoreDrawer
      className={`${className}`}
      usePortal={usePortal}
      title={title}
      isCloseButtonShown={isCloseButtonShown}
      {...rest}>

      {children}



    </CoreDrawer>
  );
};
