import React, { useState } from 'react';
import { Button, Classes, Dialog, Divider, H6, Icon, Tab, Tabs } from '@blueprintjs/core';
import { useDispatch } from 'react-redux';
import { elementadd } from '../../../redux/studio/actions';
import appConfig from '../../../config';
import { PlusOutlined } from '@ant-design/icons';
import { message, Popconfirm, Select, Upload } from 'antd';
import { deleteItem, getAssets, newAssets, newMediaUpload } from '../../../helpers/apiCalls';
import { Popover2 } from '@blueprintjs/popover2';
import usePaginatedApi from '../../../customHooks/usePaginationApi';

function AddCustomImage() {
  const [assetImage, setAssetImage] = useState({});
  const [mediaModel, setMediamodel] = useState(false);
  const [assetCard, setAssetCard] = useState([<div key="{item}" className="loader"></div>]);
  const { data: assetData, loading, refreshData } = usePaginatedApi('assets', 10);
  const dispatch = useDispatch();

  const uploadProps = {
    name: 'file',
    multiple: false,
  };


  const onFinish = () => {
    let name = assetImage.name.split('.')[0] || '';
    let data = {
      data: {
        asset: assetImage.id,
        type: 'user',
        contenttype: 'user',
        name,
      },
    };

    newAssets(data)
      .then((res) => {
        message.success('Added Assets successfully!');
        refreshData();
      })
      .catch((err) => {
        message.error('Plz try again!', err);
      });
    setMediamodel(false);
  };
  const handleUpload = (item, name, index = '') => {
    const formData = new FormData();
    formData.append('files', item.file);
    newMediaUpload(formData, item.onProgress)
      .then((res) => {
        setAssetImage(res[0]);

        item.onSuccess(res, item.file);
      })
      .catch((err) => console.log(err));
  };
  const onChange = (item) => {
    const { status } = item.file;
    if (status === 'done') {
      message.success(`${item.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${item.file.name} file upload failed.`);
    }
  };
  const uploadbutton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  const deleteAsset = (id, type) => {
    if (type === 'system') {
      message.warning('cannot Delete System Images  ');
    } else {
      deleteItem('assets', '/' + id)
        .then((res) => {
          message.success('Deleted Entry');
          getAsset({ pagination });
        })
        .catch((err) => {
          message.error('Cannot Delete', err);
        });
    }
  };

  const downloadHandler = async (url) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement('a');
      link.href = URL.createObjectURL(blob);
      link.download = 'image.png';
      link.click();
      URL.revokeObjectURL(link.href);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };


  return (
    <div className="addcoutimageuploadcss">
      <div>
        <Button
          className="uploadMediaButton"
          onClick={() => {
            setMediamodel(true);
          }}>
          Upload
        </Button>
      </div>
      <div className="addcoutimgtitlecss">
        <Tabs id="TabsExample">
          <Tab
            id="ng"
            title="Images"
            panel={
              <div className="uploadImagemainContainer">
                <Divider />
                <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center' }}>
                  {assetData?.map((item, index) => (
                    <div className="uploadImageContainer" key={index}>
                      <img
                        alt="img"
                        key={index}
                        onClick={() => {
                          const addImageUrl =
                            appConfig.socketURL + item.attributes.asset.data[0].attributes?.url.replace('/', '');
                          const width = 20;
                          const height =
                            (item.attributes.asset.data[0].attributes?.height /
                              item.attributes.asset.data[0].attributes?.width) *
                            33;
                          let dimension = { width, height };
                          dispatch(elementadd({ value: addImageUrl, type: 'image', dimension: dimension }));
                        }}
                        src={
                          appConfig.socketURL + item.attributes.asset.data[0].attributes?.url.replace('/', '')
                        }></img>

                      <Popover2
                        className="uploadimg"
                        content={
                          <div className="editUploadImage">
                            <div style={{ display: 'flex' }}>
                              <H6>image.png</H6>
                              <svg
                                style={{ marginLeft: '10px' }}
                                width="14"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  d="M13.7513 4.33412L12.534 3.11688L2.99188 12.659V15.8398H6.1726L15.7148 6.2976L14.5019 5.08477L6.01781 13.5645C5.91391 13.6673 5.77819 13.7193 5.64248 13.7193C5.50677 13.7193 5.37106 13.6673 5.26716 13.5645C5.06041 13.3567 5.06041 13.0217 5.26716 12.8138L13.7513 4.33412ZM12.534 2.05664C12.8055 2.05664 13.0769 2.16054 13.2836 2.36729L16.4644 5.54801C16.8789 5.96151 16.8789 6.6337 16.4644 7.04719L6.92219 16.5894C6.72393 16.7887 6.45357 16.9 6.1726 16.9H2.99188C2.40663 16.9 1.93164 16.425 1.93164 15.8398V12.659C1.93164 12.3781 2.04297 12.1077 2.24229 11.9095L11.7845 2.36729C11.9912 2.16054 12.2626 2.05664 12.534 2.05664ZM6.1726 15.8398H2.99188V12.659L6.1726 15.8398Z"
                                  fill="#343741"
                                />
                              </svg>
                            </div>

                            <hr />
                            <div className="popoverSubtitle" onClick={() => downloadHandler(appConfig.socketURL + item.attributes.asset.data[0].attributes?.url.replace('/', ''))}>
                              <svg
                                width="16"
                                height="16"
                                viewBox="0 0 16 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M10.7208 8.16725L8.87109 10.1104V1.55165C8.87109 1.24482 8.64724 0.996094 8.37109 0.996094C8.09495 0.996094 7.87109 1.24482 7.87109 1.55165V10.0783L6.05184 8.16725C5.83884 7.93904 5.49284 7.93904 5.28084 8.16725C5.06784 8.39546 5.06784 8.7678 5.28084 8.99601L7.23084 11.0455C7.87184 11.7345 8.90284 11.7323 9.54184 11.0455L11.4918 8.99601C11.7048 8.7678 11.7048 8.39546 11.4918 8.16725C11.2798 7.93904 10.9338 7.93904 10.7208 8.16725ZM15.8711 10.9961V14.002C15.8711 15.1033 14.9766 15.9961 13.877 15.9961H2.88078C1.77949 15.9961 0.886719 15.1016 0.886719 14.002V10.9961H1.88672V14.002C1.88672 14.55 2.33251 14.9961 2.88078 14.9961H13.877C14.425 14.9961 14.8711 14.5503 14.8711 14.002V10.9961H15.8711Z"
                                  fill="#343741"
                                />
                              </svg>
                              <span className="popoverSubtitleText">Download</span>

                            </div>
                            <div className="popoverSubtitle">
                              <Popconfirm
                                title="Are you sure?"
                                onConfirm={() => deleteAsset(item.id, item.attributes.type)}
                                onCancel={() => console.log('cancelled')}
                                okText="Yes"
                                cancelText="No">
                                <svg
                                  width="17"
                                  height="17"
                                  viewBox="0 0 17 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg">
                                  <g clipPath="url(#clip0_1471_55786)">
                                    <path
                                      d="M11.8711 3.99609H16.8711V4.99609H0.871094V3.99609H5.87109V1.99609C5.87109 1.44409 6.31909 0.996094 6.87109 0.996094H10.8711C11.4231 0.996094 11.8711 1.44409 11.8711 1.99609V3.99609ZM4.81509 11.9961H7.87109V12.9961H4.97109L5.36309 15.5151C5.40509 15.7841 5.61709 15.9731 5.85609 15.9731H11.8861C12.1251 15.9731 12.3371 15.7841 12.3791 15.5151L13.8771 5.93909H14.8711L13.3671 15.6691C13.2511 16.4161 12.6271 16.9731 11.8861 16.9731H5.85609C5.11509 16.9731 4.49109 16.4161 4.37509 15.6691L2.86409 5.93909H9.87109V6.94609H4.02809L4.34709 8.99609H8.87109V9.99609H4.50309L4.81509 11.9961ZM6.87109 3.99609H10.8711V1.99609H6.87109V3.99609Z"
                                      fill="#343741"
                                    />
                                  </g>
                                  <defs>
                                    <clipPath id="clip0_1471_55786">
                                      <rect
                                        width="16"
                                        height="16"
                                        fill="white"
                                        transform="translate(0.871094 0.996094)"
                                      />
                                    </clipPath>
                                  </defs>
                                </svg>
                                <span className="popoverSubtitleText">Move to trash</span>
                              </Popconfirm>

                            </div>
                          </div>
                        }>
                        <button icon="more" className="btn">
                          <Icon icon="more"></Icon>
                        </button>
                      </Popover2>
                    </div>
                  ))}
                </div>
              </div>
            }
          />
          <Tab id="mb" title="video" panel={<div>Videos</div>} />
          <Tab id="cb" title="Audio" panel={<div>Audio</div>} />
        </Tabs>
      </div>
      <Dialog
        title=""
        // icon="info-sign"
        enforceFocus={false}
        onClose={() => {
          setMediamodel(false);
        }}
        isOpen={mediaModel}
        autoFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        usePortal={false}>
        <div className={Classes.DIALOG_BODY}>
          <div>
            <Upload
              {...uploadProps}
              maxCount={1}
              customRequest={(item) => handleUpload(item)}
              listType="picture-card"
              onChange={onChange}>
              {uploadbutton}
            </Upload>
            <Button disabled={loading} onClick={onFinish}>Save</Button>
            <br />
          </div>
        </div>
      </Dialog>
    </div>
  );
}

export default AddCustomImage;
