// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.styles_navbarCustomised__xUMwZ {
    background-color: #F9FAF2 !important;
}

.styles_appmenu_topbar__RK9bY {
    height: 85px;
}

.styles_menu_popover__cizCF {
    width: 759px
}

.styles_popover_top_col__5oA0L {
    width: 50%;
    padding: 16px
}

.styles_popover_icon__V7Qoq {
    width: 46px;
    height: 46px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/components/Topbar/styles.module.css"],"names":[],"mappings":"AAAA;IACI,oCAAoC;AACxC;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;AACJ;;AAEA;IACI,UAAU;IACV;AACJ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB","sourcesContent":[".navbarCustomised {\n    background-color: #F9FAF2 !important;\n}\n\n.appmenu_topbar {\n    height: 85px;\n}\n\n.menu_popover {\n    width: 759px\n}\n\n.popover_top_col {\n    width: 50%;\n    padding: 16px\n}\n\n.popover_icon {\n    width: 46px;\n    height: 46px;\n    border-radius: 50%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbarCustomised": `styles_navbarCustomised__xUMwZ`,
	"appmenu_topbar": `styles_appmenu_topbar__RK9bY`,
	"menu_popover": `styles_menu_popover__cizCF`,
	"popover_top_col": `styles_popover_top_col__5oA0L`,
	"popover_icon": `styles_popover_icon__V7Qoq`
};
export default ___CSS_LOADER_EXPORT___;
