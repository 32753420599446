import React from 'react';
import appConfig from '../../config';
import styles from './styles.module.css';
import default_image from '../../assets/images/default_image.png';

const InfoCard = ({ data, image, title, desc }) => {
  return (
    <div className={styles.infoCard_root}>
      <div className={styles.infoCardBox_img}>
        <img className={styles.infoCard_img} src={image?.data ? appConfig.socketURL +
                    image?.data?.[0]?.attributes?.url : default_image} />
      </div>
      <div className={styles.infoCard_heading}>{title}</div>
      <div className={styles.infoCard_desc}>{desc}</div>
    </div>
  );
};

export default InfoCard;
