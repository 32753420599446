import React from 'react';

export const ArrowSolidRight = ({
  width = '24',
  height = '24',
  className = '',
  style = {}
}) => {
  return (<svg
    className={className}
    style={style}
    width={width}
    height={height}
    xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path d="M24 12l-9-8v6h-15v4h15v6z" />
  </svg>);
};
