import React from 'react';
import Icon from '../../assets/images/dashboard_studio_projects.png';
import default_image from '../../assets/images/default_image.png';


export const ImageCaptionCard = ({imageUrl, title, subTitle, color = 'purple', className = '', style = {}}) => {
  return (

    <div
      className={`image-card position-relative  opacity-9 br-sm ${className}`}
      style={{backgroundImage: imageUrl ? `url('${imageUrl}')` : `url('${default_image}')`, ...style}}
    >

      <div
        className={`bg-${color} opacity-9 position-absolute w-100`}
        style={{
          bottom: '0',
          padding: '1rem 2rem',
          borderBottomLeftRadius: '10px',
          borderBottomRightRadius: '10px'
        }}
      >
        <h3 className="fs-md color-white fw-500">
          {title} <br/>
          <p className="fs-sm color-white fw-400">{subTitle}</p>
        </h3>
      </div>
      <div
        className="circle position-absolute"
        style={{
          bottom: '-7px',
          right: '-10px',
          background: 'white',
          width: '37px',
          height: '37px',
          paddingTop: '4px',
          border: '2px gray solid'
        }}
      >

        <img src={Icon}/>
      </div>
    </div>

  );
};
