import React, { useCallback } from 'react';
import { WizardQuestionItem } from './components/WizardQuestionItem';

export const WizardQuestion = ({ slideName = '', data = [], onFinishtext = () => {} }) => {
  const questionItem = useCallback((item, index) => {
    return (
      <WizardQuestionItem
        name={item.name}
        key={index}
        keyValue={index}
        questionNumber={index + 1}
        type={item.type}
        questionDefaultValue={item.question}
        handleOnFinish={onFinishtext}
        handleOnChange={onFinishtext}
        id={item.id}
      />
    );
  });

  return (
    <>
      <div style={{ marginTop: '3px' }}>
        <hr className="hrline" />
        <h6 className="wizardTitle">slideName - {slideName}</h6>
        <hr className="hrline" />
      </div>
      <div className="elementsection">
        <div
          style={{
            width: '280px',
            height: 'inherit',
          }}>
          {data.map(questionItem)}
        </div>
      </div>
    </>
  );
};
