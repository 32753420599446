import React from 'react';
import {FiChevronLeft} from 'react-icons/fi';
import {useNavigate} from 'react-router-dom';
import appConfig from '../../config';
import {updateData} from '../../services/template.service';
import Wizard from '../../components/Wizard/Index';

const WizardMockupQuestion = ({state: templateData, closeWizard}) => {
  const history = useNavigate();
  const [currentStep, setCurrentStep] = React.useState(1);
  const [projectData, setProjectData] = React.useState(templateData?.attributes?.project?.slides);
  const [themeQuestionBySlide, setThemeQuestionBySlide] = React.useState(templateData?.attributes?.project?.slides[0]);
  const handleGoBack = () => {
    const step = currentStep - 1;
    if (step === 0) {
      closeWizard();
    } else {
      setCurrentStep((prevState) => prevState - 1);
    }
  };
  const skipToEditor = () => history(`/studio/${templateData?.id}`);
  const handleLessonSubmit = async () => {
    let updatedProjectData = projectData;
    updatedProjectData = updatedProjectData.map((ele) => {
      return {...ele, elements: ele?.elements?.filter((ques) => ques?.content?.text || ques?.content?.src)};
    });
    const project = {
      ...templateData?.attributes?.project,
      slides: updatedProjectData,
    };
    await updateData(`${appConfig.collection}/projects/${templateData?.id}`, {project});
    skipToEditor();
  };
  const handelNextStep = () => {
    const step = currentStep + 1;
    if (step === templateData?.attributes?.project?.slides?.length + 1) {
      handleLessonSubmit();
    } else {
      let updatedProjectData = projectData;
      updatedProjectData[currentStep - 1] = themeQuestionBySlide;
      setProjectData(updatedProjectData);
      setThemeQuestionBySlide(projectData[currentStep]);
      setCurrentStep((prevState) => prevState + 1);
    }
  };


  // React.useEffect(() => {
  //     let projectData = [];
  //     const savedProjectData = {};
  //     const savedProjectQuestion = {};
  //     templateData?.attributes?.project?.slides?.forEach((ele) => {
  //         savedProjectData[ele?.id] = ele;
  //         ele?.elements?.forEach((item) => {
  //             savedProjectQuestion[item?.id] = item;
  //         });
  //     });
  //     Object.keys(templateData?.attributes?.slides || {}).map((key, index) => {
  //         const slides = {};
  //         templateData?.attributes?.theme?.data?.attributes?.theme?.slides.forEach((slide) => {
  //             slides[slide.slideType] = slide;
  //         });
  //         const templateSlide = slides[templateData?.attributes?.slides?.[key]?.name];
  //         projectData = [
  //             ...projectData,
  //             savedProjectData?.[templateSlide?.id]
  //                 ? {
  //                       ...templateSlide,
  //                       elements: templateSlide?.elements?.map((item) =>
  //                           savedProjectQuestion?.[item?.id] ? savedProjectQuestion?.[item?.id] : item
  //                       ),
  //                   }
  //                 : templateSlide,
  //         ];
  //         return templateSlide?.elements || [];
  //     });
  //     setProjectData(projectData);
  // }, []);

  return (
    <div className="wizard-container d-flex flex-column justify-content-between">
      <div onClick={handleGoBack} className="go-back-btn text-muted">
        <FiChevronLeft/>
        Back
      </div>
      <div className="lesson-builder-container" style={{overflowY: 'auto'}}>
        <div className="header">Let’s create your lesson</div>
        <div className="lesson-builder-slides-conatiner" style={{overflowY: 'auto'}}>
          {themeQuestionBySlide?.elements?.length ? (
            themeQuestionBySlide?.elements?.map((item, _) => {
              return (
                <Wizard item={item} _={_} key={_} setThemeQuestionBySlide={setThemeQuestionBySlide} themeQuestionBySlide={themeQuestionBySlide}/>
                // <div className="question-container" key={_}>
                //     <div className="question">{item?.question}</div>
                //     <div className="options">
                //         <div className="option">
                //             <FormInput
                //                 value={item?.content?.text}
                //                 onChange={(answer) => {
                //                     const quesDetail = {
                //                         ...item,
                //                         content: { text: answer },
                //                     };
                //                     let elements = themeQuestionBySlide?.elements;
                //                     elements[_] = quesDetail;
                //                     setThemeQuestionBySlide({
                //                         ...themeQuestionBySlide,
                //                         elements,
                //                     });
                //                 }}
                //             />
                //         </div>
                //     </div>
                // </div>
              );
            })
          ) : (
            <div className="No-question">No questions yet, Move next.</div>
          )}
        </div>
      </div>
      <WizardFooter
        currentSlide={currentStep}
        totalSlide={templateData?.attributes?.project?.slides?.length}
        nextSlide={handelNextStep}
        skipToEditor={skipToEditor}
      />
    </div>
  );
};

const WizardFooter = ({currentSlide, totalSlide, nextSlide, skipToEditor}) => {
  return (
    <div className="d-flex justify-content-between align-items-center Wizard-footer">
      <div className="d-flex slide-counter">
        <span style={{fontWeight: 'bold'}}>{currentSlide} Slide </span>
        <span>of {totalSlide}</span>
      </div>
      <div className="d-flex flex-column gap-2 slide-changer">
        <div className="primary-button text-center" onClick={nextSlide}>
          Next slide
        </div>
        <div className="text-muted text-underline cursor-pointer" onClick={skipToEditor}>
          Skip this. Go to editor
        </div>
      </div>
    </div>
  );
};

export default WizardMockupQuestion;
