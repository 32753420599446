import React from 'react';
import styles from './styles.module.css';
import { Col, Row } from 'antd';
import { footerItems } from '../../constants/menuItems';
import boat from '../../assets/images/naav.png';
import tortoise from '../../assets/images/tortoise.png';
import bird from '../../assets/images/bird.png';
const Footer = () => {
  return (
    <div className={styles.container}>
      <Row gutter={500} style={{ transform: 'translateY(-7rem)' }}>
        <Col>
          <img src={bird} /></Col>
        <Col><img src={boat} /></Col>
        <Col><img src={tortoise} /></Col>
      </Row>
      <Row className={styles.parentRow} gutter={100}>
        {footerItems.map((item) => (
          <Col key={item.id} className={styles.parentCol}>
            <div className={styles.heading}>{item.name}</div>
            <Row className={styles.childrenRow} gutter={30}>
              {item.children.map((subItem) => (
                <Col key={subItem.key} className={styles.children}>{subItem.name}</Col>
              ))}
            </Row>
          </Col>

        ))}
      </Row>
      <div className={styles.copyright}>
        <div>Copyright @ 2022. All Right Reserved.</div>
        <div className={styles.termandpolicy}>
          <div>Terms of Use</div>
          <div>Privacy Policy</div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
