import React, { useEffect } from 'react';

import { BoldOutlined, UnderlineOutlined, ItalicOutlined, FontColorsOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { elementUpdated } from '../../redux/studio/actions';
import { Button, Tooltip, Position, NavbarDivider, InputGroup } from '@blueprintjs/core';

import Imagerotate from './Imagerotate';
import Positionproperty from './Positionproperty';
import AnimationProperty from './Animationproperty';
import Replaceproperty from './Replaceproperty';
import Behavioursproperty from './Behavioursproperty';
import Link from './Link';
import { Minus, Plus, Cropbtn, assetRotate } from '../../ProjectSVG';
import CustomColor from '../../components/CustomColor';
import CustomTextColor from '../../components/CustomTextColor';
import rotate1 from '../../assets/images/rotate1.png';
import AspectIcon from '../../components/AspectIcon';
import AspectIconOff from '../../components/AspectIconOff';
import ImageCrops from './ImageCrops';

const testGif = (str) => {
  const regex = /\.gif$/i; // Regular expression to match ".gif" at the end of the string, case-insensitive
  return regex.test(str);
};

function StudioToolbar1(props) {
  const dispatch = useDispatch();
  const [fontWeightBold, setFontWeightBold] = React.useState(900);
  const [fontWeightItalic, setFontWeightItalic] = React.useState('italic');
  const [textDecoration1, setTextdecoration] = React.useState('underline');
  const [textCenter, setTextcenter] = React.useState('center');
  const [textLeft, setTextleft] = React.useState('left');
  const [textRight, setTextright] = React.useState('right');
  const [textJustify, setTextjustify] = React.useState('justify');
  const [actives, setActives] = React.useState(false);
  const [boldactives, setBoldactives] = React.useState(false);
  const [italicactives, setItalicactives] = React.useState(false);
  const [underline, setUnderline] = React.useState(false);
  const [centeractive, setCenteractive] = React.useState(false);
  const [leftactive, setLeftactive] = React.useState(false);
  const [rightactive, setRightactive] = React.useState(false);
  const [justifyactive, setJustifyactive] = React.useState(false);
  const [alphactives, setAlphactives] = React.useState(true);
  const [show, setShow] = React.useState(true);

  const [value, setValue] = React.useState('selectedElement && selectedElement.style');
  const { selectedElement } = useSelector((state) => state.Studio.present);
  let elemId = selectedElement;
  let backgroundColor;
  let textdisplay = 'block';
  let fontsize;
  let fontFamily;
  let fontWeight;
  let fontStyle;
  let text;
  let color;
  let srcimage;
  let textDecoration;
  let textalign;
  let imagedisplay = 'block';
  fontsize = elemId.style?.['font-size'];
  fontFamily = elemId.style?.['font-family'];
  fontWeight = elemId.style?.['font-weight'];
  fontStyle = elemId.style?.['font-style'];
  textDecoration = elemId.style?.['text-decoration'];
  backgroundColor = elemId.style?.backgroundColor;
  textalign = elemId.style?.['text-align'];

  if (elemId.type === 'img') {
    imagedisplay = 'none';
    srcimage = elemId.content?.src;
    color = '';
    backgroundColor = '';
    text = '';
  } else {
    textdisplay = 'none';
    color = elemId.style?.color;
    backgroundColor = elemId.style?.backgroundColor;
    srcimage = '';
    text = elemId.content?.text;
  }
  const handleMinus = (e) => {
    setValue(--e.target.value);
    dispatch(elementUpdated(elemId, e.target.value, e.target.name));
  };
  const handlePlus = (e) => {
    setValue(++e.target.value);
    dispatch(elementUpdated(elemId, e.target.value, e.target.name));
  };
  // var points = new Array(30);
  // for (var i = 0; i < 30; i++) {
  //     points[i] = i + 1;
  // }

  useEffect(() => {
    if (selectedElement && selectedElement.style) {
      if (selectedElement.style['font-weight'] === 900) {
        setBoldactives(true);
      } else {
        setBoldactives(false);
      }
      if (selectedElement.style['font-style'] === 'italic') {
        setItalicactives(true);
      } else {
        setItalicactives(false);
      }
      if (selectedElement.style['text-decoration'] === 'underline') {
        setUnderline(true);
      } else {
        setUnderline(false);
      }
    } else {
      setBoldactives(false);
      setItalicactives(false);
      setUnderline(false);
    }
  }, [selectedElement]);

  useEffect(() => {
    if (selectedElement && selectedElement.style) {
      if (selectedElement.style['text-align'] === 'center') {
        setCenteractive(true);
      } else {
        setCenteractive(false);
      }
      if (selectedElement.style['text-align'] === 'left') {
        setLeftactive(true);
      } else {
        setLeftactive(false);
      }
      if (selectedElement.style['text-align'] === 'right') {
        setRightactive(true);
      } else {
        setRightactive(false);
      }
      if (selectedElement.style['text-align'] === 'justify') {
        setJustifyactive(true);
      } else {
        setJustifyactive(false);
      }
    } else {
      setCenteractive(false);
      setLeftactive(false);
      setRightactive(false);
      setJustifyactive(false);
    }
  }, [selectedElement]);
  const onChange = (e) => {
    // setActives(!actives)
    dispatch(elementUpdated(elemId, e.target.value, e.target.name));
  };

  const onChangeAlphabet = (alph, value) => {
    setActives(!actives);
    setAlphactives(!alphactives);
    dispatch(elementUpdated(elemId, value, alph));
  };

  const onChangeBold = (fontWeight, value) => {
    if (fontWeightBold === 900) {
      setFontWeightBold(700);
    } else {
      setFontWeightBold(900);
    }

    setActives(!actives);
    setBoldactives(!boldactives);
    dispatch(elementUpdated(elemId, value, fontWeight, boldactives));
  };
  const onChangeItalic = (fontStyle, value) => {
    // console.log('RED', e.target.name, e.target.value, elemId);

    if (fontWeightItalic === 'italic') {
      setFontWeightItalic('normal');
    } else {
      setFontWeightItalic('italic');
    }
    setActives(!actives);
    setItalicactives(!italicactives);
    dispatch(elementUpdated(elemId, value, fontStyle, italicactives));
  };
  const onChangeUnderline = (textDecoration, value) => {
    // console.log('RED', e.target.name, e.target.value, elemId);
    if (textDecoration1 === 'underline') {
      setTextdecoration('');
    } else {
      setTextdecoration('underline');
    }
    setActives(!actives);
    setUnderline(!underline);
    dispatch(elementUpdated(elemId, value, textDecoration, underline));
  };
  const onChangeTextcenter = (textalign, value) => {
    // console.log('RED', e.target.name, e.target.value, elemId);
    if (textCenter === 'center') {
      setTextcenter('');
    } else {
      setTextcenter('center');
    }
    setActives(!actives);
    setCenteractive(!centeractive);
    dispatch(elementUpdated(elemId, value, textalign, centeractive));
  };
  const onChangeedit = () => {
    if (show === true) {
      setShow(false);
    } else if (show === false) {
      setShow(true);
    } else {
      setShow(true);
    }
  };

  const onChangeTextleft = (textalign, value) => {
    // console.log('RED', e.target.name, e.target.value, elemId);
    if (textLeft === 'left') {
      setTextleft('');
    } else {
      setTextleft('left');
    }
    setActives(!actives);
    setLeftactive(!leftactive);
    dispatch(elementUpdated(elemId, value, textalign, leftactive));
  };
  const onChangeTextright = (textalign, value) => {
    if (textRight === 'right') {
      setTextright('');
    } else {
      setTextright('right');
    }
    setActives(!actives);
    setRightactive(!rightactive);
    dispatch(elementUpdated(elemId, value, textalign, rightactive));
  };
  const onChangeTextjustify = (textalign, value) => {
    if (textJustify === 'justify') {
      setTextjustify('');
    } else {
      setTextjustify('justify');
    }
    setActives(!actives);
    setJustifyactive(!justifyactive);
    dispatch(elementUpdated(elemId, value, textalign, justifyactive));
  };

  return (
    <React.Fragment>
      {show && selectedElement.type === 'img' && (
        <>
          <Tooltip content="Rotate" position={Position.BOTTOM}>
            <Imagerotate />
          </Tooltip>
          <NavbarDivider />
          {!testGif(selectedElement?.content?.src) && (
            <>
              <Tooltip content="Crop" position={Position.BOTTOM}>
                <ImageCrops />
              </Tooltip>
              <NavbarDivider />
            </>
          )}



          <Tooltip content="Replace-Image" position={Position.BOTTOM}>
            <Replaceproperty />
          </Tooltip>
          <NavbarDivider />
        </>
      )}
      <Tooltip content="Animate" position={Position.BOTTOM}>
        <AnimationProperty />
      </Tooltip>

      <NavbarDivider />
      <Tooltip content="Position-Property" position={Position.BOTTOM}>
        <Positionproperty />
      </Tooltip>
      <NavbarDivider />
      <Tooltip content="Behaviour" position={Position.BOTTOM}>
        <Behavioursproperty />
      </Tooltip>
      <NavbarDivider />
      <Tooltip content="Link" position={Position.BOTTOM}>
        <Link />
      </Tooltip>

      {show && selectedElement.type === 'img' && (
        <>
          {' '}
          <NavbarDivider />
          <Tooltip content="Aspect Ratio" position={Position.BOTTOM_LEFT}>
            <div
              className="as-ratio"
              style={{ cursor: 'pointer' }}
              onClick={() => props.aspectHandler(props.isAspectOn)}>
              {!props.isAspectOn ? <AspectIconOff /> : <AspectIcon />}
            </div>
          </Tooltip>
        </>
      )}
    </React.Fragment>
  );
}

export default StudioToolbar1;
