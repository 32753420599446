import { message } from 'antd';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import appConfig from '../config';

const http = axios.create({
  baseURL: appConfig.collection,
  timeout: 60 * 1000,
});

http.interceptors.request.use(
  (config) => {
    const cookies = new Cookies();
    const user = cookies.get('user', { domain: appConfig.cookiesDoamin });
    const userObj = user ? (typeof user === 'object' ? user : JSON.parse(user)) : null;
    if (userObj?.jwt) {
      config.headers['Authorization'] = 'Bearer ' + userObj.jwt;

    }
    // if (!(config.method === "get" || config.data instanceof FormData)) {
    //     config.headers["Content-Type"] = "application/json";
    // }
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => Promise.reject(error)
);

http.interceptors.response.use(
  async (response) => {
    return response.data;
  },
  async (errorObj) => {
    const errorData = errorObj.response && errorObj.response.data;
    let { error, errors } = errorData;
    if (error) {
      console.debug(error);
      message.error(error.message);
    }
    if (errors) {
      error = errors[0];
      console.debug('Multiple errors:');
      errors.forEach((err) => console.debug(err));
      message.error(error.message);
    }
    message.error(error.message);
    return Promise.reject(error);
  }
);

export default http;
