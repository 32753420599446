import React from 'react';

import PropertiesDrawer from './RightSideDrawers/PropertiesDrawer';
import BehaviourDrawer from './RightSideDrawers/BehaviourDrawer';

function StudioToolbar2(props) {
  return (
    <React.Fragment>
      <PropertiesDrawer />
      {/* <BehaviourDrawer/> */}
    </React.Fragment>
  );
}

export default StudioToolbar2;
