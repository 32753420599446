import { Papers } from './Papers';
import { Shapes } from './Shapes';
import { ArrowDownWithShade } from './ArrowDownWithShade';
import { ArrowLeftWithCircle } from './ArrowLeftWithCircle';
import { ArrowUpWithCircle } from './ArrowUpWithCircle';
import { ArrowDownWithCircle } from './ArrowDownWithCircle';
import { Refresh } from './Refresh';
import { Triangle } from './Triangle';
import { Pentagon } from './Pentagon';
import { Circle } from './Circle';
import { PentagonSolid } from './PentagonSolid';
import { CircleSolid } from './CircleSolid';
import { TriangleSolid } from './TriangleSolid';
import { TriangleCircleSolid } from './TriangleCircleSolid';
import { TriangleCircleSolidFlipped } from './TriangleCircleSolidFlipped';
import { ArrowUp } from './ArrowUp';
import { ArrowDown } from './ArrowDown';
import { ArrowRight } from './ArrowRight';
import { ArrowLeft } from './ArrowLeft';
import { ArrowSolidDown } from './ArrowSolidDown';
import { ArrowSolidLeft } from './ArrowSolidLeft';
import { ArrowSolidRight } from './ArrowSolidRight';
import { ArrowSolidUp } from './ArrowSolidUp';
import { CrossHair } from './CrossHair';
import { Stack } from './Stack';
import { StackHorizontal } from './StackHorizontal';
import { PapersSolid } from './PapersSolid';
import { StackSolid } from './StackSolid';
import { TextAlignRight } from './TextAlignRight';
import { TextAlignCenter } from './TextAlignCenter';
import { TextAlignLeft } from './TextAlignLeft';
import { Paragraph } from './Paragraph';
import { CheckList } from './CheckList';
import { ChevronRight } from './ChevronRight';
import { ChevronLeft } from './ChevronLeft';
import { ChevronDown } from './ChevronDown';
import { X } from './X';
import { WindowsCursor } from './WindowsCursor';

export {
  Papers,
  Shapes,
  ArrowDownWithShade,
  ArrowLeftWithCircle,
  ArrowUpWithCircle,
  ArrowDownWithCircle,
  Refresh,
  Triangle,
  Pentagon,
  Circle,
  PentagonSolid,
  CircleSolid,
  TriangleSolid,
  TriangleCircleSolid,
  TriangleCircleSolidFlipped,
  ArrowUp,
  ArrowDown,
  ArrowRight,
  ArrowLeft,
  ArrowSolidDown,
  ArrowSolidLeft,
  ArrowSolidRight,
  ArrowSolidUp,
  CrossHair,
  Stack,
  StackHorizontal,
  PapersSolid,
  StackSolid,
  TextAlignRight,
  TextAlignCenter,
  TextAlignLeft,
  Paragraph,
  CheckList,
  ChevronRight,
  ChevronLeft,
  ChevronDown,
  X,
  WindowsCursor
};
