import React from 'react';
import { Form, Input } from 'antd';
import { Row, Col } from 'antd';
import { getImageQuestion } from '../../../../utils';

export const WizardQuestionItem = ({
  name,
  questionNumber,
  type,
  handleOnChange = () => { },
  handleOnFinish = () => { },
  keyValue,
  questionDefaultValue,
  id
}) => {
  return (
    <div key={keyValue}>
      <p className="wizardTitle1">
                Question - {questionNumber} ({name}){' '}
      </p>
      <p className="wizardTitle1" >
                ({type})
      </p>
      <hr className="hrline" />
      <Row>
        <Col>
          <img src={getImageQuestion()} alt="" height="30" className="icons" />
        </Col>
        <Col style={{ marginLeft: '10px' }}>
          <Form onFinish={handleOnFinish}>
            <Form.Item name="question">
              <Input.TextArea
                placeholder="Add your question"
                defaultValue={questionDefaultValue}
                onChange={(e) => handleOnChange(e.target.value, id)}
              />
            </Form.Item>
          </Form>
        </Col>
      </Row>
      <hr className="hrline" />
    </div>
  );
};
