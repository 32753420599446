import React, { useEffect, useState } from 'react';
import CardForUpload from './CardForUpload';
import { PlusWithCircleIcon, UploadCloudIcon } from '../../assets/svgs';
import styles from './styles.module.scss';
import { Col, Popover, Row } from 'antd';
import { getLoggedInUser } from '../../helpers/authUtils';
import { getSchool, updateSchool } from '../../helpers/apiCalls';
import appConfig from '../../config';
import uploadIcon from '../../assets/images/upload_icon.png';
import plus_add from '../../assets/images/plus_add.png';

import { SketchPicker } from 'react-color';
import ColorCard from './ColorCard';


const cards = [
  {
    id: 'logo',
    title: 'My School Logo',
    icon: <UploadCloudIcon />
  },
  {
    id: 'color',
    title: 'My School Colours',
    icon: <PlusWithCircleIcon />
  },
  {
    id: 'font',
    title: 'My School Font',
    icon: <PlusWithCircleIcon />
  }
];



const SchoolTheme = ({ setLogoImage, logoImage, setUploadType, setMediamodel }) => {
  const [school, setSchool] = useState();
  const loggedInUser = getLoggedInUser();
  const [isColorClicked, setIsColorClicked] = useState(false);
  const [pickColor, setPickColor] = useState('#fff');
  const [colorArr, setColorArr] = useState([]);
  const [editColorCode, setEditColorCode] = useState('');



  useEffect(() => {
    if (loggedInUser?.user?.profile?.schools[0]) {
      getSchool(loggedInUser?.user?.profile?.schools[0]?.id).then((res) => {
        setSchool(res.data);
        if (res.data?.attributes.scholeThemeColors) {
          setColorArr(res.data?.attributes.scholeThemeColors);
        }
        if (res.data?.attributes.schoolLogo?.data) {
          setLogoImage(res.data?.attributes.schoolLogo?.data);
        }
      });
    }
  }, []);

  const closeSketchHandler = async (e) => {
    if (editColorCode) {
      setPickColor(e.hex);
      setColorArr(prev => prev.map((item) => item.color === editColorCode ? { color: e.hex } : item));
      let payload = {
        data: {
          scholeThemeColors: colorArr.map((item) => item.color === editColorCode ? { color: e.hex } : item)
        }
      };
      await updateSchool(school?.id, payload);
      setEditColorCode('');
      setIsColorClicked(false);
    } else {
      setPickColor(e.hex);
      setColorArr(prev => [...prev, { color: e.hex }]);
      let payload = {
        data: {
          scholeThemeColors: [...colorArr, { color: e.hex }]
        }
      };
      await updateSchool(school?.id, payload);
      setIsColorClicked(false);
    }

  };

  const editColor = (color) => {
    setEditColorCode(color);
    setIsColorClicked(true);
  };
  const deleteColor = async (color) => {
    setColorArr(prev => prev?.filter((item) => item.color !== color));
    let payload = {
      data: {
        scholeThemeColors: colorArr.filter((item) => item.color !== color)
      }
    };
    await updateSchool(school?.id, payload);
  };

  return (
    <div>
      <div className={styles.asset_theme_text}>Create a school theme which can be applied to all your lessons</div>
      <Row gutter={100}>
        <Col>
          <div className={styles.card_upload_title}>{cards[0].title}</div>
          <div className={styles.add_school_theme_img}>
            {logoImage.length > 0 ? logoImage.map((item, id) => (
              <div className={styles.add_school_theme_logo_card} key={id}>
                <img src={appConfig.socketURL + item?.attributes?.url?.replace('/', '')} />
              </div>
            )) : (
                            <CardForUpload content={cards[0]} onClick={() => {
                              setUploadType('logo');
                              setMediamodel(true);
                            }}>
                              {cards[0].icon}
                            </CardForUpload>
                        )}
            {
              school?.attributes.schoolLogo?.data.length > 0 && (
                <Row className='justify-content-between mt-2 primary-color'>
                  <Col onClick={() => {
                    setMediamodel(true);
                    setUploadType('logo');
                  }}>Change</Col>
                  <Col className='d-flex' style={{ gap: '5px' }} onClick={() => {
                    setMediamodel(true);
                    setUploadType('logo');
                  }}>
                    <div>Upload</div>
                    <img src={uploadIcon} />
                  </Col>
                </Row>
              )
            }

          </div>
        </Col>
        <Col>
          <div className={styles.card_upload_title}>{cards[1].title}</div>
          <div className={styles.add_school_theme_img}>
            {isColorClicked ? (
                            <SketchPicker width="235px" color={pickColor} onChangeComplete={(e) => closeSketchHandler(e)} />
                        ) : colorArr.length > 0 ? colorArr.map((item, id) => (
                          <ColorCard item={item} id={id} editColor={editColor} deleteColor={deleteColor} />
                        )) : (
                            <CardForUpload content={cards[1]} onClick={() => setIsColorClicked(true)}>
                              {cards[1].icon}
                            </CardForUpload>
                        )}
            {
              school?.attributes.scholeThemeColors?.length > 0 && (
                <Row className='justify-content-end primary-color'>
                  <Col className='d-flex' style={{ gap: '5px', cursor: 'pointer' }} onClick={() => setIsColorClicked(true)}>
                    <div>Add Colour</div>
                    <img src={plus_add} />
                  </Col>
                </Row>
              )
            }

          </div>
        </Col>
        <Col>
          <div className={styles.card_upload_title}>{cards[2].title}</div>
          <div className={styles.add_school_theme_img}>
            <CardForUpload content={cards[2]}>
              {cards[2].icon}
            </CardForUpload>

          </div>
        </Col>
      </Row>
    </div>
  );
};

export default SchoolTheme;
