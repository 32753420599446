import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import {connect} from 'react-redux';

import {isMobileOnly} from 'react-device-detect';
import 'react-perfect-scrollbar/dist/css/styles.css';

import {DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown} from 'reactstrap';
import * as FeatherIcon from 'react-feather';

import AppMenu from './AppMenu';
import profilePic from '../assets/images/users/male.jpg';

/**
 * User Widget
 */
const UserProfile = (user) => {
  let usr = user.user;
  if (user.type === 'schoolAdmin' || user.type === 'teacher' || user.type === 'student') {
    // console.log("Redirect to classroom");
    window.location.replace('https://classroom.lauk.in/');
  }
  // console.log("user : ", usr.firstName, usr);
  let userImage = usr.image && usr.image.url ? usr.image.url : profilePic;

  return (
    <React.Fragment>
      <div className="media user-profile mt-2 mb-2 ">
        <div>
          <img src={userImage} className="avatar-sm rounded-circle mr-2"/>
        </div>
      </div>
      <div>
        <div className="media-body username">
          <h6 className="pro-user-name mt-0 mb-0">{usr.firstName + ' ' + usr.lastName}</h6>
          <span className="pro-user-desc">{usr.plan && usr.plan.name ? usr.plan.name : 'Free'}</span>
        </div>

        <UncontrolledDropdown className="align-self-center profile-dropdown-menu">
          <DropdownToggle
            data-toggle="dropdown"
            tag="button"
            className="btn btn-link p-0 dropdown-toggle mr-0  drop-button">
            <FeatherIcon.ChevronDown/>
          </DropdownToggle>
          <DropdownMenu right className="topbar-dropdown-menu profile-dropdown-items appmenu">
            <Link to="/my-account" className="dropdown-item notify-item drop-link">
              <FeatherIcon.User className="icon-dual icon-xs mr-2 drop-link"/>
              <span>My Account</span>
            </Link>
            {/*<Link to="/" className="dropdown-item notify-item">*/}
            {/*    <FeatherIcon.Settings className="icon-dual icon-xs mr-2" />*/}
            {/*    <span>Settings</span>*/}
            {/*</Link>*/}
            {/*<Link to="/" className="dropdown-item notify-item">*/}
            {/*    <FeatherIcon.HelpCircle className="icon-dual icon-xs mr-2" />*/}
            {/*    <span>Support</span>*/}
            {/*</Link>*/}
            {/*<Link to="/" className="dropdown-item notify-item">*/}
            {/*    <FeatherIcon.Lock className="icon-dual icon-xs mr-2" />*/}
            {/*    <span>Lock Screen</span>*/}
            {/*</Link>*/}
            <DropdownItem divider/>
            <Link to="/account/logout" className="dropdown-item notify-item drop-link">
              <FeatherIcon.LogOut className="icon-dual icon-xs mr-2 drop-link"/>
              <span>Logout</span>
            </Link>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </React.Fragment>
  );
};

/**
 * Sidenav
 */
const SideNav = () => {
  return (
    <div className="sidebar-content ">
      <div id="sidebar-menu">
        <AppMenu/>
      </div>
    </div>
  );
};

class LeftSidebar extends Component {
  menuNodeRef;

  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
    this.handleOtherClick = this.handleOtherClick.bind(this);
  }

  /**
   * Bind event
   */
  componentDidMount = () => {
    document.addEventListener('mousedown', this.handleOtherClick, false);
  };

  /**
   * Bind event
   */
  componentWillUnmount = () => {
    document.removeEventListener('mousedown', this.handleOtherClick, false);
  };

  /**
   * Handle the click anywhere in doc
   */
  handleOtherClick = (e) => {
    if (this.menuNodeRef.contains(e.target)) {
      return;
    }
    // else hide the menubar
    if (document.body && isMobileOnly) {
      document.body.classList.remove('sidebar-enable');
    }
  };

  /**
   * Handle click
   * @param {*} e
   * @param {*} item
   */
  handleClick(e) {
    console.log(e);
  }

  render() {
    const isCondensed = this.props.isCondensed || false;

    return (
      <React.Fragment>
        <div style={{zIndex: 0}} className="left-side-menu appmenu " ref={(node) => (this.menuNodeRef = node)}>
          {/*<UserProfile />*/}
          {/* {this.props.user ? <UserProfile user={this.props.user.user} /> : ''} */}
          {!isCondensed && <SideNav/>}
          {isCondensed && <SideNav/>}
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  const {user, loading, error} = state.Auth;
  return {user, loading, error};
};
export default connect(mapStateToProps)(LeftSidebar);
// export default connect()(LeftSidebar);
