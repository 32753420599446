import React from 'react';
import { Outlet } from 'react-router-dom';
import { connect } from 'react-redux';
import { changeSidebarTheme, changeSidebarType } from '../redux/actions';
import * as layoutConstants from '../constants/layout';
import LeftSidebar from '../components/LeftSidebar';

import RightSidebar from '../components/RightSidebar';
import { Container } from 'reactstrap';
import ThemeCustomizer from '../components/ThemeCustomizer';
import Topbar from '../components/Topbar';
import Footer from '../components/Footer';

const VerticalLayout = (props) => {
  const isCondensed = props.layout.leftSideBarType === layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED;
  const isLight = props.layout.leftSideBarTheme === layoutConstants.LEFT_SIDEBAR_THEME_DEFAULT;
  const openLeftMenu = () => {
    if (document.body) {
      if (document.body.classList.contains('sidebar-enable')) {
        document.body.classList.remove('sidebar-enable');
        props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
      } else {
        if (document.body.classList.contains('left-side-menu-condensed'))
        {document.body.classList.remove('left-side-menu-condensed');}
        document.body.classList.add('sidebar-enable');
      }
    }
  };
  React.useEffect(() => {
    if (window.innerWidth >= 768 && window.innerWidth <= 1028) {
      props.changeSidebarType(layoutConstants.LEFT_SIDEBAR_TYPE_CONDENSED);
    }
  }, []);
  console.log('topbar', props);
  return (
    <div className="app">
      <div id="wrapper">
        <Topbar openLeftMenuCallBack={openLeftMenu} {...props} />

        <div className="content-page">
          {/* <LeftSidebar isCondensed={isCondensed} isLight={isLight} {...props} /> */}

          <div className="content">
            <Container fluid>
              <Outlet />
            </Container>
          </div>
        </div>
        {props?.mainTopbar ? null : <Footer />}
      </div>

      <RightSidebar title="Customize" {...props}>
        <ThemeCustomizer />
      </RightSidebar>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

const mapDispatchToProps = (dispatch) => ({
  changeSidebarType: (type) => {
    dispatch(changeSidebarType(type));
  },
  changeSidebarTheme: (theme) => {
    dispatch(changeSidebarTheme(theme));
  },
  // Add your mapDispatchToProps logic if needed
});

export default connect(mapStateToProps, mapDispatchToProps)(VerticalLayout);
