import Button from './index';
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import React from 'react';

export const PrimaryNavButton = ({ inverse = false, className = '', direction = 'next', disabled = false, loading = false, ...rest }) => {
  const classNameInternal = !inverse ? 'carousel-nav-btn ' : 'carousel-nav-btn-inverse ';
  const disabledClass = '';
  const loadingClass = '';
  const iconClass = 'icon';

  return <Button className={`${classNameInternal}${className} ${loading
        ? loadingClass
        : disabled ? disabledClass : ''}`}
  disabled={disabled}
  {...rest}
  >
    {direction === 'next' ? <ArrowRightOutlined className={iconClass} /> : <ArrowLeftOutlined className={iconClass} />}
  </Button>;
};
