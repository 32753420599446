import { Carousel, Col, Row } from 'antd';
import React from 'react';
import { ImageCaptionCard } from '../../components/Card/ImageCaptioinCard';
import { CarouselNavWrapper } from '../../components/commonComponent/CarouselCustom/CarouselNavWrapper';
import { NavButton } from '../../components/commonComponent/CarouselCustom/NavButton';
import { SubSectionLayout } from '../../layouts/SubSectionLayout';
import appConfig from '../../config';


// const settings = {
//     dotPosition: "bottom",
//     slidesToShow: 4,
//     slidesToScroll: 4,
//     className: "dashboard-slider slider-test d-lg-none",
//     dots: false,
//     arrows: true,
//     nextArrow: (
//         <CarouselNavWrapper>
//             <NavButton top={0} marginTop="-4%" right="0%" direction="next" />
//         </CarouselNavWrapper>
//     ),
//     prevArrow: (
//         <CarouselNavWrapper>
//             <NavButton
//                 top={0}
//                 marginTop="-4%"
//                 direction="prev"
//                 right="5%"
//                 left="auto"
//             />
//         </CarouselNavWrapper>
//     ),
//     responsive: [
//         {
//             breakpoint: 1024,
//             settings: "unslick",
//         },
//         {
//             breakpoint: 999,
//             settings: {
//                 arrows: true,
//                 slidesToShow: 1.5,
//                 infinite: false,
//                 slidesToScroll: 1,
//                 nextArrow: (
//                     <CarouselNavWrapper>
//                         <NavButton direction="next" top="40%" inverse />
//                     </CarouselNavWrapper>
//                 ),
//                 prevArrow: <div className="d-none"></div>,
//                 //variableWidth: true
//             },
//         },
//     ],
// };

const contentStyle = {
  margin: 0,
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const StudioList = ({ className, style, project }) => {
  // console.log('imageurl', item.attributes.images.data[0].attributes.url)

  const onChange = (currentSlide) => {
    console.log(currentSlide);
  };

  return (
    <SubSectionLayout
      title={'My studio projects'}
      className={className}
      style={style}
      arrowDisplay={false}
    >
      {/* bigger than md resolution show grid */}
      <Row gutter={[24, 24]} className="mx-0 row-listing d-none d-lg-flex">
        {project?.data.slice(0, 3).map((item, k) => {
          return (
            <Col xs={15} md={10} lg={10} xl={8} xxl={6} key={k}>
              <ImageCaptionCard
                // style={{width: '80%'}}
                title={item?.attributes?.username}
                subTitle={item?.attributes?.title}
                imageUrl={item?.attributes?.thumbnail?.data ?
                                    appConfig.socketURL +
                                    item?.attributes?.thumbnail?.data[0]?.attributes?.url : null
                }
                color={k % 2 === 0 ? 'purple' : 'green'}
              />
            </Col>
          );
        })}
      </Row>
      {/* bigger than md resolution show grid  End*/}
      {/* mobile resolution show carousel */}
      {/* <Carousel afterChange={onChange} slidesToShow={3}>
                {project.data.map((item, k) => {
                    return (
                        <Col span={23} className="px-0" key={k}>
                            <ImageCaptionCard
                                title={item?.attributes?.username}
                                subTitle={item?.attributes?.title}
                                imageUrl={
                                    appConfig.socketURL +
                                    item?.attributes?.images?.data?.[0]?.attributes?.url
                                }
                                color={k % 2 === 0 ? "purple" : "green"}
                            />
                        </Col>
                    );
                })}
            </Carousel> */}
      {/* mobile resolution show carousel End*/}
    </SubSectionLayout>
  );
};

export default StudioList;
