import React, { useState } from 'react';
import styles from './styles.module.scss';
import { Popover } from 'antd';
import { Button, Classes } from '@blueprintjs/core';
import deleteIcon from '../../assets/images/delete_icon.png';
import editIcon from '../../assets/images/edit_icon.png';

const ColorCard = ({ item, id, editColor, deleteColor }) => {
  const [open, setOpen] = useState(false);


  const handleOpenChange = (newOpen) => {
    setOpen(newOpen);
  };
  return (
    <div className={styles.add_school_theme_color_card} key={id}>
      <span className={styles.add_school_theme_color_circle} style={{ background: item?.color }}></span>
      <span className={styles.add_school_theme_logo_hexcode}>{item?.color}</span>
      <Popover
        arrow={false}
        placement="bottom"
        trigger="click"
        open={open}
        onOpenChange={handleOpenChange}
        content={
          <div className="card_pop" style={{ height: '85px' }}>
            <div className="popoverSubtitle" onClick={() => {
              editColor(item.color);
              setOpen(false);
            }}>
              <img src={editIcon} />
              <button
                className={Classes.POPOVER_DISMISS}
                style={{
                  border: 'white',
                  backgroundColor: 'white',
                  fontFamily: 'Lexend',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  fontSize: '14px',
                }}
              >
                                Edit
              </button>
            </div>
            <div className="popoverSubtitle" onClick={() => {
              deleteColor(item.color);
              setOpen(false);
            }}>
              <img src={deleteIcon} />
              <button
                className={Classes.POPOVER_DISMISS}
                style={{
                  border: 'white',
                  backgroundColor: 'white',
                  fontFamily: 'Lexend',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  fontSize: '14px',
                }}
                // className="btn_pop"
              >
                                Delete
              </button>
            </div>
          </div>
        }>
        <Button icon="more" className="projectcardMore"></Button>
      </Popover>
    </div>
  );
};

export default ColorCard;
