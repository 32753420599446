import React from 'react';
import { Layout } from 'antd';

const BuilderLayout = ({ sideComponent, contentComponent, className = '', ...rest }) => {
  const { Sider, Content } = Layout;

  return (
    <Layout hasSider style={{ gap: 24 }} className={`bg-white ${className} layout-content-padding`} {...rest}>
      <Sider theme='light'>
        {sideComponent}
      </Sider>
      <Content theme='light' size='large'>
        {contentComponent}
      </Content>
    </Layout>

  );
};

export default BuilderLayout;
