import React, { useEffect, useState } from 'react';
import { Label } from '@blueprintjs/core';
import { Row, Input } from 'antd';
import { Button } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import { LinkIcon } from '../../ProjectSVG';
import { useDispatch, useSelector } from 'react-redux';
import { elementUpdated } from '../../redux/studio/actions';
const Link = () => {
  const { selectedElement } = useSelector((state) => state.Studio.present);
  const dispatch = useDispatch();
  const [addLink, setAddLink] = useState('');
  function handleLinkparamChange(event) {
    dispatch(elementUpdated(selectedElement, event.target.value, 'linkParam'));
    setAddLink(event.target.value);
    // console.log('LinkEvent', event.target, selectedElement);
  }
  const linkHandler = () => {
    const callValue = document.getElementById('link').value;
    const typeValue = 'click';
    dispatch(elementUpdated(selectedElement, callValue, 'linkCall'));
    dispatch(elementUpdated(selectedElement, typeValue, 'linkType'));
  };
  useEffect(() => {
    setAddLink('');
  }, [selectedElement]);
  return (
    <Popover2
      content={
        <div className="positionc" style={{ position: 'absolute' }}>
          <Row style={{ marginTop: '-6px' }}>
            <Label style={{ color: 'black', fontSize: '13px', fontWeight: '300' }}>
              <b>Enter a link or search </b>
            </Label>
          </Row>
          <Row style={{ marginTop: '-6px' }}>
            <Input
              style={{
                width: '201px',
                height: '36px',
                borderRadius: '6px',
                fontSize: '12px',
              }}
              name="name"
              placeholder="Enter link in select image"
              value={addLink}
              onChange={handleLinkparamChange}
            />
          </Row>
        </div>
      }>
      <Button
        style={{ textAlign: 'center', marginBottom: '10px', marginLeft: '-8px' }}
        minimal={true}
        onClick={linkHandler}
        value={'link'}
        id="link"
        disabled={selectedElement.disable === false ? false : true}>
        <LinkIcon />
      </Button>
    </Popover2>
  );
};

export default Link;
