// @flow
import appConfig from '../config';
import axios from 'axios';
import { getLoggedInUser, isUserAuthenticated } from './authUtils';
import qs from 'qs';
import * as rdd from 'react-device-detect';
// import store from '../redux/store';

window.rdd = rdd;

window.onerror = function (error, url, line) {
  // controller.sendLog({acc:'error', data:'ERR:'+error+' URL:'+url+' L:'+line});
};

const makeHeader = (jwt) => {
  let ujwt = jwt;
  if (!jwt) {
    let user = getLoggedInUser();
    ujwt = user?.jwt;
  }
  return {
    headers: {
      Authorization: 'Bearer ' + ujwt,
    },
  };
};

const copyInvite = (userName, roomName, cid, type, password) => {
  let msg = `
${userName} is inviting you to join Lauk ${type}.

${type} Name: ${roomName}
${type} ID: ${cid}

${type} Link
${appConfig.roomLink + cid}
`;

  if (password) {
    msg += `
Room Password: ${password}`;
  }

  return msg;
};

/**
 * Get Meetings
 */
const getMeeting = async (userID, meetingID, cid, mode, filter = '') => {
  let filters = '';
  let header = {};

  if (mode === 'private') {
    header = makeHeader();
  }
  if (meetingID) {
    filters += '/' + meetingID;
    filters += filter.length ? '?' + filter : '';
  }
  if (userID) {
    filters += '?owner=' + userID;
    filters += filter.length ? '&' + filter : '';
  }
  if (cid) {
    filters += '?cid=' + cid;
    filters += filter.length ? '&' + filter : '';
  }

  try {
    return await axios
      .get(appConfig.meetings + filters, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
  } catch (error) {
    return error;
  }
};

const newMeeting = async (meeting) => {
  const header = makeHeader();
  try {
    return await axios
      .post(appConfig.meetings, meeting, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // Handle error.
        return error;
      });
  } catch (error) {
    return error;
  }
};

/**
 * Update User ProfileDetails
 * waiting  userWaiting  ongoing  ended
 */
const updateMeeting = async (meetingID, data) => {
  let header = {};
  const isAuthTokenValid = isUserAuthenticated();
  if (isAuthTokenValid) {
    header = makeHeader();
  }

  return await axios
    .put(appConfig.meetings + '/' + meetingID, data, header)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * Update User ProfileDetails
 */
const updateUserData = async (userID, data) => {
  const header = makeHeader();
  // const data = {password: pass};

  return await axios
    .put(appConfig.users + '/' + userID, data, header)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * Update User Password
 */
const updateUserPassword = async (userID, data) => {
  const header = makeHeader();
  // const data = {password: pass};

  return await axios
    .put(appConfig.users + '/' + userID, data, header)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      return error;
    });
};

/**
 * Create Call Log
 */
const newCallLog = async (data) => {
  // const header = makeHeader();
  try {
    return await axios
      .post(appConfig.callLog, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // Handle error.
        return error;
      });
  } catch (error) {
    console.log(error);
  }
};

/**
 * Update Call Log
 */
const updateCallLog = async (logID, data) => {
  // const header = makeHeader();
  try {
    return await axios
      .put(appConfig.callLog + '/' + logID, data)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // Handle error.
        return error;
      });
  } catch (error) {
    console.log(error);
  }
};

/**
 * Get Meetings
 */
const getCallLogs = async (userID, cid, filter = '') => {
  // let filters = "";
  let query = '';
  const header = makeHeader();

  if (userID) {
    query = qs.stringify({
      _where: { _or: [{ participant: userID }, { owner: userID }] },
    });
    //, _sort:[{updatedAt:'ASC'}]
  }
  if (cid) {
    query = qs.stringify({
      _where: [{ cid: cid }],
    });
    // filters += "?cid=" + cid
    // filters += filter.length ? "&" + filter : "";
  }
  query += '&_sort=updatedAt:DESC';
  try {
    return await axios
      .get(appConfig.callLog + '?' + query, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return [];
      });
  } catch (error) {
    console.log(error);
  }
};

/**
 * Get Billing Link
 */
const getBillingLink = async (stripeID, returnLink) => {
  // const header = makeHeader();
  const header = makeHeader();
  try {
    return await axios
      .post(
        appConfig.billingLink,
        {
          customerId: stripeID,
          returnlink: returnLink,
        },
        header
      )
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        // Handle error.
        return error;
      });
  } catch (error) {
    console.log(error);
  }
};

// Upload Media
// */

const newMediaUpload = async (formData, onProgress = null) => {
  const header = makeHeader();

  try {
    return await axios
      .post(appConfig.upload, formData, {
        ...header,
        onUploadProgress: (e) => {
          if (onProgress) {
            onProgress({ percent: (e.loaded / e.total) * 100 });
          }
        },
      })
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);

        return [];
      });
  } catch (error) {
    console.log(error);
  }
};
// Get Assets
const getAssets = async (id = '', limit = 20, start = 1, search = '', filter = '', contenttype = '') => {
  //https://apilw.html5.run/api/assets?filters[contenttype][$eq]=button&limit=10&start=10

  let url = `${appConfig.assets}?sort=updatedAt:DESC`;
  const header = makeHeader();
  if (id) {
    url += `${id}`;
  }
  url += `&start=${start}&limit=${limit}`;
  if (contenttype) {
    url += `&filters[$and][0][contenttype][$eq]=${contenttype}`;
  }
  if (search) {
    url += `&filters[$and][1][name][$contains]=${search}`;
  }
  // if()
  // if (contenttype && search) {
  //     url += `?filters[contenttype][$eq]=${contenttype}&filters[name][$contains]=${search}`;
  //     if (limit) {
  //         url += `&start=${start}&limit=${limit}`;
  //     }
  // } else if (limit && contenttype) {
  //     url += `?start=${start}&limit=${limit}&filters[contenttype][$eq]=${contenttype}`;
  // }
  try {
    return await axios
      .get(url, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  } catch (error) {
    console.log(error);
  }
};

//search keywords from assets

const fetchKeywordsResults = async (query) => {
  const BASE_URL = appConfig.assets;
  if (query && query.length > 0) {
    const parsedQuery = query.replaceAll(' ', '+');
    const url = `${BASE_URL}` + '?filters' + `[name][$containsi]=${parsedQuery}`;
    //Cartoon-Forest-Background-1.png
    const res = await axios(url);
    const resJson = res.data;
    return resJson.data;
  } else {
    const parsedQuery = query.replaceAll(' ', '+');
    const url = `${BASE_URL}` + '?filters' + `[name][$containsi]=${parsedQuery}`;
    const res = await axios(url);
    const resJson = res.data;
    return resJson.data;
  }
};
/*
Create Assest
*/
const newAssets = async (data) => {
  const header = makeHeader();
  try {
    return await axios.post(appConfig.assets, data, header).then((response) => {
      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};

const getSvgs = async (data) => {
  const header = makeHeader();
  try {
    return await axios
      .get(`${appConfig.svgs}?sort=order:ASC&pagination[page]=0&pagination[pageSize]=100`, null, header)
      .then((response) => {
        return response.data;
      });
  } catch (error) {
    throw new Error(error);
  }
};

/*
Get Socials
*/
const getSocial = async (userID, itemID) => {
  const header = makeHeader();

  let query = '';
  if (userID) {
    query = 'user=' + userID;
  }

  try {
    return await axios
      .get(appConfig.socialProps + '?' + query, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  } catch (error) {
    console.log(error);
  }
};
const getSchool = async (id, itemID) => {
  const header = makeHeader();

  let url = appConfig.schools;
  if (id) {
    url += `/${id}`;
  }

  try {
    return await axios
      .get(url, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  } catch (error) {
    console.log(error);
  }
};
const updateSchool = async (id, data) => {
  const header = makeHeader();

  let url = appConfig.schools;
  if (id) {
    url += `/${id}`;
  }

  try {
    return await axios
      .put(url, data, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  } catch (error) {
    console.log(error);
  }
};
/*
 Create Socials
*/
const newSocialLink = async (data) => {
  const header = makeHeader();

  try {
    return await axios
      .post(appConfig.socialProps, data, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  } catch (error) {
    console.log(error);
  }
};

/*
Get Overlays
*/
const getOverlays = async (userID, itemID) => {
  const header = makeHeader();

  let query = '';
  if (userID) {
    query = 'user=' + userID;
  }

  try {
    return await axios
      .get(appConfig.overlays + '?' + query, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  } catch (error) {
    console.log(error);
  }
};
/*
Get Overlays
*/
const getLayouts = async (userID, itemID) => {
  const header = makeHeader();

  let query = '';
  if (userID) {
    query = 'user=' + userID;
  }

  try {
    return await axios
      .get(appConfig.layouts + '?' + query, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  } catch (error) {
    console.log(error);
  }
};
//getShows
const getShows = async (userID, itemID) => {
  const header = makeHeader();

  let query = '';
  if (userID) {
    query = 'user=' + userID;
  }

  try {
    return await axios
      .get(appConfig.shows + '?' + query, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  } catch (error) {
    console.log(error);
  }
};
/*
Get Projects
*/
const getProjects = async (id = '', limit = '', start = '', search = '', filter = '', sortType = '', filters) => {
  let url = appConfig.projects;
  // console.log("daapro",url)
  const header = makeHeader();
  if (id) {
    url += `/${id}`;
  }
  console.log('decode search', decodeURIComponent(search));
  if (search) {
    // url += `?$filters[title]=${search}`;
    if (limit) {
      url += `?start=${start}&limit=${limit}&sort=createdAt:DESC&filters[$and][0][title][$contains]=${decodeURIComponent(
        search
      )}`;
    }
  } else if (filters && sortType !== '') {
    url += `?start=${start}&limit=${limit}&sort=${sortType}:ASC&filters[$and][0][status][$eq]=${filters}`;
  } else if (filters && limit && sortType === '') {
    console.log('Filterss');
    url += `?start=${start}&limit=${limit}&sort=createdAt:DESC&filters[$and][0][status][$eq]=${filters}`;
  } else if (sortType !== '') {
    url += `?start=${start}&limit=${limit}&sort=${sortType}:ASC`;
  } else if (limit && sortType === '') {
    url += `?start=${start}&limit=${limit}&sort=createdAt:DESC`;
  }
  try {
    return await axios
      .get(url, header)
      .then((response) => {
        // console.log("daapro",response.data)

        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  } catch (error) {
    console.log(error);
  }
};

const getResources = async () => {
  let url = appConfig.resources;
  // console.log("daapro",url)
  const header = makeHeader();

  try {
    return await axios
      .get(url, header)
      .then((response) => {
        // console.log("daapro",response.data)

        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  } catch (error) {
    console.log(error);
  }
};
const getTemplates = async (id = '', limit = '', start = '', search = '', filter = '') => {
  let url = appConfig.templates;
  console.log('daatem', search);

  const header = makeHeader();
  if (id) {
    url += `/${id}`;
  }
  if (search && filter) {
    url += `?${filter}_contains=${search}`;
    if (limit) {
      url += `&start=${start}&limit=${limit}&sort=createdAt:DESC`;
    }
  } else if (limit && search.length > 0) {
    url += `?start=${start}&limit=${limit}&sort=createdAt:DESC&filters[$and][0][title][$contains]=${search}`;
  } else if (limit) {
    url += `?start=${start}&limit=${limit}&sort=createdAt:DESC`;
  }

  try {
    return await axios
      .get(url, header)
      .then((response) => {
        // console.log("daatem",response.data)

        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  } catch (error) {
    console.log(error);
  }
};
const getThemes = async (id = '', limit = '', start = '', search = '', filter = '') => {
  let url = appConfig.theme;
  // console.log("daatheme",url)

  const header = makeHeader();
  if (id) {
    url += `/${id}`;
  }
  if (search && filter) {
    url += `?${filter}_contains=${search}`;
    if (limit) {
      url += `&start=${start}&limit=${limit}&sort=createdAt:DESC`;
    }
  } else if (limit) {
    url += `/${id}`;
  }

  try {
    return await axios
      .get(url, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  } catch (error) {
    console.log(error);
  }
};
//Add Project
const addPublished = async (data) => {
  const header = makeHeader();
  try {
    return await axios.post(appConfig.publisheds, data, header).then((response) => {
      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};

//Add Project
const addProject = async (data) => {
  const header = makeHeader();
  try {
    return await axios.post(appConfig.projects, data, header).then((response) => {
      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};

//Add Template
const addTemplate = async (data) => {
  const header = makeHeader();
  try {
    return await axios.post(appConfig.templates, data, header).then((response) => {
      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};

//Add Theme
const addTheme = async (data) => {
  const header = makeHeader();
  try {
    return await axios.post(appConfig.theme, data, header).then((response) => {
      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};
//Editing project
const editProject = async (ID, data) => {
  const header = makeHeader();
  try {
    return await axios.put(appConfig.projects + '/' + ID, data, header).then((response) => {
      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};
const editTheme = async (ID, data) => {
  const header = makeHeader();
  try {
    return await axios.put(appConfig.theme + '/' + ID, data, header).then((response) => {
      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};
//Editing Template
const editTemplate = async (ID, data) => {
  const header = makeHeader();

  try {
    return await axios.put(appConfig.templates + '/' + ID, data, header).then((response) => {
      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};
//Getting count
const getCount = async (type, search = '', filter = '') => {
  let url = appConfig.count + `${type}/count`;
  console.log('getcount', url);
  if (search && filter) {
    url += `?filters[${filter}][$containsi]=${search}`;
  }
  try {
    return await axios.get(url).then((response) => {
      console.log('getcout', response.data);

      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};
// delete item
const deleteItem = async (model, id) => {
  const header = makeHeader();
  let url = appConfig[model];
  url = `${url}/${id}`;

  try {
    return await axios.delete(url, header).then((response) => {
      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};

/*
Create shows
*/
const createShows = async (data) => {
  const header = makeHeader();

  try {
    return await axios
      .post(appConfig.shows, data, header)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        console.log(error);
        return [];
      });
  } catch (error) {
    console.log(error);
  }
};

const getLessons = ({ query = '' }) => {
  try {
    return axios
      .get(`https://apilw.html5.run/api/lessons?${query}`)
      .then((res) => {
        return { data: res.data.data };
      })
      .catch((error) => {
        if (error) {
          console.log('error message', error.message);
          return { data: null, error: error.message };
        }
      });
  } catch (err) {
    return { data: null, error: err.message };
  }
};

const getSlideTemplate = () => {
  try {
    return axios
      .get(`https://apilw.html5.run/api/slide-templates?sort=updatedAt:ASC&filters[$and][0][isDisplay][$eq]=true`)
      .then((res) => {
        console.log('responseData', res.data);
        return { data: res.data.data.map(({ attributes }) => attributes.slide) };
      })
      .catch((error) => {
        if (error) {
          console.log('error message', error.message);
          return { data: null, error: error.message };
        }
      });
  } catch (err) {
    return { data: null, error: err.message };
  }
};

const createAISlide = async (data) => {
  // const header = makeHeader();
  try {
    return await axios.post('https://aibdev.gnx.sh/api/v1/pipeline/articulate-studio-slides', data).then((response) => {
      return response.data;
    });
  } catch (error) {
    throw new Error(error);
  }
};

export {
  deleteItem,
  addProject,
  addTemplate,
  addTheme,
  getProjects,
  getResources,
  getTemplates,
  getThemes,
  editProject,
  editTemplate,
  addPublished,
  // getElements,
  getCount,
  makeHeader,
  copyInvite,
  getBillingLink,
  newCallLog,
  updateCallLog,
  getCallLogs,
  getMeeting,
  newMeeting,
  updateMeeting,
  updateUserData,
  updateUserPassword,
  getSocial,
  newSocialLink,
  getOverlays,
  getLayouts,
  getShows,
  newMediaUpload,
  newAssets,
  getAssets,
  createShows,
  fetchKeywordsResults,
  editTheme,
  getLessons,
  getSchool,
  updateSchool,
  getSvgs,
  getSlideTemplate,
  createAISlide
};
