import { Row, Col } from 'antd';
import React from 'react';
import { Typography } from 'antd';
import dashboardImage from '../../assets/images/dashboard_profile_img.png';
import dashbaordBanner from '../../assets/images/dashboard_banner.png';
import dashbaordSaves from '../../assets/images/dashboard_saved.png';
import resourceCurriculum from '../../assets/images/resource_curriculum.png';
import dashboardStudents from '../../assets/images/dashboard_students.png';
import dashboardAssessments from '../../assets/images/dashboard_assessments.png';
import styles from './styles.module.css';
import { getLoggedInUser } from '../../helpers/authUtils';


const list = [
  {
    id: 1,
    image: dashbaordSaves,
    title: 'Saved Resources',
    color: '#E6F5F0',
    border: '#92DFC5'
  },
  {
    id: 2,
    image: resourceCurriculum,
    title: 'My Curriculum',
    color: '#FFF3DE',
    border: '#D2BB93'
  },
  {
    id: 3,
    image: dashboardStudents,
    title: 'My Students',
    color: '#EAEFFF',
    border: '#8AA2EF'
  },
  {
    id: 4,
    image: dashboardAssessments,
    title: 'My Assessments',
    color: '#FFDED0',
    border: '#ECAB8F'
  },
];


const { Title } = Typography;
const Banner = () => {
  const loggedInUser = getLoggedInUser();
  return (
    <Row className={styles.banner_root}>
      <Col style={{ width: '70%', padding: '3rem' }}>
        <div style={{ fontSize: '30px', color: '#000', fontWeight: 900 }}>Dashboard</div>
        <Row gutter={15}>
          <Col><div style={{ fontSize: '24px', color: '#7459FB' }}>{`Hello, ${loggedInUser?.user?.profile?.title} ${loggedInUser?.user?.profile?.firstName} ${loggedInUser?.user?.profile?.lastName}`}</div></Col>
          <Col>
            <img src={dashboardImage} /></Col>
        </Row>
        <div style={{ fontSize: '18px' }}>Everything that you need in one place. From saved worksheets to free
                    collections and lessons that you have created!  <span style={{ color: '#5C3091', cursor: 'pointer', fontWeight: '700', textDecoration: 'underline' }}>Upgrade your subscription</span> to
                    access all premium features!
        </div>
        <Row gutter={15} style={{ marginTop: '2rem' }}>
          {list.map((item, id) => (
            <Col className={styles.banner_card} key={id} style={{ background: item.color, border: `1px solid ${item.border}` }}>
              <img src={item.image} />
              <div className={styles.banner_card_text}>{item.title}</div>
            </Col>
          ))}
        </Row>
      </Col>
      <Col className={styles.banner_left}><img src={dashbaordBanner} /></Col>
    </Row>
  );
};

export default Banner;
