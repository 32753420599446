import React from 'react';

export const Svg = ({
  id = '',
  onClick = () => { },
  style = {},
  children,
  class: className ='',
  'fill-rule': fillRule = '',
  'clip-rule': clipRule = '',
  'stroke-linejoin': strokeLinejoin = '',
  'stroke-miterlimit': strokeMiterlimit = '',
  ...rest
}) => {
  return (
    <svg
      className={className}
      id={id}
      clipRule={clipRule}
      fillRule={fillRule}
      strokeLinejoin={strokeLinejoin}
      strokeMiterlimit={strokeMiterlimit}
      onClick={onClick}
      style={style}
      {...rest}
    >
      {children}
    </svg>
  );
};
