import { Carousel, Col, Row } from 'antd';
import React, { useRef, useState } from 'react';
import InfoCard from '../../components/Card/InfoCard';
import { SubSectionLayout } from '../../layouts/SubSectionLayout';

const RecentlyLesson = ({ resources, className, style }) => {
  const slider = useRef(null);

  const handlePrev = () => {
    slider.current.prev();
  };

  const handleNext = () => {
    slider.current.next();
  };
  return (
    <SubSectionLayout
      title={'Recently played lessons'}
      className={className}
      style={style}
      arrowDisplay={true}
      handlePrev={handlePrev}
      handleNext={handleNext}
    >
      <Carousel ref={slider} slidesToShow={4} dots={false} dotPosition='bottom' slidesToScroll={4} >
        {resources?.data.map(({ attributes, id }, k) => {
          return (
            <Col key={k}>
              <InfoCard
                data={attributes}
                id={id}
                image={attributes?.thumbnail}
                title={attributes?.title}
                desc={attributes?.description}
              />
            </Col>
          );
        })}
      </Carousel>
    </SubSectionLayout>
  );
};

export default RecentlyLesson;
