import React, {useState} from 'react';
import {Col, Form, message, Row} from 'antd';
import {PlusOutlined} from '@ant-design/icons';
import {addProject, getCount, getProjects, newMediaUpload} from '../../helpers/apiCalls';
import {Classes, Dialog} from '@blueprintjs/core';
import {getLoggedInUser} from '../../helpers/authUtils';
import appConfig from '../../config';
import {BannerSection} from './components/BannerSection';
import {Loader} from './components/Loader';
import {Pagination} from '../../ui/Pagination';
import {CardListContainer} from './components/CardListContainer';
import {getImageFallback} from '../../utils';
import {ProjectCard} from '../../components/Card/ProjectCard';
import {ProjectPreviewPopover} from '../../components/Card/ProjectCard/components/ProjectPreviewPopover';
import {AddProjectForm} from '../../components/Forms/AddProjectForm';
import {FilterSection} from '../../components/Filters/FilterSection/FilterSection';
// import Template from './Template';

const layout = {
  labelCol: {
    span: 6,
  },
  wrapperCol: {
    span: 20,
  },
};
const uploadProps = {
  name: 'file',
  multiple: false,
};
const uploadbutton = (
  <div>
    <PlusOutlined/>
    <div className="uploadbutton">Upload</div>
  </div>
);

const MyCreation = () => {
  const [showModel, setShowsModel] = useState(false);
  const [copyDialog, setCopydialog] = useState(false);
  const [type, setType] = useState('');
  const [thumbnailImageId, setThumbnailImageId] = useState('2017');
  const [referesh, setReferesh] = useState(true);
  const [form] = Form.useForm();
  const loggedInUser = getLoggedInUser();
  const [projectData, setProjectData] = useState(() => []);
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 4,
  });
  const getRandomuserParams = (params) => ({
    limit: params.pagination.pageSize,
    start:
      params.pagination.current > 1
        ? params.pagination.current * params.pagination.pageSize - params.pagination.pageSize
        : 0,
    ...params,
  });
  const getProject = (params, sortType, filter = null) => {
    setLoading(true);
    const {limit, start} = getRandomuserParams(params);
    getProjects('', limit, start, search.searchText, search.searchedColumn, sortType, filter).then((res) => {
      setProjectData(res.data);
      setPagination({...params.pagination});
      setLoading(false);
    });
  };

  const handleChange = (value) => {
    //setCardData([<span className="loader"></span>]);
    let pagination1 = {...pagination, current: value};
    getProject({pagination: pagination1}, type);
  };

  const [search, setSearch] = useState({
    searchText: '',
    searchedColumn: '',
  });

  const [loading, setLoading] = React.useState(false);
  console.log('projectreload', loading);
  const [total, setTotal] = React.useState([]);

  // setting Total no. of Project
  React.useEffect(() => {
    getCount('Project', loggedInUser?.user?.username, '')
      .then((res) => {
        setTotal(res.entity);
      })
      .catch((err) => console.log(err));
    getProject({pagination}, type);
  }, [search.searchText]);
  //Getting data of project from API

  const onFinish = async (values) => {
    const loggedInUser = getLoggedInUser();
    const username = loggedInUser?.user?.username;
    const obj = appConfig.initailProject;
    const JSONproject = JSON.stringify(obj);

    let data = {
      data: {
        title: values.title,
        project: JSONproject,
        username: username,
        thumbnail: thumbnailImageId,
      },
    };

    await addProject(data)
      .then((res) => {
        message.success('Successfully created new Course!');

        getProject({pagination});
        setShowsModel(false);
      })
      .catch((err) => {
        message.error('Plz try again!', err);
      });
    await setThumbnailImageId('2017');
  };
  const onCopyFinish = async (values) => {
    const loggedInUser = getLoggedInUser();
    const username = loggedInUser?.user?.username;

    let data = {
      data: {
        title: values.title,
        project: {}, // see to this later
        username: username,
        thumbnail: thumbnailImageId,
      },
    };

    await addProject(data)
      .then((res) => {
        message.success('Project Copied Sucessfully !');

        getProject({pagination});
        setCopydialog(false);
      })
      .catch((err) => {
        message.error('Plz try again!', err);
      });
    await setThumbnailImageId('2017');
  };
  window.imgg = thumbnailImageId;

  const handleUpload = (item, name, index = '') => {
    const formData = new FormData();
    formData.append('files', item.file);
    newMediaUpload(formData, item.onProgress)
      .then((res) => {
        setThumbnailImageId(res[0].id);
        item.onSuccess(res, item.file);
      })
      .catch((err) => console.log(err));
  };

  function handleStatusChange(event) {
    getProject({pagination}, type, event);
  }

  function handleSortChange(event) {
    let type = '';
    let value =
      event === 'Name'
        ? (type = 'title')
        : event === ' Date'
          ? (type = 'createdAt')
          : event === ' Visibilty'
            ? (type = 'username')
            : '';
    setType(value);
    getProject({pagination}, value);
    // console.log('Select sort event', value);
  }

  // const ap = async (event) => {
  //     await  setCardData(event);
  // };

  const onChange = (item) => {
    const {status} = item.file;
    if (status === 'done') {
      message.success(`${item.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${item.file.name} file upload failed.`);
    }
  };
  return (
    <>
      <BannerSection/>
      <FilterSection
        handleSortChange={handleSortChange}
        handleStatusChange={handleStatusChange}
        handleNewProjectClick={() => setShowsModel(true)}
        className="mb-5"
        title="You might want to try..."
      />
      {loading ? (
        <Loader/>
      ) : (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            marginTop: '-59px',
          }}>
          <div className="projectcardsContainer">
            <CardListContainer>{makeProjectCards({data: projectData})}</CardListContainer>
          </div>
          {projectData?.length >= 1 ? (
            <div style={{display: 'flex', justifyContent: 'center'}}>
              <Pagination onChange={handleChange} defaultCurrent={1} defaultPageSize={4} total={total}/>
            </div>
          ) : (
            <span style={{fontSize: 22, fontWeight: 600}}>Oops We didn't find any results</span>
          )}
        </div>
      )}

      <Dialog
        title="Add Your Projects"
        icon="info-sign"
        enforceFocus={false}
        onClose={() => {
          setShowsModel(false);
        }}
        isOpen={showModel}
        autoFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        usePortal={false}>
        <div className={Classes.DIALOG_BODY}>
          <Row>
            <Col span={24}>
              <AddProjectForm
                layout={layout}
                onValuesChange={() => setReferesh(!referesh)}
                form={form}
                name="control-hooks"
                onFinish={onFinish}
                uploadProps={{
                  ...uploadProps,
                  maxCount: 1,
                  customRequest: (item) => handleUpload(item),
                  listType: 'picture-card',
                  onChange: onChange,
                  childComponent: uploadbutton,
                }}
                title="Add Project"
              />
            </Col>
          </Row>
        </div>
      </Dialog>

      <Dialog
        title="Add Your Projects"
        icon="info-sign"
        enforceFocus={false}
        onClose={() => {
          setCopydialog(false);
        }}
        isOpen={copyDialog}
        autoFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        usePortal={false}>
        <div className={Classes.DIALOG_BODY}>
          <Row>
            <Col span={24}>
              <AddProjectForm
                onValuesChange={() => setReferesh(!referesh)}
                {...layout}
                form={form}
                name="control-hooks"
                onFinish={onCopyFinish}
                layout={layout}
                uploadProps={{
                  ...uploadProps,
                  maxCount: 1,
                  customRequest: (item) => handleUpload(item),
                  listType: 'picture-card',
                  onChange: onChange,
                  childComponent: uploadbutton,
                }}
                title="Create Copy of the Project"
              />
            </Col>
          </Row>
        </div>
      </Dialog>
    </>
  );
};

const imageUrlFallback = (data) => {
  if (!data) {
    return getImageFallback();
  }
  return appConfig.socketURL + data[0]?.attributes.url.replace('/', '');
};

const makeProjectCards = ({data = []}) => {
  return data?.length
    ? data.map((item, index) => {
      return (
        <ProjectCard
          key={index}
          popoverTitle={item.attributes.title}
          id={item.id}
          imageUrl={imageUrlFallback(item.attributes.thumbnail.data)}
          noOfSlides={item.attributes.project?.slides?.length}
          title={item.attributes.title}
          popoverComponent={<ProjectPreviewPopover title={item.attributes.title} id={item.id}/>}
        />
      );
    })
    : null;
};

export default MyCreation;
