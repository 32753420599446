import React, { useState } from 'react';
import { SketchPicker } from 'react-color';
// import 'antd/dist/antd.css';
import { InputGroup, Tag, Button } from '@blueprintjs/core';
import { Row, Col } from 'reactstrap';
import { Label } from '@blueprintjs/core';
import { useDispatch, useSelector } from 'react-redux';
import { elementChanged, elementUpdated } from '../../../../redux/studio/actions';
import { AnticlockIcon, HorizontalflipIcon, LeftIcon, TopIcon, VerticalflipIcon } from '../../../../ProjectSVG';
import $ from 'jquery';
import { ELEMENT_Type } from '../../../../constants';
import { STUDIO_ELEMENTS } from '../../../utils';
import { rgbaToHex } from '../../../../redux/appMenu/utils';

const { CANVAS_EL } = STUDIO_ELEMENTS;
const { SHAPES, SVG } = ELEMENT_Type;
const defaultValue = 0;

function AdvancedFormat() {
  const [rotate1, setRotate1] = useState('rotate(90deg)');
  const [flipright, setFlipright] = useState('scaleX(-1)');
  const [fliptop, setFliptop] = useState('scaleY(-1)');

  const { selectedElement } = useSelector((state) => state.Studio.present);

  const dispatch = useDispatch();

  let elemId = selectedElement;

  const width = parseFloat(elemId.position?.width);
  const height = parseFloat(elemId.position?.height);
  const fillColor = elemId.style?.fill || '#000';
  const strokeColor = elemId.style?.stroke || '#000';
  const strokeWidth = elemId.style?.strokeWidth || '.5';

  const onChange = (e) => {
    if (e.target.name === 'top' || e.target.name === 'left') {
      let elem = selectedElement;
      let jqElem = $('#' + elem.id);
      let parentOffset = jqElem.offset();
      let d = { x: elem.position.x, y: elem.position.y };
      if (e.target.name === 'top') { d.y = e.target.value; }
      if (e.target.name === 'left') { d.x = e.target.value; }
      //or $(this).offset(); if you really just want the current element's offset
      let relX = d.x - parentOffset.left;
      let relY = d.y - parentOffset.top;
      let el = $(CANVAS_EL);

      let cpos = el.offset();

      let pos = {
        top: d.y - cpos.top,
        left: d.x - cpos.left,
      };

      let elpos = {
        top: ((pos.top - relY) * 100) / el.height(),
        left: ((pos.left - relX) * 100) / el.width(),
      };

      elem.position.x = d.x;
      elem.position.y = d.y;
      elem.position.top = elpos.top;
      elem.position.left = elpos.left;
      dispatch(elementChanged(elem));
      return;
    }
    if (e.target.name !== 'transform') {
      // so far takes care of height and width
      dispatch(elementUpdated(elemId, e.target.value, e.target.name));
    }
  };
  const onChange90 = (transformRotate, value) => {
    if (rotate1 === 'rotate(90deg)') {
      setRotate1('rotate(180deg)');
    } else if (rotate1 === 'rotate(180deg)') {
      setRotate1('rotate(270deg)');
    } else if (rotate1 === 'rotate(270deg)') {
      setRotate1('rotate(360deg)');
    } else {
      setRotate1('rotate(90deg)');
    }
    dispatch(elementUpdated(elemId, value, transformRotate));
  };
  const onChangeRotate = (e) => {
    const transformValue = e.target.value;
    const transformUpdatedValue = `rotate(${transformValue}deg)`;

    dispatch(elementUpdated(elemId, transformUpdatedValue, 'transformRotate'));
  };
  const onChangeflipright = (transformSkew, value) => {
    if (flipright === 'scaleX(-1)') {
      setFlipright('');
    } else {
      setFlipright('scaleX(-1)');
    }

    dispatch(elementUpdated(elemId, value, transformSkew));
  };
  const onChangeProperty = (property, value) => dispatch(elementUpdated(elemId, value, property));

  const onChangefliptop = (transformSkew, value) => {
    // setFlip(true)
    if (fliptop === 'scaleY(-1)') {
      setFliptop('');
    } else {
      setFliptop('scaleY(-1)');
    }

    dispatch(elementUpdated(elemId, value, transformSkew));
  };
  return (
    <div onChange={onChange} style={{ marginTop: '3px', height: 'calc(100% - 6px)', overflowX: 'hidden' }}>
      <Label className="ElementsTitle">Size and Rotation</Label>
      <div style={{ padding: '8px', marginTop: '-16px' }}>
        <div style={{ display: selectedElement.type === SHAPES ? 'none' : 'block' }}>
          <Row>
            <Col>
              <Tag className="greenbuttons">Width</Tag>
              <InputGroup
                asyncControl={true}
                value={width}
                name="width"
                type="number"
                step="any"
                className="PropertiesInput"
              />
            </Col>
            <Col>
              <Tag className="greenbuttons">Height</Tag>
              <InputGroup
                asyncControl={true}
                value={height}
                name="height"
                step="any"
                type="number"
                className="PropertiesInput"
              />
            </Col>
          </Row>
        </div>
        <div style={{ display: selectedElement.type === SHAPES ? 'block' : 'none' }}>
          <Row>
            <Col>
              <Tag className="greenbuttons">Shapes Width</Tag>
              <InputGroup
                asyncControl={true}
                value={width}
                name="shapewidth"
                type="number"
                step="any"
                className="PropertiesInput"
              />
            </Col>
            <Col>
              <Tag className="greenbuttons">Shapes Height</Tag>
              <InputGroup
                asyncControl={true}
                value={height}
                name="shapeheight"
                step="any"
                type="number"
                className="PropertiesInput"
              />
            </Col>
          </Row>
        </div>
        <Row>
          <Col xs={6}>
            <Tag className="greenbuttons">Rotate</Tag>
            <InputGroup
              asyncControl={true}
              value={defaultValue}
              name="transform"
              type="number"
              step="any"
              className="PropertiesInput"
              onChange={onChangeRotate}
            />
          </Col>
          <Col xs={2}>
            <Row>
              <Col>
                <span style={{ fontSize: '12px' }}>90</span>
              </Col>
            </Row>
            <Row>
              <Button
                minimal={true}
                name="transform"
                value={rotate1}
                style={{ border: '0px' }}
                onClick={() => onChange90('transformRotate', rotate1)}>
                <AnticlockIcon />
              </Button>
            </Row>
          </Col>
          <Col xs={2}>
            <Row>
              <Col>
                <span style={{ fontSize: '12px' }}>Flip</span>
              </Col>
            </Row>
            <Row>
              <Col style={{ display: 'flex' }}>
                <Button
                  minimal={true}
                  name="transform"
                  value={flipright}
                  style={{ border: '0px' }}
                  onClick={() => onChangeflipright('transformSkew', flipright)}>
                  <VerticalflipIcon />
                </Button>
                <Button
                  minimal={true}
                  name="transform"
                  value={fliptop}
                  style={{ border: '0px' }}
                  onClick={() => onChangefliptop('transformSkew', fliptop)}>
                  <HorizontalflipIcon />
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
        <div style={{ display: selectedElement.type === SVG ? 'block' : 'none' }}>
          <Row>
            <Col>
              <Tag className="greenbuttons">Shape Thickness</Tag>
              <InputGroup
                asyncControl={true}
                value={strokeWidth}
                name="shapeThickness"
                type="number"
                step={0.1}
                className="PropertiesInput"
                onChange={({ target }) => onChangeProperty('strokeWidth', target?.value)}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Tag className="greenbuttons">Fill Color</Tag>
              <SketchPicker
                color={fillColor}
                onChange={({ rgb }) => onChangeProperty('fill', rgbaToHex(rgb.r, rgb.g, rgb.b, rgb.a))}
              />
            </Col>
            <Col>
              <Tag className="greenbuttons">Stroke Color</Tag>
              <SketchPicker
                color={strokeColor}
                onChange={({ rgb }) =>
                  onChangeProperty('stroke', rgbaToHex(rgb.r, rgb.g, rgb.b, rgb.a))
                }
              />
            </Col>
          </Row>
        </div>
      </div>
      <hr />
      <Label className="ElementsTitle">Position</Label>
      <div style={{ padding: '8px', overflow: 'hidden', marginTop: '-6px' }}>
        <Row style={{ padding: '5px' }}>
          <Col>
            <Tag className="greenbuttons">
              <div style={{ display: 'flex' }}>
                <span>
                  <div>
                    <TopIcon />
                  </div>
                </span>
                <span>Top</span>
              </div>
            </Tag>
            <Button
              className="PropertiesInput"
              style={{
                marginLeft: ' 10px',
                height: '28px',
                marginTop: '10px',
                border: '1px solid #a19a9a',
              }}
              asyncControl={true}
              name="vertical-align"
              minimal={true}
              onClick={() => {
                dispatch(elementUpdated(elemId, 0, 'top'));
              }}>
              Top
            </Button>
          </Col>
          <Col>
            <Tag className="greenbuttons">
              <div style={{ display: 'flex' }}>
                <span>
                  <div>
                    <LeftIcon />
                  </div>
                </span>
                <span>Left</span>
              </div>
            </Tag>
            <Button
              className="PropertiesInput"
              style={{
                marginLeft: ' 10px',
                marginTop: '10px',
                height: '28px',
                border: '1px solid #a19a9a',
              }}
              asyncControl={true}
              name="vertical-align"
              minimal={true}
              onClick={() => {
                dispatch(elementUpdated(elemId, 0, 'left'));
              }}>
              Left
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default AdvancedFormat;
