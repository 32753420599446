import React, { useState } from 'react';
import { Button, Position, Tooltip, Classes } from '@blueprintjs/core';
import AddTextType from './AddTextType';
import { Drawer } from '../../../ui/Drawer/Drawer';




export const AddTextDrawer = () => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);


  return (
    <div style={{ margingLeft: '-2px' }}>
      <Button onClick={() => setIsDrawerOpen(true)} className="drawerbuttons" minimal={true}>
        <div>
          <Tooltip content="Add Text" position={Position.RIGHT_TOP}>
            <svg
              width="31"
              height="47"
              viewBox="0 0 32 64"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path
                d="M5.25109 43.9961V37.3961H2.75509V35.5961H9.84709V37.3961H7.29109V43.9961H5.25109ZM12.9271 44.1161C12.2231 44.1161 11.6111 43.9761 11.0911 43.6961C10.5711 43.4081 10.1671 43.0201 9.87906 42.5321C9.59106 42.0361 9.44706 41.4721 9.44706 40.8401C9.44706 40.3521 9.52706 39.9041 9.68706 39.4961C9.84706 39.0881 10.0711 38.7361 10.3591 38.4401C10.6471 38.1361 10.9871 37.9041 11.3791 37.7441C11.7791 37.5761 12.2151 37.4921 12.6871 37.4921C13.1351 37.4921 13.5431 37.5721 13.9111 37.7321C14.2871 37.8921 14.6111 38.1161 14.8831 38.4041C15.1551 38.6921 15.3631 39.0321 15.5071 39.4241C15.6511 39.8161 15.7151 40.2441 15.6991 40.7081L15.6871 41.2241H10.6111L10.3351 40.1441H14.1271L13.9231 40.3721V40.1321C13.9071 39.9321 13.8431 39.7561 13.7311 39.6041C13.6271 39.4441 13.4871 39.3201 13.3111 39.2321C13.1351 39.1441 12.9351 39.1001 12.7111 39.1001C12.3991 39.1001 12.1311 39.1641 11.9071 39.2921C11.6911 39.4121 11.5271 39.5921 11.4151 39.8321C11.3031 40.0641 11.2471 40.3521 11.2471 40.6961C11.2471 41.0481 11.3191 41.3561 11.4631 41.6201C11.6151 41.8761 11.8311 42.0761 12.1111 42.2201C12.3991 42.3641 12.7391 42.4361 13.1311 42.4361C13.4031 42.4361 13.6431 42.3961 13.8511 42.3161C14.0671 42.2361 14.2991 42.1001 14.5471 41.9081L15.4471 43.1801C15.1991 43.3961 14.9351 43.5721 14.6551 43.7081C14.3751 43.8441 14.0871 43.9441 13.7911 44.0081C13.5031 44.0801 13.2151 44.1161 12.9271 44.1161ZM20.8112 43.9961L19.4552 41.9441L18.8312 41.0681L16.3112 37.6241H18.6152L19.9232 39.5801L20.6072 40.5161L23.0912 43.9961H20.8112ZM16.2512 43.9961L18.8192 40.4201L19.8512 41.6561L18.5192 43.9961H16.2512ZM20.4632 41.2241L19.4432 40.0001L20.7032 37.6241H22.9712L20.4632 41.2241ZM24.6925 43.9961V36.0161H26.6125V43.9961H24.6925ZM23.5645 39.3041V37.6241H27.8485V39.3041H23.5645Z"
                fill="#545353"
              />
              <path
                d="M4.87109 3.24609V6.24609C4.87109 6.51412 5.01406 6.76171 5.24609 6.89564C5.47813 7.02957 5.76406 7.02957 5.99609 6.89564C6.22812 6.76171 6.37109 6.51411 6.37109 6.24609V3.99609H15.3711V21.9961H13.1211C12.8531 21.9961 12.6055 22.1391 12.4715 22.3711C12.3376 22.6031 12.3376 22.8891 12.4715 23.1211C12.6055 23.3531 12.8531 23.4961 13.1211 23.4961H19.1211C19.3891 23.4961 19.6367 23.3531 19.7706 23.1211C19.9046 22.8891 19.9046 22.6031 19.7706 22.3711C19.6367 22.1391 19.3891 21.9961 19.1211 21.9961H16.8711V3.99609H25.8711V6.24609C25.8711 6.51412 26.0141 6.76171 26.2461 6.89564C26.4781 7.02957 26.7641 7.02957 26.9961 6.89564C27.2281 6.76171 27.3711 6.51411 27.3711 6.24609V3.24609C27.3711 3.04721 27.2921 2.85636 27.1515 2.71574C27.0108 2.57511 26.82 2.49609 26.6211 2.49609H5.62109C5.42221 2.49609 5.23136 2.57511 5.09074 2.71574C4.95011 2.85636 4.87109 3.04721 4.87109 3.24609Z"
                fill="#505050"
              />
            </svg>
          </Tooltip>
        </div>
      </Button>
      <Drawer
        className="drawer"
        position={Position.LEFT}
        onClose={() => setIsDrawerOpen(false)}
        isOpen={isDrawerOpen}
        title="Text"
      >
        <div className={Classes.DRAWER_BODY}>
          <div className="horizontal-line"></div>
          <AddTextType />
        </div>
      </Drawer>
    </div>
  );
};
