import { H2 } from '@blueprintjs/core';
import { Row, Col } from 'antd';
import React from 'react';

export const BannerSection = ({ className = '', style }) => {

  return (

    <div className={`banner ${className}`} style={style}>
      <Row>
        <Col span={17} style={{marginLeft:'22px'}}>
          <H2 className="bannerTitle" style={{ fontWeight: 700, fontSize: '30px' }}>
            {' '}
                        Welcome to your personal project library.{' '}
          </H2>
          <p className="projecttext">
                        This is where all your amazing lesson creations will be saved. To get you started, take a{' '}
            <br />
                        look at some of our recommended lessons and templates.
          </p>
          <p className="bannerLink">Get started in less than 5 minutes!</p>
        </Col>
        <Col span={4}>
          <img
            className="libraryimgcs"
            src="https://images.pexels.com/photos/8613089/pexels-photo-8613089.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
          />
        </Col>
      </Row>
    </div>

  );
};
