import { desaturate, EuiButton } from '@elastic/eui';
import { SmallBanner } from '../../components/SmallBanner';
import BuilderLayout from '../../components/BuilderLayout';
import CheckboxFilterWrapper from '../../components/CheckboxFilter/CheckboxFilterWrapper';
import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import { Input, message, Pagination, Spin } from 'antd';
import { deleteItem, getCount, getTemplates } from '../../helpers/apiCalls';
import { Classes, Dialog } from '@blueprintjs/core';
import { getLoggedInUser } from '../../helpers/authUtils';
import appConfig from '../../config';
import { getImageFallback } from '../../utils';
import { CardListContainer } from '../../pages/my-creation/components/CardListContainer';
import { ConfirmationForm } from '../../components/Forms/ConfirmationForm/ConfirmationForm';
import { TemplatePreview } from '../../components/SubSection/RecommendTemplate/components/TemplatePreview';
import { LessonCard } from '../../components/LessonCard';
import PopOverContent from '../../components/PopOverContent';
import { SearchOutlined } from '@ant-design/icons';
import { createData } from '../../services/template.service';
import { useSelector } from 'react-redux';
import http from '../../services/http.service';
import { createQuery } from '../../utils';

const MyTemplate = () => {
  const [deleteId, setDeleteId] = useState(undefined);
  const [showMode, setShowsMode] = useState(false);
  const [showMod, setShowsMod] = useState(false);
  const loggedInUser = getLoggedInUser();
  const { user } = useSelector((state) => state.Auth);
  const [templateData, setTemplateData] = useState([]);
  const history = useNavigate();
  const [pagination, setPagination] = React.useState({
    current: 1,
    pageSize: 12,
  });
  const [isCopy, setIsCopy] = useState(false);
  const [copyId, setCopyId] = useState({});
  const handleSearch = (e) => {
    setLoading(true);
    getTemplate({ pagination }, e.target.value);
  };
  const handleChange = (value) => {
    let pagination1 = { ...pagination, current: value };
    getTemplate({ pagination: pagination1 });
  };
  const sideData = {
    ['Design Type']: ['Lesson', 'Assembly', 'Game', 'Template'],
    Status: ['Draft', 'In Review', 'Published (Private)', 'Published (SuperTeach)', 'Not Approved', 'Unpublished'],
    Visbility: ['Public', 'Private', 'School'],
  };

  const [loading, setLoading] = React.useState(false);

  const [total, setTotal] = React.useState([]);
  const handleCopy = (item) => {
    setIsCopy(true);
    setCopyId({
      template: item.attributes.template,
      title: item.attributes.title,
      description: item.attributes.description,
      username: item.attributes.username,
    });
  };

  const handleDelete = (item) => {
    setDeleteId(item.id);
    setShowsMode(true);
  };

  React.useEffect(() => {
    getCount('Template', loggedInUser.user.username, '')
      .then((res) => {
        setTotal(res.entity);
      })
      .catch((err) => console.log(err));
    getTemplate({ pagination });
  }, []);

  const deleteProject = (id) => {
    let data = { model: 'project', item: id };
    deleteItem('projects', id)
      .then((res) => {
        message.success('Deleted Entry');
        getTemplate({ pagination });
        setShowsMode(false);
      })
      .catch((err) => {
        message.error('Cannot Delete');
        setShowsMode(false);
      });
  };

  //Getting data of project from API
  const getTemplate = async (params, events) => {
    setLoading(true);
    const { limit, start } = getRandomuserParams(params);
    const result = await http.get(appConfig.collection + '/templates?' + createQuery({
      filters: {
        $and: [
          {
            username: {
              $eq: user?.user?.username
            }
          },
          {
            title: {
              $contains: events
            }
          }
        ]
      }
    }, limit, start));
    console.log('result', result);
    setTemplateData(result.data);
    setPagination({ ...params.pagination });
    setLoading(false);
  };
  const getRandomuserParams = (params) => ({
    limit: params.pagination.pageSize,
    start:
      params.pagination.current > 1
        ? params.pagination.current * params.pagination.pageSize - params.pagination.pageSize
        : 0,
    ...params,
  });

  const handleCopyProject = async () => {
    let response = await createData('templates', copyId);
    getTemplate({ pagination });
    setIsCopy(false);
  };

  return (
    <div>
      <SmallBanner
        pageTitle="My Creations"
        subTitle="A library of your creations!"
        leftSectionComponent={
          <>
            <EuiButton className="euiButton br-xs" size="large">
              Create a folder
            </EuiButton>
            <EuiButton
              onClick={() => history('/template')}
              className="euiButton secondary br-xs"
              size="large">
              Create my own template
            </EuiButton>
          </>
        }
      />
      <BuilderLayout
        sideComponent={<CheckboxFilterWrapper data={sideData} handleOnCheck={() => null} />}
        contentComponent={
          <>
            <Input
              placeholder="Search templates"
              className="mt-1 search-input"
              allowClear
              size="large"
              prefix={<SearchOutlined />}
              onChange={(e) => handleSearch(e)}
              style={{ width: '30%', marginLeft: '1.5rem' }}
            />
            <div className="projectcardsContainer">
              {loading ? (
                <Spin />
              ) : (
                <CardListContainer>
                  {makeTemplateCards({ data: templateData, handleCopy, handleDelete })}
                </CardListContainer>
              )}
            </div>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
              <Pagination onChange={handleChange} defaultCurrent={1} showSizeChanger={false} total={total} />
            </div>
            <Dialog
              enforceFocus={false}
              onClose={() => setShowsMod(false)}
              style={{ width: '65%' }}
              isOpen={showMod}
              autoFocus={true}
              canEscapeKeyClose={true}
              canOutsideClickClose={true}
              usePortal={false}>
              <TemplatePreview />
            </Dialog>
            <Dialog
              // title="Add Your Projects"
              // icon="info-sign"
              enforceFocus={false}
              onClose={() => {
                setShowsMode(false);
              }}
              className="librarydialogcss"
              isOpen={showMode}
              autoFocus={true}
              canEscapeKeyClose={true}
              canOutsideClickClose={true}
              usePortal={false}>
              <div className={Classes.DIALOG_BODY}>
                <ConfirmationForm
                  onClose={() => setShowsMode(false)}
                  onCancel={() => setShowsMode(false)}
                  onConfirmation={() => deleteProject(deleteId)}
                  message={'Are you sure you want to delete this project?'}
                />
              </div>
            </Dialog>
            <Dialog
              // title="Add Your Projects"
              // icon="info-sign"
              enforceFocus={false}
              onClose={() => {
                setIsCopy(false);
              }}
              className="librarydialogcss"
              isOpen={isCopy}
              autoFocus={true}
              canEscapeKeyClose={true}
              canOutsideClickClose={true}
              usePortal={false}>
              <div className={Classes.DIALOG_BODY}>
                <ConfirmationForm
                  onClose={() => setIsCopy(false)}
                  onCancel={() => setIsCopy(false)}
                  onConfirmation={() => handleCopyProject(copyId)}
                  message={'Are you sure you want to Copy this project?'}
                />
              </div>
            </Dialog>
          </>
        }
      />
    </div>
  );
};

const imageUrlFallback = (data) => {
  if (!data) {
    return getImageFallback();
  }
  return appConfig.socketURL + data[0]?.attributes.url.replace('/', '');
};

const makeTemplateCards = ({
  data = [], handleCopy = () => {
  }, handleDelete = () => {
  }, ...rest
}) => {
  return data.map((item) => {
    return (
      <LessonCard
        key={item.id}
        popoverTitle={item.attributes.title}
        id={item.id}
        imageUrl={imageUrlFallback(item.attributes.thumbnail.data)}
        noOfSlides={item.attributes.project?.slides?.length}
        title={item.attributes.title}
        popoverComponent={
          <PopOverContent
            title={item.attributes.title}
            id={item.id}
            handleCopy={(e) => handleCopy(item)}
            handleDelete={(e) => handleDelete(item)}
          />
        }
      />
    );
  });
};

export default MyTemplate;
