import React, { useEffect, useState } from 'react';
import { message, Select, Upload } from 'antd';
import { Button, Classes, Dialog, Tooltip } from '@blueprintjs/core';
import { newAssets, newMediaUpload, updateSchool } from '../../helpers/apiCalls';
import { PlusOutlined } from '@ant-design/icons';
import { SmallBanner } from '../../components/SmallBanner';
import { EuiButton } from '@elastic/eui';

import AssetsBuilder from '../../components/assets-builder';
import { getLoggedInUser } from '../../helpers/authUtils';
import appConfig from '../../config';
import http from '../../services/http.service';
import { createQuery } from '../../utils';

const { Option } = Select;
const uploadProps = {
  name: 'file',
  multiple: false,
};


const Asset = () => {
  const [totalMedia, setTotalMedia] = useState();
  const [mediaModel, setMediamodel] = useState(false);
  const [assetImage, setAssetImage] = useState({});
  const [loading, setLoading] = useState(true);
  const [logoImage, setLogoImage] = useState([]);
  const [uploadType, setUploadType] = useState('');
  const loggedInUser = getLoggedInUser();
  const [deleteLoader, setDeleteLoader] = useState(false);
  const [pagination, setPagination] = useState({
    limit: 20,
    start: 0,
  });
  const [filter, setFilter] = useState({});


  const uploadbutton = (
    <div>
      <PlusOutlined />
      <div className="uploadbutton">Upload</div>
    </div>
  );

  const getType = (data) => {
    let unique = data.data
      .filter((value) => value.attributes.contenttype !== null)
      .filter((value, index, self) => {
        return self.map((e) => e.attributes.contenttype).indexOf(value.attributes.contenttype) === index;
      });

    return unique;
  };
  const filterAssets = async (filter, pagination) => {
    setLoading(true);
    const { start, limit } = pagination;
    console.log('filters', filter, createQuery(filter));
    const result = await http.get(appConfig.collection + '/assets?' + createQuery(filter, limit, start));
    setTotalMedia(result.data);
    setLoading(false);
  };
  useEffect(() => {
    filterAssets(filter, pagination);
    return () => setTotalMedia([]);
  }, [pagination, filter, deleteLoader]);


  const handleUpload = (item, name, index = '') => {
    const formData = new FormData();
    formData.append('files', item.file);
    console.log('formData', formData, item);
    newMediaUpload(formData, item.onProgress)
      .then((res) => {
        setAssetImage(res[0]);
        item.onSuccess(res, item.file);
      })
      .catch((err) => console.log(err));
  };
  const onChange = (item) => {
    const { status } = item.file;
    if (status === 'done') {
      message.success(`${item.file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      message.error(`${item.file.name} file upload failed.`);
    }
  };

  const onFinish = () => {
    console.log('assetImage', assetImage);
    let name = assetImage.name.split('.')[0] || '';
    let extensionType = '';
    const regexImage = /\.(jpg|jpeg|png)$/i;
    const regexGif = /\.(gif)$/i;
    if (regexImage.test(assetImage.name)) {
      extensionType = 'Image';
    } else if (regexGif.test(assetImage.name)) {
      extensionType = 'Gif';
    }
    if (uploadType === 'media') {
      let data = {
        data: {
          asset: assetImage.id,
          type: 'user',
          contenttype: 'user',
          name,
          assetType: extensionType
        },
      };
      newAssets(data)
        .then((res) => {
          setDeleteLoader((prev) => !prev);
          message.success('Added Asets successfully!');
        })
        .catch((err) => {
          message.error('Plz try again!', err);
        });
    }
    if (uploadType === 'logo') {
      let data = {
        data: {
          schoolLogo: {
            id: assetImage.id
          },
        }
      };
      updateSchool(loggedInUser?.user?.profile?.schools[0]?.id, data)
        .then((res) => {
          if (res.data?.attributes.schoolLogo?.data) {
            setLogoImage(res.data?.attributes.schoolLogo?.data);
          }

          message.success('Update logo successfully!');
        })
        .catch((err) => {
          message.error('Plz try again!', err);
        });
    }
    setMediamodel(false);
  };


  return (
    <div>
      <SmallBanner
        pageTitle="My Assets"
        subTitle="Upload your own media and create a school theme to personalise your resources."
        type="assets"
        leftSectionComponent={
          <>
            <EuiButton className="euiButton br-xs" size="large" onClick={() => {
              setMediamodel(true);
              setUploadType('media');
            }}>
              Upload media
            </EuiButton>
          </>
        }
      />
      <AssetsBuilder
        setDeleteLoader={setDeleteLoader}
        totalMedia={totalMedia}
        loading={loading}
        setTotalMedia={setTotalMedia}
        setLoading={setLoading}
        setMediamodel={setMediamodel}
        logoImage={logoImage}
        setLogoImage={setLogoImage}
        setUploadType={setUploadType}
        setPagination={setPagination}
        setFilter={setFilter}
        pagination={pagination}
        filter={filter}
      />
      <Dialog
        title="Upload new Media"
        icon="info-sign"
        enforceFocus={false}
        onClose={() => {
          setMediamodel(false);
        }}
        isOpen={mediaModel}
        autoFocus={true}
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        usePortal={false}>
        <div className={Classes.DIALOG_BODY}>
          <div>
            <Upload
              {...uploadProps}
              maxCount={1}
              customRequest={(item) => handleUpload(item)}
              listType="picture-card"
              onChange={onChange}>
              {uploadbutton}
            </Upload>
            <Button onClick={onFinish}>Save</Button>
            <br />
          </div>
        </div>

        <div className={Classes.DIALOG_FOOTER}>
          <div className={Classes.DIALOG_FOOTER_ACTIONS}>
            <Tooltip content="This button is hooked up to close the dialog.">
              <Button
                onClick={() => {
                  setMediamodel(false);
                }}>
                Close
              </Button>
            </Tooltip>
          </div>
        </div>
      </Dialog>
    </div>
  );
};

export default Asset;
