import React, { useState } from 'react';
import { Label } from '@blueprintjs/core';
import { Row, Col } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { elementUpdated, elementChanged } from '../../redux/studio/actions';
import { Button } from '@blueprintjs/core';
import { Popover2 } from '@blueprintjs/popover2';
import appConfig from '../../config';

import {
  BackwardIcon,
  BottomIcon,
  CenterIcon,
  ForwardIcon,
  LeftIcon,
  MiddleIcon,
  RightIcon,
  TopIcon,
} from '../../ProjectSVG';
import $ from 'jquery';
const Positionproperty = () => {
  const [state, setState] = useState({
    project: appConfig.project,
  });
  const { selectedElement } = useSelector((state) => state.Studio.present);
  const dispatch = useDispatch();
  let elemId = selectedElement;

  const sendBackwardHandler = (element) => {
    dispatch(elementUpdated(element, 'sendBackward', 'z-index'));
  };
  const sendForwardHandler = (element) => {
    dispatch(elementUpdated(element, 'bringForward', 'z-index'));
  };
  const handleOpen = () => setState({ isOpen: true });
  const handleClose = () => setState({ isOpen: false });
  return (
    <Popover2
      content={
        <div className="positioncss">
          <Row style={{ marginTop: '-6px' }}>
            <Col span={12}>
              <ForwardIcon />
              <Button
                className="positionbutns"
                asyncControl={true}
                name="vertical-align"
                value={0}
                minimal={true}
                onClick={() => sendForwardHandler(elemId)}>
                Forward
              </Button>
            </Col>
            <Col span={12}>
              <BackwardIcon />
              <Button
                className="positionbutns"
                asyncControl={true}
                name="vertical-align"
                value={0}
                minimal={true}
                onClick={() => sendBackwardHandler(elemId)}>
                Backward
              </Button>
            </Col>
          </Row>
          <hr style={{ width: '229px', marginLeft: '-11px' }} />
          <Row style={{ marginTop: '-6px', marginLeft: '2px' }}>
            <Label style={{ fontSize: '15px', fontWeight: '500', color: 'black' }}>Align to page</Label>
          </Row>

          <Row>
            <Col span={12}>
              <TopIcon />
              <Button
                className="positionbutns"
                asyncControl={true}
                name="vertical-align"
                value={0}
                minimal={true}
                onClick={() => {
                  dispatch(elementUpdated(elemId, 0, 'top'));
                }}>
                Top
              </Button>
            </Col>
            <Col span={12}>
              <BottomIcon />
              <Button
                className="positionbutns"
                asyncControl={true}
                name="vertical-align"
                value={0}
                minimal={true}
                onClick={() => {
                  let lcanvas = $('#lcanvas');
                  let lcanvasHeight = lcanvas.height();
                  let elHeight;

                  if (typeof elemId.position.height === 'string') {
                    if (elemId.position.height.includes('%')) {
                      elHeight =
                        (parseFloat(elemId.position.height.replace('%', '')) * lcanvasHeight) /
                        100;
                    } else if (elemId.position.height.includes('px')) {
                      elHeight = parseFloat(elemId.position.height.replace('px', ''));
                    } else {
                      elHeight = (parseFloat(elemId.position.height) * lcanvasHeight) / 100;
                    }
                  } else {
                    elHeight = (elemId.position.height * lcanvasHeight) / 100;
                  }

                  let percentageWidth = ((lcanvasHeight - elHeight) * 100) / lcanvasHeight;
                  dispatch(elementUpdated(elemId, percentageWidth, 'top'));
                }}>
                Bottom
              </Button>
            </Col>
          </Row>
          <Row>
            <Col span={12}>
              <MiddleIcon />
              <Button
                className="positionbutns"
                asyncControl={true}
                name="vertical-align"
                value={0}
                minimal={true}
                onClick={() => {
                  let lcanvas = $('#lcanvas');
                  let lcanvasWidth = lcanvas.width();
                  let elWidth;
                  if (typeof elemId.position.width === 'string') {
                    if (elemId.position.width.includes('%')) {
                      elWidth =
                        (parseFloat(elemId.position.width.replace('%', '')) * lcanvasWidth) /
                        100;
                    } else if (elemId.position.width.includes('px')) {
                      elWidth = parseFloat(elemId.position.width.replace('px', ''));
                    } else {
                      elWidth = (parseFloat(elemId.position.width) * lcanvasWidth) / 100;
                    }
                  } else {
                    elWidth = (elemId.position.width * lcanvasWidth) / 100;
                  }
                  let percentageWidth = ((lcanvasWidth / 2 - elWidth / 2) * 100) / lcanvasWidth;
                  dispatch(elementUpdated(elemId, percentageWidth, 'left'));
                }}>
                Middle
              </Button>
            </Col>
            <Col span={12}>
              <CenterIcon />
              <Button
                className="positionbutns"
                asyncControl={true}
                name="vertical-align"
                value={0}
                minimal={true}
                onClick={() => {
                  let lcanvas = $('#lcanvas');
                  let lcanvasWidth = lcanvas.width();
                  let lcanvasHeight = lcanvas.height();
                  let elWidth;
                  if (typeof elemId.position.width === 'string') {
                    if (elemId.position.width.includes('%')) {
                      elWidth =
                        (parseFloat(elemId.position.width.replace('%', '')) * lcanvasWidth) /
                        100;
                    } else if (elemId.position.width.includes('px')) {
                      elWidth = parseFloat(elemId.position.width.replace('px', ''));
                    } else {
                      elWidth = (parseFloat(elemId.position.width) * lcanvasWidth) / 100;
                    }
                  } else {
                    elWidth = (elemId.position.width * lcanvasWidth) / 100;
                  }
                  let elHeight;
                  if (typeof elemId.position.height === 'string') {
                    if (elemId.position.height.includes('%')) {
                      elHeight =
                        (parseFloat(elemId.position.height.replace('%', '')) * lcanvasHeight) /
                        100;
                    } else if (elemId.position.height.includes('px')) {
                      elHeight = parseFloat(elemId.position.height.replace('px', ''));
                    } else {
                      elHeight = (parseFloat(elemId.position.height) * lcanvasHeight) / 100;
                    }
                  } else {
                    elHeight = (elemId.position.height * lcanvasHeight) / 100;
                  }
                  let percentageWidth = ((lcanvasWidth / 2 - elWidth / 2) * 100) / lcanvasWidth;
                  let percentageheight = ((lcanvasHeight / 2 - elHeight / 2) * 100) / lcanvasHeight;
                  elemId.position.left = percentageWidth;
                  elemId.position.top = percentageheight;
                  dispatch(elementChanged(elemId));
                }}>
                center
              </Button>
            </Col>
          </Row>

          <Row style={{ marginTop: '-6px' }}>
            <Col span={12}>
              <LeftIcon />
              <Button
                className="positionbutns"
                asyncControl={true}
                name="vertical-align"
                value={0}
                minimal={true}
                onClick={() => {
                  dispatch(elementUpdated(elemId, 0, 'left'));
                }}>
                Left
              </Button>
            </Col>
            <Col span={12}>
              <RightIcon />
              <Button
                className="positionbutns"
                asyncControl={true}
                name="vertical-align"
                value={0}
                minimal={true}
                onClick={() => {
                  let lcanvas = $('#lcanvas');
                  let lcanvasWidth = lcanvas.width();
                  let elWidth;
                  if (typeof elemId.position.width === 'string') {
                    if (elemId.position.width.includes('%')) {
                      elWidth =
                        (parseFloat(elemId.position.width.replace('%', '')) * lcanvasWidth) /
                        100;
                    } else if (elemId.position.width.includes('px')) {
                      elWidth = parseFloat(elemId.position.width.replace('px', ''));
                    } else {
                      elWidth = (parseFloat(elemId.position.width) * lcanvasWidth) / 100;
                    }
                  } else {
                    elWidth = (elemId.position.width * lcanvasWidth) / 100;
                  }
                  let percentageWidth = ((lcanvasWidth - elWidth) * 100) / lcanvasWidth;

                  dispatch(elementUpdated(elemId, percentageWidth, 'left'));
                }}>
                Right
              </Button>
            </Col>
          </Row>
        </div>
      }>
      <Button className="animaionaniatecss" minimal={true} disabled={elemId.disable === false ? false : true} style={{ marginTop: '15px' }}>
        <p style={{ color: '#717171' }}>Position</p>
      </Button>
    </Popover2>
  );
};

export default Positionproperty;
