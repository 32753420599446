import React from 'react';
import LessonBuilder from '../../components/lesson-builder';
import { MainBanner } from '../../components/MainBanner';
import Image from '../../assets/images/Group258.png';

function LessonBuilderPage({ lessons }) {
  const bannerProps = {
    pageTitle:
            'Use our wizard to build interactive lessons in minutes! Just add your content and we take care of the rest!',
    subText: 'Choose from hundreds of ready to use templates designed specifically for children or build your own!',
    rightSectionImage: Image,
  };

  return (
    <>
      <MainBanner {...bannerProps} />
      <LessonBuilder />
    </>
  );
}

export default LessonBuilderPage;
