// Cardinal Law:
//  1. no side effects
//  2. output depends on input

import { cloneDeep, isArray, isEqual, isObject } from 'lodash';
import cover from '../assets/images/bkp/covers/2.jpg';
import appConfig from '../config';
import qs from 'qs';

export const getImageFallback = () => cover;

export const imagesObjectReMap = (imagesObject = {}) => {
  return imagesObject?.length
    ? imagesObject.map((k) => {
      return { imageUrl: appConfig.socketURL + k.attributes.url, id: k.id };
    })
    : [];
};

export const settingsDiff = (newSettings, originalSettings, ignoreList = []) => {
  // ignore_list takes a list of (str) keys to ignore
  // Returns an object with the keys/values that have changed from their initial state
  let _newSettings = cloneDeep(newSettings);
  let _originalSettings = cloneDeep(originalSettings);
  // Deleting ignored fields
  ignoreList.forEach((ignore_attribute) => {
    if (ignore_attribute in _newSettings) { delete _newSettings[ignore_attribute]; }
    if (ignore_attribute in _originalSettings) { delete _originalSettings[ignore_attribute]; }
  });
  // calculating changed settings
  let changedSettings = {};
  Object.keys(_newSettings).forEach((setting) => {
    let newSetting = _newSettings[setting];
    let originalSetting = _originalSettings[setting];

    if (isObject(newSetting)) {
      if (!isEqual(originalSetting, newSetting)) { changedSettings[setting] = newSetting; }
    } else if (isArray(newSetting)) {
      if (!isEqual(originalSetting, newSetting)) { changedSettings[setting] = newSetting; }
    } else if (originalSetting !== newSetting) {
      changedSettings[setting] = newSetting;
    }
  });
  return { changed: Object.keys(changedSettings).length > 0, changedSettings };
};


export function debounce(func, delay) {
  let timeoutId;
  return function (...args) {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, args);
    }, delay);
  };
}

export function hideTextIfExceedsLimit(text, limit) {
  if (text.length > limit) {
    return text.substring(0, limit) + '...'; // Replace '...' with whatever you want to indicate that the text has been truncated
  } else {
    return text;
  }
}


export function makeFilterQuery(start, limit, query) {

  if (start !== undefined && limit !== undefined) {
    return `?_start=${start}&_limit=${limit}${query}`;
  }

  if (start !== undefined) {
    return `?_start=${start}${query}`;
  }
  return query;
}

export const createQuery = (value = null, limit = 10, start = 0) => {
  if (!value) {
    return qs.stringify({
      limit: limit,
      start: start
    });
  }
  return qs.stringify(
    {
      ...value,
      limit: limit,
      start: start
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  );
};

export const uuidv4 = () => {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
};
