import React, { useEffect, useState } from 'react';
import Banner from './Banner';
import StudioList from './StudioList';
import ResourcesList from './ResourcesList';
import { getProjects, getResources } from '../../helpers/apiCalls';
import RecentlyLesson from './RecentlyLesson';

const Dashboard = () => {
  const [projects, setProjects] = useState();
  const [resources, setResources] = useState();


  useEffect(() => {
    getProjects().then((res) => {
      setProjects(res);
    });
    getResources().then((res) => {
      setResources(res);
    });
  }, []);


  return (
    <div>
      <Banner />
      {projects && <StudioList className={'mb-6'} project={projects} style={{ padding: '2rem 5rem' }} />}
      {resources && <ResourcesList resources={resources} style={{ padding: '2rem 5rem' }} className={'mb-6'} />}
      {projects && <RecentlyLesson resources={projects} style={{ padding: '2rem 5rem' }} className={'mb-6'} />}
    </div>
  );
};

export default Dashboard;


// import React, { Component } from 'react';
// import { Row, Col, UncontrolledButtonDropdown, DropdownMenu, DropdownItem, DropdownToggle } from 'reactstrap';
// import Flatpickr from 'react-flatpickr';
// import { ChevronDown, Mail, Printer, File, Users, Image, ShoppingBag } from 'react-feather';

// import { getLoggedInUser } from '../../helpers/authUtils';
// import Loader from '../../components/Loader';
// import OverviewWidget from '../../components/OverviewWidget';

// class Dashboard extends Component {
//     constructor(props) {
//         super(props);

//         var oneWeekAgo = new Date();
//         oneWeekAgo.setDate(oneWeekAgo.getDate() - 15);

//         this.state = {
//             // user: getLoggedInUser(),
//             filterDate: [oneWeekAgo, new Date()],
//         };
//     }

//     render() {
//         return (
//             <React.Fragment>
//                 <div className="">
//                     {/* preloader */}
//                     {this.props.loading && <Loader />}

//                     <Row className="page-title align-items-center">
//                         <Col sm={4} xl={6}>
//                             <h4 className="mb-1 mt-0">Dashboard</h4>
//                         </Col>
//                     </Row>
//                 </div>
//             </React.Fragment>
//         );
//     }
// }

// export default Dashboard;
