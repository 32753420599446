import React from 'react';
import { Row, Col, Select } from 'antd';
import { H2, Position, Button } from '@blueprintjs/core';
import {
  BlankprojectIcon,
  NewprojectIcon,
  WizardimportIcon,
} from '../../../ProjectSVG';
import { Popover2 } from '@blueprintjs/popover2';

const { Option } = Select;
export const FilterSection = ({
  className = '',
  style = {},
  handleSortChange = () => { },
  handleStatusChange = () => { },
  handleNewProjectClick = () => { },
  title = null
}) => {
  const sortChildren = [];
  const sortOptions = ['Visibilty', 'Date', 'Name'];
  for (let i = 0; i < sortOptions.length; i++) {
    sortChildren.push(<Option key={sortOptions[i]}>{sortOptions[i]}</Option>);
  }
  const statusChildren = [];
  const statusOptions = ['active', 'published', 'draft'];
  for (let i = 0; i < statusOptions.length; i++) {
    statusChildren.push(<Option key={statusOptions[i]}>{statusOptions[i]}</Option>);
  }
  return (<div className={`librarybrowermaincss ${className}`} style={style}>
    <Row className="librarybrowersecondcss">
      <Col span={10}>
        {title ? <H2 className="bannerProjectSubtitle ">{title}</H2> : null}

      </Col>
      <Col span={8}>
        <Row span={24}>
          <Col span={4}>
            <p style={{ marginTop: '7px', fontSize: '15px', marginLeft: '-10px' }}>Filter by</p>
          </Col>
          <Col span={8}>
            <Select
              className="projectsSelect"
              name="status"
              placeholder="Themes"
              onChange={handleSortChange}>
              {sortChildren}
            </Select>
          </Col>
          <Col span={8}>
            <Select
              className="projectsSelect"
              name="status"
              placeholder="Types"
              onChange={handleStatusChange}>
              {statusChildren}
            </Select>
          </Col>
          <Col span={2} style={{ marginTop: '3px' }}>
            <Popover2
              content={
                <div className="createProjectPopover" style={{ zIndex: 0 }}>
                  <div className="popoverSubtitle">
                    <BlankprojectIcon />
                    <span className="popoverSubtitleText">Start Blank</span>
                  </div>
                  <div className="popoverSubtitle">
                    <WizardimportIcon />
                    <span className="popoverSubtitleText">Start Wizard Import</span>
                  </div>
                  <div className="popoverSubtitle">
                    <Button
                      className="ProjectCreateButton1"
                      onClick={handleNewProjectClick}>
                                            + Create New Project
                    </Button>
                  </div>
                </div>
              }
              style={{ zIndex: 19 }}
              position={Position.LEFT_BOTTOM}>
              <NewprojectIcon />
            </Popover2>
          </Col>
        </Row>
      </Col>
    </Row>
  </div>);
};
