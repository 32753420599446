import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Label,
  FormGroup,
  Button,
  Alert,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { AvForm, AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { Mail, Lock } from 'react-feather';
import { loginUser } from '../../redux/actions';
import { isUserAuthenticated } from '../../helpers/authUtils';
import Loader from '../../components/Loader';
import logo from '../../assets/images/logo.png';
import ReactGA from 'react-ga';

const Login = (props) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    let path = 'app' + location?.pathname;
    ReactGA.pageview(path);
    document.body.classList.add('authentication-bg');
    return () => {
      document.body.classList.remove('authentication-bg');
    };
  }, [location?.pathname]);

  useEffect(() => {
    renderRedirectToRoot();
  }, [props.user]);
  // console.log("userProps", props)

  const handleValidSubmit = (event, values) => {
    event.preventDefault();
    props.loginUser(values.username, values.password);
  };

  const renderRedirectToRoot = () => {
    const isAuthTokenValid = isUserAuthenticated();
    if (isAuthTokenValid) {
      navigate('/');
    }
  };

  return (
    <React.Fragment>
      {/* {renderRedirectToRoot()} */}
      {/* {(true || !isUserAuthenticated()) && ( */}
      <div className="account-pages my-5">
        <Container>
          <Row className="justify-content-center">
            <Col xl={10}>
              <Card className="">
                <CardBody className="p-0">
                  <Row>
                    <Col md={6} className="p-5 position-relative">
                      {props.loading && <Loader />}
                      <div className="mx-auto mb-5">
                        <a href="/">
                          <img src={logo} alt="" height="50" />
                        </a>
                      </div>
                      <h6 className="h5 mb-0 mt-4">Welcome back!</h6>
                      <p className="text-muted mt-1 mb-4">
                                                Enter your email address and password to access your account.
                      </p>
                      {props.error && (
                        <Alert color="danger" isOpen={props.error ? true : false}>
                          <div>{props.error}</div>
                        </Alert>
                      )}
                      <AvForm onValidSubmit={handleValidSubmit} className="authentication-form">
                        <AvGroup className="">
                          <Label for="username">Username</Label>
                          <InputGroup>
                            <InputGroupText addontype="prepend">
                              <span className="input-group-text">
                                <Mail className="icon-dual" />
                              </span>
                            </InputGroupText>
                            <AvInput
                              type="text"
                              name="username"
                              id="username"
                              placeholder="Enter your Email"
                              value={username}
                              onChange={(e) => setUsername(e.target.value)}
                              required
                            />
                          </InputGroup>
                          <AvFeedback>This field is invalid</AvFeedback>
                        </AvGroup>
                        <AvGroup className="mb-3">
                          <Label for="password">Password</Label>
                          <InputGroup>
                            <InputGroupText addontype="prepend">
                              <span className="input-group-text">
                                <Lock className="icon-dual" />
                              </span>
                            </InputGroupText>
                            <AvInput
                              type="password"
                              name="password"
                              id="password"
                              placeholder="Enter your password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                              required
                            />
                          </InputGroup>
                          <AvFeedback>This field is invalid</AvFeedback>
                        </AvGroup>
                        <FormGroup className="form-group mb-0 text-center">
                          <Button color="primary" className="btn-block">
                                                        Log In
                          </Button>
                        </FormGroup>
                      </AvForm>
                    </Col>
                    <Col md={6} className="d-none d-md-inline-block">
                      <div className="auth-page-sidebar"></div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col className="col-12 text-center"></Col>
          </Row>
        </Container>
      </div>
      {/* )} */}
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  const { user, loading, error } = state.Auth;
  return { user, loading, error };
};

export default connect(mapStateToProps, { loginUser })(Login);
