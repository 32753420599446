import React, { useState, useEffect, useRef, createContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { projectInit } from '../redux/studio/actions';
import { Row, Col } from 'reactstrap';
import '@blueprintjs/core/lib/css/blueprint.css';
import StudioToolbar from './components/StudioToolbar';
import StudioToolbarLeft from './components/studioToolbarLeft';
import CanvasFrame from './components/canvasFrame';
import '@blueprintjs/core/lib/css/blueprint.css';
import Slides from './components/slides';
import { Slider } from 'antd';
import $ from 'jquery';
import { ExpandIcon, QuestionIcon, ShrinkIcon } from '../ProjectSVG';
import GridIcon from '../components/GridIcon';
import GridIconOff from '../components/GridIconOff';
import Topbar from '../components/Topbar';
import {
  useLocation,
  useParams
} from 'react-router-dom';


const MyContext = createContext();

function Studio() {
  const dispatch = useDispatch();
  const project = useSelector(state => state.Studio.present.project);
  const [imageDrawer, setImageDrawer] = useState({
    image: false,
    isReplaced: false,
    data: {},
  });
  const [formatting, setFormatting] = useState({
    bold: false,
    italic: false,
    underline: false,
    orderedList: false,
    unorderedList: false,
    isFormattingChanged: false,
  });
  const [state, setState] = useState({
    fullScreen: false,
    isGrid: false,
    isAspectOn: true,
    sliderValue: 1
  });
  const location = useLocation();
  const params = useParams();
  const gridRef = useRef();
  const [style, setStyle] = useState({
    height: '100%',
    width: '100%',
  });

  const checkFullScreen = () => {
    let elem = document.documentElement;
    if (state.fullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        /* Safari */
        document.webkitExitFullscreen();
      } else if (document.msExitFullscreen) {
        /* IE11 */
        document.msExitFullscreen();
      }
    } else {
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    }
    setState(prevState => ({ ...prevState, fullScreen: !prevState.fullScreen }));
  };

  useEffect(() => {
    if (project) {
      let width = project.meta?.size?.width;
      console.log('width::', width);
      setStyle(prev => ({
        ...prev,
        sliderValue: prev.width / width
      }));
    }
  }, [project]);

  const onChange = (value) => {
    let { height, width } = project.meta.size;
    setStyle(prev => ({
      ...prev,
      height: `${height * value}px`,
      width: `${width * value}px`,
    }));
  };

  const addGrid = () => {
    setState(prevState => ({ ...prevState, isGrid: !prevState.isGrid }));
  };

  const aspectHandler = (value) => {
    setState(prevState => ({ ...prevState, isAspectOn: !value }));
  };

  useEffect(() => {
    let projectType = '';
    if (location.pathname.includes('theme')) {
      projectType = 'theme';
    }
    let type = location.search.split('=')[1];
    dispatch(projectInit(params?.cid, location.state?.type || type || projectType));

    return () => {
      dispatch(projectInit(null));
    };
  }, [location.pathname, location.search, location.state, params?.cid]);

  useEffect(() => {
    window.studioState = state;
    if (gridRef.current) {
      if (state.isGrid) {
        gridRef.current.style.backgroundImage = 'url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0naHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmcnIHdpZHRoPSc2MCcgaGVpZ2h0PSc2MCc+PGxpbmUgeDE9JzAnIHkxPScwJyB4Mj0nMCcgeTI9JzYwJyBzdHlsZT0nc3Ryb2tlOiNkNzE0MTQ7c3Ryb2tlLXdpZHRoOjI7c3Ryb2tlLWRhc2hhcnJheT0iMCInIC8+PGxpbmUgeDE9JzAnIHkxPScwJyB4Mj0nNjAnIHkyPScwJyBzdHlsZT0nc3Ryb2tlOiNkNzE0MTQ7c3Ryb2tlLXdpZHRoOjI7c3Ryb2tlLWRhc2hhcnJheT0iMCInIC8+PGxpbmUgeDE9JzEyJyB5MT0nMCcgeDI9JzEyJyB5Mj0nNjAnIHN0eWxlPSdzdHJva2U6I2RkZDtzdHJva2Utd2lkdGg6MTtzdHJva2UtZGFzaGFycmF5PSIwIicgLz48bGluZSB4MT0nMjQnIHkxPScwJyB4Mj0nMjQnIHkyPSc2MCcgc3R5bGU9J3N0cm9rZTojZGRkO3N0cm9rZS13aWR0aDoxO3N0cm9rZS1kYXNoYXJyYXk9IjAiJyAvPjxsaW5lIHgxPSczNicgeTE9JzAnIHgyPSczNicgeTI9JzYwJyBzdHlsZT0nc3Ryb2tlOiNkZGQ7c3Ryb2tlLXdpZHRoOjE7c3Ryb2tlLWRhc2hhcnJheT0iMCInIC8+PGxpbmUgeDE9JzQ4JyB5MT0nMCcgeDI9JzQ4JyB5Mj0nNjAnIHN0eWxlPSdzdHJva2U6I2RkZDtzdHJva2Utd2lkdGg6MTtzdHJva2UtZGFzaGFycmF5PSIwIicgLz48bGluZSB4MT0nMCcgeTE9JzEyJyB4Mj0nNjAnIHkyPScxMicgc3R5bGU9J3N0cm9rZTojZGRkO3N0cm9rZS13aWR0aDoxO3N0cm9rZS1kYXNoYXJyYXk9IjAiJyAvPjxsaW5lIHgxPScwJyB5MT0nMjQnIHgyPSc2MCcgeTI9JzI0JyBzdHlsZT0nc3Ryb2tlOiNkZGQ7c3Ryb2tlLXdpZHRoOjE7c3Ryb2tlLWRhc2hhcnJheT0iMCInIC8+PGxpbmUgeDE9JzAnIHkxPSczNicgeDI9JzYwJyB5Mj0nMzYnIHN0eWxlPSdzdHJva2U6I2RkZDtzdHJva2Utd2lkdGg6MTtzdHJva2UtZGFzaGFycmF5PSIwIicgLz48bGluZSB4MT0nMCcgeTE9JzQ4JyB4Mj0nNjAnIHkyPSc0OCcgc3R5bGU9J3N0cm9rZTojZGRkO3N0cm9rZS13aWR0aDoxO3N0cm9rZS1kYXNoYXJyYXk9IjAiJyAvPjwvc3ZnPg==")';
      } else {
        gridRef.current.style.backgroundImage = 'none';
      }
    }
  }, [state.isGrid, gridRef]);

  return (
    <MyContext.Provider value={{
      imageDrawer,
      setImageDrawer,
      formatting,
      setFormatting,
    }}>
      <div style={{ height: '100vh', width: '100vw', overflow: 'hidden', position: 'relative', zIndex: 10, display: 'flex', flexDirection: 'column' }}>
        <Topbar mainTopbar={false} />
        {project?.slides?.length ? (
          <Row
            style={{
              flexGrow: 1,
              width: '100%',
              borderBottom: '1px solid #d1d1d1',
            }}>
            <Col style={{ padding: '0px', display: 'flex', flexDirection: 'column' }}>
              <Row style={{ height: '100%', marginLeft: '12px', flexWrap: 'nowrap' }}>
                <Col
                  style={{
                    borderRight: '1px solid #D1D1D1',
                    maxWidth: '56px',
                    background: 'white',
                    zIndex: 10,
                  }}>
                  <StudioToolbarLeft />
                </Col>
                <Col
                  style={{
                    backgroundColor: '#ffff',
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    padding: '0px',
                    zIndex: 9,
                  }}>
                  <Row
                    style={{
                      marginRight: '25px',
                      marginLeft: '0px',
                      width: '100%',
                      zIndex: 10,
                    }}>
                    <Col
                      style={{
                        backgroundColor: 'white',
                        borderTop: '1px solid #D1D1D1',
                        borderBottom: '1px solid #D1D1D1',
                        margin: '0px',
                        height: '41px',
                        zIndex: 1000,
                        position: 'relative'
                      }}>
                      <StudioToolbar
                        aspectHandler={aspectHandler}
                        isAspectOn={state.isAspectOn}
                      />
                    </Col>
                  </Row>
                  <Row style={{ flexGrow: 1, zIndex: 9 }}>
                    <Col
                      style={{
                        flexGrow: 1,
                        position: 'relative'
                      }}>
                      <CanvasFrame isAspectOn={state.isAspectOn} gridRef={gridRef} style={style} setStyle={setStyle} />
                    </Col>
                  </Row>
                  <Row
                    style={{
                      height: '112px',
                      width: '100%',
                      backgroundColor: '#fff',
                    }}>
                    <Col style={{ backgroundColor: '#fff', padding: '1rem' }}>
                      <Slides />
                    </Col>
                  </Row>
                  <Row className="bottomToolbar" style={{ backgroundColor: '#fff' }}>
                    <Col style={{ display: 'flex', justifyContent: 'right' }}>
                      <div onClick={addGrid} className="grid-icon">
                        {state.isGrid ? <GridIconOff /> : <GridIcon />}
                      </div>
                      <span className="bottomSlider" style={{ width: '12%' }}>
                        <Slider
                          defaultValue={state.sliderValue}
                          min={1}
                          max={2}
                          step={0.1}
                          onChange={onChange}
                        />
                      </span>
                      <div onClick={checkFullScreen} style={{ marginTop: '6px' }}>
                        {state.fullScreen ? <ShrinkIcon /> : <ExpandIcon />}
                      </div>
                      <div style={{ marginTop: '6px' }}>
                        <QuestionIcon />
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        ) : (
          <div
            style={{
              height: '100vh',
              width: '100vw',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}>
            <div className="studioloader" />
          </div>
        )}
      </div>
    </MyContext.Provider>
  );
}

export default Studio;
export { MyContext };
