import React from 'react';
import Input from './Input';
import Image from './Image';

const Wizard = ({ item, _, setThemeQuestionBySlide, themeQuestionBySlide, setMediamodel, mediaModel }) => {
  switch (item?.type) {
    case 'text':
      return (
        <Input item={item} _={_} setThemeQuestionBySlide={setThemeQuestionBySlide} themeQuestionBySlide={themeQuestionBySlide} />
      );
      break;
    case 'img':
      return (
        <Image item={item} _={_} setThemeQuestionBySlide={setThemeQuestionBySlide} themeQuestionBySlide={themeQuestionBySlide} setMediamodel={setMediamodel} mediaModel={mediaModel} />
      );
      break;
    default:
      return null;
      break;
  }
};

export default Wizard;
