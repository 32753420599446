import React from 'react';
import { Modal } from 'antd';
import { ModalIcon, PartyIcon } from '../components/Icon/icons';
import { useNavigate } from 'react-router-dom';

const TemplateCreationModal = ({ id, visible, module, onCancel }) => {
  const history = useNavigate();
  return (
    <Modal className="step-3-modal" open={visible} footer={null} destroyOnClose onCancel={onCancel}>
      <div className="modal-container">
        <div className="party-icon">
          <PartyIcon />
        </div>
        <div className="template-creation-container">
          <div className="header">
            <div className="title">Congratulations</div>
            <div className="subtitle">You’ve created your lesson template!</div>
          </div>
          <div className="use-custom-template">Would you like to use your custom template now?</div>
          <div className="template-content">
            <div className="button-group">
              <button className="primary-button" onClick={() => {
                if (module === 'template') {
                  history(`/studio/${id}?type=template`);
                } else {
                  history(`/studio/${id}`);
                }
              }}>
                                Yes! Let’s get started!
              </button>
              <button className="link-button" onClick={() => history('/')}>
                                No thanks, I’m going to use it later
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-icon">
        <ModalIcon />
      </div>
    </Modal>
  );
};
export default TemplateCreationModal;
