import React from 'react';
import { Col, Row, Typography } from 'antd';
import styles from './styles.module.css';

const { Title } = Typography;

const PopoverMenu = ({ content }) => {
  return (
    <Row className={styles.menu_popover}>
      {content.map((menu, id) => (
        <Col className={styles.popover_top_col} key={id}>
          <Row>
            <Col style={{ width: '25%', display: 'flex', justifyContent: 'center', }}>
              <div className={styles.popover_icon} style={{ background: menu.color }}><img src={menu.icon} /></div>
            </Col>
            <Col style={{ width: '75%' }}>
              <Title level={3} style={{ margin: 0 }}>{menu.heading}</Title>
              <Title level={5} style={{ margin: 0 }}>{menu.description}</Title>
            </Col>
          </Row>
        </Col>
      ))}
    </Row>
  );
};

export default PopoverMenu;
