import React from 'react';
import { getProjects } from '../../helpers/apiCalls';
// import WizardQuestion from './WizardQuestion';
import WizardsHome from './WizardsHome';
import { Modal } from 'antd';
import { Loader } from '../my-creation/components/Loader';
// import WizardMockup from './WizardMockup';
import WizardMockupQuestion from './WizardMockupQuestion';
import wizardBGImage1 from '../../assets/images/background/wizard-question-modal-1.png';
import wizardBGImage2 from '../../assets/images/background/wizard-question-modal-2.png';

const Wizards = ({ id, type = 'project', visible, setVisible }) => {
  const [state, setState] = React.useState({});
  const [basicState, setBasicState] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [showHome, setShowHome] = React.useState(true);

  React.useEffect(() => {
    setLoading(true);
    if (!id) {return null;}

    getProjects(id).then((res) => {
      setLoading(false);
      setState(res.data);
    });
  }, [id]);

  return (
    <Modal
      centered
      width={1000}
      className="wizard-modal"
      open={visible}
      footer={null}
      onCancel={() => setVisible(false)}>
      <div className="wizard-layout">
        {!showHome && (
          <React.Fragment>
            <img className="wizard-background-image-1" src={wizardBGImage1} alt="wizard-background-1" />
            <img className="wizard-background-image-2" src={wizardBGImage2} alt="wizard-background-2" />
          </React.Fragment>
        )}
        {!state?.id ? (
                    <Loader />
                ) : showHome ? (
                    <WizardsHome
                      nextStep={() => setShowHome(false)}
                      loading={loading}
                      state={state}
                      setBasicState={setBasicState}
                      onCancel={() => setVisible(false)}
                    />
                ) : state?.attributes?.project?.slides?.length > 0 ? (
                    <WizardMockupQuestion state={state} closeWizard={() => setShowHome(true)} />
                ) : (
                    <div style={{ position: 'relative', margin: 'auto' }}>
                      <h1>Something went wrong</h1>
                    </div>
                )}
      </div>
    </Modal>
  );
};

export default Wizards;
